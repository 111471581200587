<template>
  <div :class="'device device_num_' + num">
    <div class="row mb-3">
      <h5 class="col-sm-2">Устройство {{ num }}</h5>
    </div>
    <div class="row mb-3">
      <label :for="'techLine' + num" class="col-sm-5 col-form-label"
        >Технологическая линия / устройство</label
      >
      <div class="col-sm-7">
        <input
          placeholder="Введите наименование устройства"
          type="text"
          class="form-control"
          :id="'techLine' + num"
          :name="'techLine' + num"
          v-model="techLine"
          @blur="techLineBlur"
        />
        <small class="invalid" v-if="techLineError">{{ techLineError }}</small>
      </div>
    </div>

    <div class="row mb-3">
      <label
        :for="'thousandsTonnsProduction' + num"
        class="col-sm-5 col-form-label"
        >Выпуск продукции, тыс. тонн</label
      >
      <div class="col-sm-7">
        <input
          v-model="thousandsTonnsProduction"
          type="text"
          class="form-control"
          :id="'thousandsTonnsProduction' + num"
          :name="'thousandsTonnsProduction' + num"
          @blur="thousandsTonnsProductionBlur"
        />
        <small class="invalid" v-if="thousandsTonnsProductionError">{{
          thousandsTonnsProductionError
        }}</small>
      </div>
    </div>
    <template v-if="this.$route.path != '/type-three'">
    <div v-if="this.$store.state.productionType == 'Производство всех видов стекла'" class="row mb-3">
      <label :for="'stecloboyPath' + num" class="col-sm-5 col-form-label"
        >Доля стеклобоя, %</label
      >
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control"
          :id="'stecloboyPath' + num"
          :name="'stecloboyPath' + num"
          v-model="stecloboyPath"
          @blur="stecloboyPathBlur"
        />
        <small class="invalid" v-if="stecloboyPathError">{{
          stecloboyPathError
        }}</small>
      </div>
    </div>
    <div v-else-if="this.$store.state.activityType == 'Целлюлозно-бумажное производство'" class="row mb-3">
      <!-- <label :for="'twoCarbonate' + num" class="col-sm-5 col-form-label"
        >Карбонат</label
      >
      <div class="col-sm-7">
        <Field
          as="select"
          v-model="twoCarbonate"
          class="form-select"
          aria-label="Default select example"
          :id="'twoCarbonate' + num"
          :name="'twoCarbonate' + num"
        >
          <option disabled :value="0">Выберите карбонат</option>
          <option :value="0.43971">Известняк</option>
          <option :value="0.47732">Доломит</option>
        </Field>
        <small class="invalid" v-if="twoCarbonateError">{{ twoCarbonateError }}</small>
      </div> -->
      <label :for="'limestone' + num" class="col-sm-5 col-form-label mb-2"
        >Масса известняка, тонн</label
      >
      <div class="col-sm-7 mb-2">
        <input
          v-model="limestone"
          type="text"
          class="form-control"
          :id="'limestone' + num"
          :name="'limestone' + num"
          @blur="limestoneBlur"
        />
        <small class="invalid" v-if="limestoneError">{{
          limestoneError
        }}</small>
      </div>
      <label :for="'dolomite' + num" class="col-sm-5 col-form-label"
        >Масса доломита, тонн</label
      >
      <div class="col-sm-7">
        <input
          v-model="dolomite"
          type="text"
          class="form-control"
          :id="'dolomite' + num"
          :name="'dolomite' + num"
          @blur="dolomiteBlur"
        />
        <small class="invalid" v-if="dolomiteError">{{
          dolomiteError
        }}</small>
      </div>
    </div>
    </template>
    <div class="row mb-3">
      <label :for="'energyQuantity' + num" class="col-sm-5 col-form-label"
        >Расход энергии за период (ГДж)</label
      >
      <div class="col-sm-7">
        <input
          v-model="energyQuantity"
          type="text"
          class="form-control"
          :id="'energyQuantity' + num"
          :name="'energyQuantity' + num"
          @blur="energyQuantityBlur"
        />
        <small class="invalid" v-if="energyQuantityError">{{
          energyQuantityError
        }}</small>
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'fuelType' + num" class="col-sm-5 col-form-label"
        >Топливо</label
      >
      <div class="col-sm-7">
        <Field
          as="select"
          v-model="fuelType"
          class="form-select"
          aria-label="Default select example"
          id="fuelType"
          name="fuelType"
        >
          <!-- <option disabled value="zero">Выберите тип топлива</option>
          <option value="Природный газ">Природный газ</option>
          <option value="Газ сжиженный">Газ сжиженный</option>
          <option value="Торф">Торф</option>
          <option value="Брикеты торфяные">Брикеты торфяные</option> -->
          <option disabled value="zero">Выберите тип топлива</option>
              <!-- <option value="Природный газ">Природный газ</option>
              <option value="Газовое топливо">Газ сжиженный</option>
              <option value="Торф">Торф</option>
              <option value="Брикеты торфяные">Брикеты торфяные</option> -->
              <template v-for="(value, name) in fuelTypeData" :key="name">
              <option v-if="name != 'zero'" :value="name" >{{ name }}</option>
              </template>
        </Field>
        <small class="invalid" v-if="fuelTypeError">{{ fuelTypeError }}</small>
      </div>
    </div>
    <div class="row p-4" v-if="carbonate">
      <the-carbonate
        v-for="rawNumber in rawCount"
        :key="rawNumber"
        :numberO="rawCount"
        @getCarbData="getCarbsData"
        :noCarbonates="noCarbonates"
      ></the-carbonate>
      <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-2">
        <button
          type="button"
          class="btn add-btn btn-sm d-flex align-items-center"
          @click.prevent="addRaw"
        ><img src="/img/plus.svg" alt="" width="40" class="add-img">
          Добавить<br />карбонат
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'snigenieEcv' + num" class="col-sm-5 col-form-label"
        >Снижение (улавливание), тонн (СО2-экв.)</label
      >
      <div class="col-sm-7">
        <input
          v-model="snigenieEcv"
          type="text"
          class="form-control"
          :id="'snigenieEcv' + num"
          :name="'snigenieEcv' + num"
          @blur="snigenieEcvBlur"
        />
        <small class="invalid" v-if="snigenieEcvError">{{
          snigenieEcvError
        }}</small>
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'techVybross' + num" class="col-sm-5 col-form-label"
        >Технологический выброс, тонн (СО2-экв.)</label
      >
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control green"
          :id="'techVybross' + num"
          :name="'techVybross' + num"
          v-model="techVybross"
          disabled
        />
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'energoVybross' + num" class="col-sm-5 col-form-label"
        >Энергетический выброс, тонн (СО2-экв.)</label
      >
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control green"
          :id="'energoVybross' + num"
          :name="'energoVybross' + num"
          v-model="energoVybross"
          disabled
        />
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'summVybross' + num" class="col-sm-5 col-form-label"
        >Суммарный выброс, тонн (СО2-экв.)</label
      >
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control green"
          :id="'summVybross' + num"
          :name="'summVybross' + num"
          v-model="summVybross"
          disabled
        />
      </div>
    </div>
    <div class="row mb-3">
      <label :for="'udelCheckVybr' + num" class="col-sm-5 col-form-label"
        >Удельный показатель выбросов, т. СО2-экв./т.</label
      >
      <div class="col-sm-7">
        <input
          type="text"
          class="form-control green"
          :id="'udelCheckVybr' + num"
          :name="'udelCheckVybr' + num"
          v-model="udelCheckVybr"
          disabled
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <button
          type="button"
          @click="deviceCalculate"
          class="btn devise-btn ms-5 float-end"
        >
          Рассчитать для устройства
        </button>
        <small class="invalid" v-if="noDevice"
          >Произведите расчет хотя бы для одного устройства</small
        >
      </div>
    </div>
  </div>
  <hr />
</template>

<script>
import { toRef, ref, watch, toRefs, computed, nextTick } from "vue";
import { useRoute } from "vue-router";
import TheCarbonate from "./TheCarbonate.vue";
import * as yup from "yup";
import { useField, useForm, Field } from "vee-validate";
import { useStore } from "vuex";
import { fuelTypeData } from "../use/fuelTypeData"
export default {
  components: { TheCarbonate, Field },
  name: "TheDevice",
  props: ["number", "noDevice"],
  emits: ["getData"],
  setup(props, context) {
    const store = useStore();
    const { handleSubmit, isSubmitting, submitCount } = useForm();
    const prodType = toRefs(props);
    const num = props.number;
    const noDevice = toRef(props, "noDevice");
    const totalCarbData = [];
    const noCarbonates = ref(false);
    // const techLine = ref("");
    const {
      value: techLine,
      errorMessage: techLineError,
      handleBlur: techLineBlur,
    } = useField(
      "techLine",
      yup
        .string()
        .required("Введите наименование устройства")
        .min(3, "Наименование должно быть не менее 3 символа"),
      { initialValue: "" }
    );
    // const production = ref({ k: "0", l: "zero" });
    // const stecloboyPath = ref(10);
    const {
      value: stecloboyPath,
      errorMessage: stecloboyPathError,
      handleBlur: stecloboyPathBlur,
    } = useField(
      "stecloboyPath",
      yup
        .number()
        .typeError("Введите число")
        .min(0, "Значение не должно быть меньше 0"),
      { initialValue: 10 }
    );
    // const fuelType = ref("");
    const {
      value: fuelType,
      errorMessage: fuelTypeError,
      // handleBlur: yearBlur,
    } = useField(
      "fuelType",
      yup.string().matches(/^((?!zero).)/, { message: "Выберите тип топлива" }),
      {
        initialValue: "zero",
      }
    );
    // const {
    //   value: twoCarbonate,
    //   errorMessage: twoCarbonateError,
    // } = useField(
    //   "twoCarbonate",
    //   yup.number()
    //     .typeError("Введите число")
    //     .moreThan(0, "Выберите карбонат"),
    //   { initialValue: 0 }
    // );
    // const snigenieEcv = ref("0");
    const {
      value: snigenieEcv,
      errorMessage: snigenieEcvError,
      handleBlur: snigenieEcvBlur,
    } = useField(
      "snigenieEcv",
      yup
        .number()
        .typeError("Введите число")
        .min(0, "Значение не должно быть меньше 0"),
      { initialValue: 0 }
    );
    const techVybross = ref("0");
    const energoVybross = ref("0");
    const summVybross = ref("0");
    const udelCheckVybr = ref("0");
    // const thousandsTonnsProduction = ref(1);
    const {
      value: thousandsTonnsProduction,
      errorMessage: thousandsTonnsProductionError,
      handleBlur: thousandsTonnsProductionBlur,
    } = useField(
      "thousandsTonnsProduction",
      yup
        .number()
        .typeError("Введите число")
        .moreThan(0, "Значение должно быть больше 0"),
      { initialValue: 1 }
    );
    const {
      value: limestone,
      errorMessage: limestoneError,
      handleBlur: limestoneBlur,
    } = useField(
      "limestone",
      yup
        .number()
        .typeError("Введите число")
        .moreThan(0, "Значение должно быть больше 0"),
      { initialValue: 1 }
    );
    const {
      value: dolomite,
      errorMessage: dolomiteError,
      handleBlur: dolomiteBlur,
    } = useField(
      "dolomite",
      yup
        .number()
        .typeError("Введите число")
        .moreThan(0, "Значение должно быть больше 0"),
      { initialValue: 1 }
    );
    // const energyQuantity = ref("0");
    const {
      value: energyQuantity,
      errorMessage: energyQuantityError,
      handleBlur: energyQuantityBlur,
    } = useField(
      "energyQuantity",
      yup
        .number()
        .typeError("Введите число")
        .moreThan(0, "Значение должно быть больше 0"),
      { initialValue: 1 }
    );
    const summCarbonatsVybros = ref("0");
    const summCarbonatsVybrosFixed = ref(0);
    // const fuelTypeData = {
    //   "Природный газ": {
    //     k: "54.40",
    //     eoCH: "1",
    //     eoNO: "0.1",
    //     opCH: "1",
    //     opNO: "0.1",
    //     ksCH: "5",
    //     ksNO: "0.1",
    //     jsCH: "5",
    //     jsNO: "0.1",
    //   },
    //   "Газовое топливо": {
    //     k: "56.10",
    //     eoCH: "0",
    //     eoNO: "0",
    //     opCH: "1",
    //     opNO: "0.1",
    //     ksCH: "5",
    //     ksNO: "0.1",
    //     jsCH: "5",
    //     jsNO: "0.1",
    //   },
    //   Торф: {
    //     k: "106",
    //     eoCH: "1",
    //     eoNO: "1.5",
    //     opCH: "2",
    //     opNO: "1.5",
    //     ksCH: "10",
    //     ksNO: "1.4",
    //     jsCH: "300",
    //     jsNO: "1.4",
    //   },
    //   "Брикеты торфяные": {
    //     k: "106",
    //     eoCH: "1",
    //     eoNO: "1.5",
    //     opCH: "2",
    //     opNO: "1.5",
    //     ksCH: "10",
    //     ksNO: "1.4",
    //     jsCH: "300",
    //     jsNO: "1.4",
    //   },
    //   zero: {
    //     k: "0",
    //     eoCH: "0",
    //     eoNO: "0",
    //     opCH: "0",
    //     opNO: "0",
    //     ksCH: "0",
    //     ksNO: "0",
    //     jsCH: "0",
    //     jsNO: "0",
    //   },
    // };

    let rawCount = ref(1);
    const addRaw = async () => {
      rawCount.value++;
      await nextTick(); //ждет пока перестроится дом
      document
        .querySelector(
          ".device_num_" + num + " .row .rawMaterial_num_" + rawCount.value
        )
        .scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const route = useRoute();
    const carbonate = computed(() => route.meta.carbonate);

    // watch(prodType.productionType, (newValue, oldValue) => {
    //   production.value = { k: "0", l: "zero" };
    // });
    watch(prodType.noDevice, (newValue, oldValue) => {});

    // const productionTypeInclArray = ["29", "31", "38", "44"];

    const diokUgl = 1;
    const metan = 25;
    const zakAzot = 298;

    // test
    const getCarbsData = (data) => {
      noCarbonates.value = false;
      if (totalCarbData.length != 0) {
        totalCarbData.forEach((el, index, array) => {
          for (let k in el) {
            for (let key in data) {
              if (k == key) {
                array.splice(index, 1);
              }
            }
          }
        });
        totalCarbData.push(data);
      } else {
        totalCarbData.push(data);
      }
      summCarbonatsVybros.value = 0;
      totalCarbData.forEach((el) => {
        for (let k2 in el) {
          summCarbonatsVybros.value += el[k2].vpVal;
        }
      });
      summCarbonatsVybrosFixed.value = summCarbonatsVybros.value.toFixed(0);
    };
    // test

    const deviceCalculate = handleSubmit((values) => {
      noCarbonates.value = false;
      if (totalCarbData.length == 0) {
        noCarbonates.value = true;
        // return;
      }
      // noCarbonates.value = false;
      values.carbonates = totalCarbData;
      if (carbonate.value) {
        techVybross.value = summCarbonatsVybrosFixed.value;
      } else if(store.state.activityType == "Целлюлозно-бумажное производство") {
        techVybross.value = limestone.value * 0.43971 + dolomite.value * 0.47732
      } else if (store.state.productionType == "Производство всех видов стекла") {
        techVybross.value =
          thousandsTonnsProduction.value *
          store.state.production.k *
          (1 - stecloboyPath.value / 100);
        // energoVybross.value = energyQuantity.value * fuelType.value.k * 0.001;
      } else {
        techVybross.value =
          thousandsTonnsProduction.value *
          store.state.production.k
      }
      energoVybross.value =
        energyQuantity.value *
          0.001 *
          fuelTypeData[fuelType.value].k *
          diokUgl +
        energyQuantity.value *
          0.001 *
          fuelTypeData[fuelType.value].eoCH *
          0.001 *
          metan +
        energyQuantity.value *
          0.001 *
          fuelTypeData[fuelType.value].eoNO *
          0.001 *
          zakAzot;
      summVybross.value =
        Number(techVybross.value) +
        Number(energoVybross.value) -
        snigenieEcv.value;

      udelCheckVybr.value = (
        summVybross.value / thousandsTonnsProduction.value
      ).toFixed(3);
      context.emit("getData", {
        [num]: {
          tv: techVybross.value,
          ev: energoVybross.value,
          sv: summVybross.value,
          uv: udelCheckVybr.value,
          t_line: techLine.value,
          production: store.state.production,
          sbp: stecloboyPath.value,
          ft: fuelType.value,
          secv: snigenieEcv.value,
          tonns: thousandsTonnsProduction.value,
          eq: energyQuantity.value,
          carbData: totalCarbData,
          limestone: limestone.value,
          dolomite: dolomite.value
        },
      });
      techVybross.value = Number(techVybross.value).toFixed(0);
      energoVybross.value = Number(energoVybross.value).toFixed(0);
      summVybross.value = Number(summVybross.value).toFixed(0);
      document.querySelector(".pdf-make").removeAttribute("disabled");
    });
    return {
      limestone,
      limestoneError,
      limestoneBlur,
      dolomite,
      dolomiteError,
      dolomiteBlur,
      // twoCarbonate,
      // twoCarbonateError,
      deviceCalculate,
      num,
      techLine,
      techLineError,
      techLineBlur,
      // production,
      stecloboyPath,
      stecloboyPathError,
      stecloboyPathBlur,
      fuelType,
      fuelTypeError,
      snigenieEcv,
      snigenieEcvError,
      snigenieEcvBlur,
      techVybross,
      energoVybross,
      summVybross,
      udelCheckVybr,
      thousandsTonnsProduction,
      thousandsTonnsProductionError,
      thousandsTonnsProductionBlur,
      energyQuantity,
      energyQuantityError,
      energyQuantityBlur,
      // productionTypeInclArray,
      prodType,
      carbonate,
      rawCount,
      addRaw,
      getCarbsData,
      summCarbonatsVybros,
      summCarbonatsVybrosFixed,
      noDevice,
      noCarbonates,
      store,
      fuelTypeData
    };
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$calculate-for-device-button-color: #7a6d49;
$hover-button-color: #ffffff;

$custom-btn-type-two-color: #6b6b6b;
$hover-custom-btn-type-two-color: #ffffff;

.custom-btn {
  @include button-variant(
    $calculate-for-device-button-color,
    $calculate-for-device-button-color,
    $hover-button-color
  );
  width: 100%;
}
.custom-btn:hover {
  @include button-variant(
    $hover-button-color,
    $calculate-for-device-button-color,
    $calculate-for-device-button-color
  );
}
.custom-btn-type-two {
  @include button-variant(
    $custom-btn-type-two-color,
    $custom-btn-type-two-color,
    $hover-custom-btn-type-two-color
  );
}
.custom-btn-type-two:hover {
  @include button-variant(
    $hover-custom-btn-type-two-color,
    $custom-btn-type-two-color,
    $custom-btn-type-two-color
  );
}

.green {
  background-color: #e2f8e8 !important;
}
.device {
  background-color: #f3f8fa;
  padding: 10px;
  border-radius: 10px;
}
.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 0.5rem;
}
/* .custom-btn{
  width: 100%;
} */
.form-control:disabled {
  background-color: #e2f8e8 !important;
}
.devise-btn {
  background-color: #4ca6c6;
  color: white;
  border: 2px solid #4ca6c6;
}
.devise-btn:hover {
  background-color: white;
  border: 2px solid #4ca6c6;
  color: #4ca6c6;
}
.add-btn{
  color: #4ca6c6;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  transition: .3s;
}
.add-btn:hover{
  color: #4ca6c6;
  transform: scale(1.05);
  transition: .3s;
}

</style>
