export const cementData = {
    "Портланд": 
    {
        klinker: 0.95,
        co2: 0.52
    },
    "Кладочный":
    {
        klinker: 0.64,
        co2: 0.52
    },
    "Портланд, модифицированный шлаком":
    {
        klinker: 0.93,
        co2: 0.52
    },
    "Доменно-шлаковый портланд":
    {
        klinker: 0.70,
        co2: 0.52
    },
    "Пуццолановый портланд":
    {
        klinker: 0.81,
        co2: 0.52
    },
    "Портланд, модифицированный пуццоланом":
    {
        klinker: 0.95,
        co2: 0.52
    },
    "Шлаковый цемент":
    {
        klinker: 0.29,
        co2: 0.52
    }
}