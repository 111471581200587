<template>
    <tr>
        <td v-if="span" :rowspan="span">{{ name }}</td>
        <td>{{ secondName }}</td>
        <td>{{ units }}</td>
        <td v-for="(y, index) in years" :key="index">
            <FormKit label="" type="number" validation="number" :validation-messages="{
                number: 'Должно быть число.',
            }" v-model="volume[index]" name="" placeholder="Объем" inner-class="my-width" />
        </td>
        <td>
            <p class="qwerty grey mb-0">{{ makeString(parseFloat(volumeMid).toFixed(2)) }}</p>
        </td>
        <td>
            <p v-if="disabledCO" class="qwerty grey mb-0">{{ makeString(parseFloat(CO).toFixed(4)) }}</p>
            <FormKit v-else label="" type="number" validation="number" name="" placeholder="" v-model="CO"
                :disabled="disabledCO === true" inner-class="my-width" />
        </td>
        <td>
            <p class="qwerty grey mb-0">{{ makeString(parseFloat(koeff).toFixed(4)) }}</p>
        </td>
        <td v-for="(y, index) in years" :key="index">
            <p class="qwerty grey mb-0">{{
                makeString(parseFloat(volumeAbs[index]).toFixed(3)) }}</p>
        </td>
        <td>
            <p class="qwerty grey mb-0">{{ makeString(parseFloat(volumeAbsMid).toFixed(3))
            }}</p>
        </td>
    </tr>
</template>

<script>
import { ref, toRefs, watch } from "vue"
import { colorMetalFunctions } from "@/use/colorMetFunctions";
export default {
    name: "TableRowComponent",
    props: {
        span: [Boolean, Number],
        name: String,
        secondName: String,
        units: String,
        years: Array,
        volume: Object,
        volumeMid: [Number, String],
        CO: [Number, String],
        disabledCO: Boolean,
        koeff: Number,
        volumeAbs: Object,
        volumeAbsMid: [Number, String],
        minus: Boolean,
        number: [Number, String],
        type: String
    },
    emits: ['showButton', 'changeCO', 'changeK'],
    setup(props, { emit }) {
        const prop = toRefs(props)
        const { arrayMiddle, makeString } = colorMetalFunctions()

        const number = prop.number.value
        const type = prop.type.value
        const span = prop.span.value
        const name = prop.name.value
        const secondName = prop.secondName.value
        const units = prop.units.value
        const years = prop.years.value
        const volume = ref(prop.volume.value)
        const volumeMid = ref(prop.volumeMid.value)
        const CO = ref(prop.CO.value)
        const disabledCO = prop.disabledCO.value
        const koeff = ref(prop.koeff.value)
        const volumeAbs = ref(prop.volumeAbs.value)
        const volumeAbsMid = ref(prop.volumeAbsMid.value)
        const minus = prop.minus.value
        watch(volume.value, (newVol) => {
            for (let key in years) {
                volumeAbs.value[key] = minus ? -Math.abs(Number(newVol[key]) * Number(koeff.value)) : Number(newVol[key]) * Number(koeff.value)
            } 
            volumeMid.value = arrayMiddle(newVol, years)
            volumeAbsMid.value = arrayMiddle(volumeAbs.value, years)
            emit('showButton', false)
        }, {deep: true})
        watch(CO, (newCO) => {
            koeff.value = minus ? -Math.abs(Number(newCO) * 3.664) : Number(newCO) * 3.664
            for (let key in years) {
                volumeAbs.value[key] = minus ? -Math.abs(Number(volume.value[key]) * Number(koeff.value)) : Number(volume.value[key]) * Number(koeff.value)
            }
            CO.value = newCO
            volumeAbsMid.value = arrayMiddle(volumeAbs.value, years)
            emit('changeCO', {CO: CO.value, num: number, type: type})
            emit('changeK', {ko: koeff.value, num: number, type: type})
            emit('showButton', false)
        })


        return {
            makeString, span, name, secondName, units, years, volume, volumeMid, CO, disabledCO, koeff, volumeAbs, volumeAbsMid, minus
        }
    }
}
</script>
<style scoped>
p.qwerty {
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #f2f2f2;
    text-align: left;
    margin-top: -15px;
}

</style>