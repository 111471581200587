<template>
    <div v-if="loader" class="preloader">
        <div class="preloader__row">
            <div class="preloader__item"></div>
            <div class="preloader__item"></div>
        </div>
    </div>
    <div class="container" v-else>
        <div class="wrapper-container">
            <FormKit type="form" :config="{ validationVisibility: 'submit' }" submit-label="Далее" @submit="submitHandler"
                incomplete-message="Не все поля заполнены правильно" :submit-attrs="{
                    'suffix-icon': 'submit'
                }" id="firstStep" :disabled=false>
                <h3 style="text-align: center" v-if="activ == 'Черная металлургия'">
                    ГОСТ Р 113.26.01-2022 «Наилучшие доступные технологии. Методические рекомендации по проведению
                    бенчмаркинга удельных выбросов парниковых газов для отрасли черной металлургии».
                </h3>
                <!-- <p>
                    ГОСТ Р. Наилучшие доступные технологии. Методические рекомендации по
                    проведению бенчмаркинга удельных выбросов парниковых газов для отрасли
                    черной металлургии
                </p> -->
                <h3 style="text-align: center" v-if="activ == 'Цветная металлургия'">
                    Расчет удельных выбросов парниковых газов при производстве первичного алюминия
                </h3>
                <p v-if="activ == 'Цветная металлургия'">
                    Приказ Минприроды России от 27.05.2022 № 371 "Об утверждении методик количественного определения
                    объемов выбросов парниковых газов и поглощений парниковых газов"
                    (Зарегистрирован в Минюсте России 29.07.2022 N 69451)
                </p>
                <div class="">
                    <!-- Раскомментировать когда будет файл -->
                    <!-- <a href="files/gost.pdf" download class="float-end">Скачать файл</a> -->
                </div>
                <br />
                <hr />
                <br />
                <div class="wrapper">
                    <div v-if="mobile">
                        <p class="text-danger">Работа в ограниченном режиме</p>
                        <p class="text-danger">Для более удобной комфортной работы - переверните экран в горизонтальное
                            положение</p>
                    </div>
                    <div class="left">
                        <FormKit label="Наименование предприятия" type="text" name="company_name"
                            placeholder="Введите наименование предприятия" validation="required|length:3"
                            :value="store.state.firstPage.company_name" :validation-messages="{
                                required: 'Не введено наименование предприятия',
                                length: 'Введите минимум 3 символа',
                            }" />
                        <FormKit label="Код ОНВ" type="text" name="onv_code" placeholder="Введите код ОНВ"
                            validation="required|matches:/^[0-9-]+$/" :value="store.state.firstPage.onvCode"
                            :validation-messages="{
                                required: 'Не введен код ОНВ',
                                matches: 'Можно вводить только цифры и знак тире',
                            }" />
                    </div>
                    <div class="right">
                        <FormKit label="Год" type="checkbox" name="year" placeholder="Введите год" :options="years"
                            ref="root" :validation="mobile ? 'required|max:1' : 'required'" :validation-messages="{
                                required: 'Выберите хотя бы один год',
                                max: mobile ? 'Не более одного года' : '',
                            }" :options-class="{ 'years-class': true }" :legend-class="{ 'legends-cl': true }" />
                        <a href="" @click.prevent="allCheck" class="text-decoration-none float-end mx-2"><small>{{
                            allSelected ?
                            'Отменить' : 'Выбрать все' }}</small></a>
                    </div>
                </div>
            </FormKit>
        </div>
    </div>
</template>

<script>
import { ref } from "vue"
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { mobileDetect } from "../use/mobile_detect.js";
export default {
    setup() {
        const root = ref([])
        const allSelected = ref(false)
        const loader = ref(false)

        const router = useRouter();
        const store = useStore();
        const activ = store.getters.activ//activityType
        const { isMobile } = mobileDetect();
        const mobile = ref(false)
        // store.$reset();
        store.commit("setFirstPageData", {});

        async function submitHandler(credentials) {
            loader.value = true
            store.commit("setFirstPageData", credentials)
            setTimeout(function () {
                if(activ == 'Черная металлургия'){
                    router.push("/new-ferrous-metallurgy-calculate");
                } else if(activ == 'Цветная металлургия'){
                    router.push("/non-ferrous-metallurgy-calculate");
                }
                
            }, 10)
        }
        var years = []
        for (let i = (new Date("1990-01-01").getFullYear()); i <= (new Date().getFullYear()); i++) {
            years.push(i)
        }
        if (isMobile.any()) mobile.value = true
        function allCheck() {
            allSelected.value = !allSelected.value
            if (allSelected.value) {
                root.value.node.input(years)
            } else {
                root.value.node.input()
            }
        }
        return {
            submitHandler,
            store, years, mobile, loader, 
            root, allCheck, allSelected, activ
        };
    },
};
</script>

<style>
.container {
    width: 100%;
}

.wrapper-container {
    margin: 0 auto;
    /* margin-top: 5rem; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.formkit-form {
    width: 900px;
}

.wrapper {
    display: flex;
    justify-content: space-around
}

.right,
.left {
    width: 45%;
}

/* .formkit-option {
    display: inline-block;
    margin-right: 5rem !important;
} */
@media (max-width: 900px) {
    .wrapper {
        flex-direction: column
    }

    .left,
    .right {
        width: 95%;
    }

    .formkit-wrapper,
    .formkit-fieldset {
        max-width: 100% important;
    }
}

/* preloader start */
.preloader {
    /*фиксированное позиционирование*/
    position: fixed;
    /* координаты положения */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* фоновый цвет элемента */
    background: #000000;
    opacity: 0.8;
    /* размещаем блок над всеми элементами на странице (это значение должно быть больше, чем у любого другого позиционированного элемента на странице) */
    z-index: 1001;
}

.preloader__row {
    position: relative;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    margin-top: -35px;
    margin-left: -35px;
    text-align: center;
    animation: preloader-rotate 2s infinite linear;
}

.preloader__item {
    position: absolute;
    display: inline-block;
    top: 0;
    background-color: #337ab7;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    animation: preloader-bounce 2s infinite ease-in-out;
}

.preloader__item:last-child {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

@keyframes preloader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader-bounce {

    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

.loaded_hiding .preloader {
    transition: 0.3s opacity;
    opacity: 0;
}

.loaded .preloader {
    display: none;
}

/* preloader end */</style>