<template>
    <AlumWeightByYearsComponent :only="visible" @sendWeights="getWeights($event)" />
    <div class="calc-components mt-4" v-if="visible">
        <div class="comp-toggle d-flex flex-row justify-content-evenly">
            <button v-for="tab in tabs" v-bind:key="tab"
                v-bind:class="['tab-button btn btn-outline-success', { active: currentTab === tab }]"
                v-on:click="toggleTabs(tab)">
                {{ tab }}
            </button>
            <button type="button" class="btn btn-warning calcbtn" @click="getAllFromChilds" data-bs-toggle="modal"
                data-bs-target="#exampleModal">Расчет выбросов парниковых газов в СО<sub>2</sub>-эквиваленте</button>
        </div>
        <AlumDioxidCO2Calculate v-show="showComponent" :weightByYears="weightByYear" :showResult="showResult"
            @sendAllDioxideData="getAllDioxideData($event)" />
        <AlumPerftormCO2Calculate v-show="!showComponent" :weightByYears="weightByYear" :showResult="showResult"
            @sendAllPerformData="getAllPerformData($event)" />
        <div class="big-chart">
            <Bar :chart-data="chartData" :chart-options="chartOptions" :width="width1" :height="height1"
                ref="BarMainChart" />
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <!-- если на весь экран, то добавить modal-fullscreen вместо modal-xl  -->
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <EquivalentCO2Calculate @sendAllEquivalentData="getAllEquivalentData($event)" v-if="showResult"
                        :key="allKey" :weightByYears="weightByYear" :showResult="showResult" :performData="performData"
                        :dioxideData="dioxideData" />
                </div>
                <div class="modal-footer justify-content-evenly">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                    <button class="btn btn-primary" data-bs-target="#exampleModalToggle2"
                        data-bs-toggle="modal">График</button>
                    <button type="button" class="btn btn-primary" @click="createDocument">Сформировать отчет</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">График - Удельные выбросы, т СО2-экв. / т Ал.
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <Bar :chart-data="chartData" :chart-options="chartOptions" :width="width1" :height="height1"
                            ref="BarMainChart" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-target="#exampleModal" data-bs-toggle="modal">Назад</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, annotationPlugin)
// ChartJS.register(annotationPlugin);

import {
    Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, PageOrientation, Table, TableCell, TableRow,
    Math, MathRun, MathSubScript, MathSubSuperScript, ImageRun, WidthType, VerticalAlign
} from "docx";
import { saveAs } from 'file-saver'; // you can use this also
import AlumWeightByYearsComponent from '../components/colorMetal/AlumWeightByYearsComponent.vue'
import AlumDioxidCO2Calculate from '../components/colorMetal/AlumDioxidCO2Calculate.vue'
import AlumPerftormCO2Calculate from '../components/colorMetal/AlumPerftormCO2Calculate.vue'
import EquivalentCO2Calculate from '../components/colorMetal/EquivalentCO2Calculate.vue'
import { ref, computed, watch } from "vue";
import { useStore } from 'vuex';
export default {

    components: { AlumWeightByYearsComponent, AlumDioxidCO2Calculate, AlumPerftormCO2Calculate, EquivalentCO2Calculate, Bar },
    setup() {
        const BarMainChart = ref(null)

        const width1 = ref(600)
        const height1 = ref(300)
        const chartData = ref({})
        const chartOptions = ref({})

        const store = useStore()
        const showResult = ref(false)//показывать конечные расчеты
        const componentKey = ref(0);//чтобы перерендились компоненты
        const visible = ref(false)//видимость основных блоков с расчетами
        const weightByYear = ref({})//вес алюминия по годам
        const showComponent = ref(true)
        const performData = ref({})
        const dioxideData = ref({})
        const equivalentData = ref({}) //потом надо будет
        const allKey = ref(0)
        const getWeights = (event) => {
            weightByYear.value = event
            visible.value = true
        }
        watch(weightByYear, () => {
            // componentKey.value += 1
        }, { deep: true })
        watch(dioxideData, () => {
            // componentKey.value += 1
        }, { deep: true })
        const currentTab = ref('Расчет выбросов диоксида углерода')//компонент по умолчанию
        const currentTabComponent = computed(() => {//текущий компонент
            return currentTab.value
        })
        function toggleTabs(tab) {
            if (currentTab.value !== tab) {
                showComponent.value = !showComponent.value
                currentTab.value = tab
            } else {
                showComponent.value = showComponent.value
            }
        }
        function getAllFromChilds() {
            showResult.value = true
            allKey.value++
        }
        const getAllPerformData = (event) => {
            performData.value = event
            allKey.value++
        }
        const getAllDioxideData = (event) => {
            dioxideData.value = event
            allKey.value++
        }
        const getAllEquivalentData = (event) => {
            equivalentData.value = event
            showGraf()
        }
        const createDocument = () => {
            createDoc(performData.value, dioxideData.value, equivalentData.value)
        }
        // graf

        function showGraf() {
            let lab = Object.keys(equivalentData.value.udelVybrSumm)//labels для графика
            lab.push("Среднее")
            let dat = Object.values(equivalentData.value.udelVybrSumm)//данные для графика
            dat.push(equivalentData.value.udelVybrSummMiddle)

            var gColors = []//массив для цветов графика
            for (let i = 0; i < dat.length; i++) {
                if (i == dat.length - 1) {
                    gColors.push('#0559C9')
                } else {
                    gColors.push('#72A6FB')
                }
            }
            chartData.value = {
                labels: lab,
                datasets: [
                    {
                        // backgroundColor: '#72A5FB',
                        backgroundColor: gColors,
                        data: dat
                    }
                ]
            }
            chartOptions.value = {
                barThickness: 50,
                plugins: {
                    legend: {
                        display: false,
                    },
                    autocolors: false,
                    annotation: {
                        annotations: {//две горизонтальные линии на графике
                            line1: {
                                type: 'line',
                                yMin: 1.972,
                                yMax: 1.972,
                                borderColor: "#00b050",
                                borderWidth: 2,
                                label: {
                                    content: "1.972 (ИП 2)",
                                    display: true,
                                    color: "#00b050",
                                    height: 50
                                }
                            },
                            line2: {
                                type: 'line',
                                yMin: 2.509,
                                yMax: 2.509,
                                borderColor: "#ff7f27",
                                borderWidth: 2,
                                label: {
                                    content: "2.509 (ИП 1)",
                                    display: true,
                                    color: "#ff7f27"
                                }
                            }
                        }
                    }
                }
            }
        }
        //end graf
        const createDoc = () => {
            const canvas = document.getElementById('bar-chart');//элемент канвас с графиком
            const dataURL = canvas.toDataURL();//получаем ссылку на файл графика

            const doc = new Document({
                styles: {
                    paragraphStyles: [
                        {
                            id: "usualPar",
                            name: "My Paragraf Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 22
                            },
                            paragraph: {
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                indent: {
                                    left: 0,
                                },
                            },
                        },
                        {
                            id: "textPar",
                            name: "My Paragraf Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 24
                            },
                            paragraph: {
                                spacing: {
                                    before: 40,
                                    after: 40
                                },
                                indent: {
                                    left: 0,
                                },
                            },
                        },
                        {
                            id: "formula",
                            name: "My formula Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 28,
                                italics: true,
                            },
                            paragraph: {
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                indent: {
                                    left: 0,
                                },
                            },
                        },
                        {
                            id: "tabCellStyle",
                            name: "Table Cell Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 24,
                            },
                            paragraph: {
                                alignment: AlignmentType.CENTER,
                            },
                        },
                        {
                            id: "YearsCellStyle",
                            name: "Table Cell Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 20,
                            },
                            paragraph: {
                                alignment: AlignmentType.CENTER,
                            },
                        },
                        {
                            id: "BigTablesCellStyle",
                            name: "Table Cell Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 14,
                            },
                            paragraph: {
                                alignment: AlignmentType.CENTER,
                            },
                        },
                    ]
                },
                sections: [
                    {
                        properties: {
                            page: {
                                size: {
                                    orientation: PageOrientation.PORTRAIT,
                                },
                                margin: {
                                    top: "0.7in",
                                    bottom: "0.7in",
                                    left: "0.8in",
                                    right: "0.8in"
                                }
                            },
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет выбросов парниковых газов для предприятий производства первичного алюминия",
                                        bold: true,
                                        size: 32,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Объект, оказывающий негативное воздействие: ",
                                        bold: true,
                                        size: 28,
                                        // color: "#00b050",
                                    }),
                                    new TextRun({
                                        text: store.state.firstPage.company_name,
                                        bold: false,
                                        size: 28,
                                        // color: "#00b050",
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Код ОНВ: ",
                                        bold: true,
                                        size: 28,
                                        // color: "#00b050",
                                    }),
                                    new TextRun({
                                        text: store.state.firstPage.onv_code,
                                        bold: false,
                                        size: 28,
                                        // color: "#00b050",
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "МЕТОДИКА ПРОВЕДЕНИЯ РАСЧЕТА ВЫБРОСОВ ПАРНИКОВЫХ ГАЗОВ",
                                        bold: true,
                                        size: 24,
                                        // color: "#00b050",
                                        marginTop: 24
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет произведен по Методике количественного определения объемов выбросов парниковых газов (утверждена Приказом Минприроды России от 27.05.2022 № 371 (Зарегистрирован в Минюсте России 29.07.2022 № 69451).",
                                        bold: false,
                                        size: 24,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.  РАСЧЕТ АБСОЛЮТНЫХ ВЫБРОСОВ ПАРНИКОВЫХ ГАЗОВ",
                                        bold: true,
                                        size: 24,
                                        // color: "#00b050",
                                        marginTop: 24
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.1.	Расчет абсолютных выбросов перфторуглеродов (CF",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "4",
                                        subScript: true,
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: ") для каждого производственного процесса электролитического получения алюминия проводится по формуле (1):",
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),

                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (1)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" / т"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выпуск алюминия (электролитического), т/год."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет удельного выброса перфторуглеродов (CF",
                                        bold: false,
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "4",
                                        subScript: true,
                                        bold: false,
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: ") от производственного процесса электролитического получения алюминия проводится по формуле (2):",
                                        bold: false,
                                        size: 24,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" * "),
                                            new MathRun("AEM / 1000")

                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (2)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" от производства алюминия, т CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" / т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – угловой коэффициент для CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(", (кг CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал.) / (минуты анодного эффекта/ванно-сутки);"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "AEM",
                                        italics: true
                                    }),
                                    new TextRun(" – минуты анодного эффекта на ванно-сутки, (минуты анодного эффекта/ванно-сутки)."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("При этом:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("AEM = AEF * AED")
                                        ]
                                    }),
                                    new TextRun({
                                        text: ",   (3)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("AEF")
                                        ]
                                    }),
                                    new TextRun(" - средняя частота анодных эффектов за период, шт./ванно-сутки;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("AED")
                                        ]
                                    }),
                                    new TextRun(" - средняя продолжительность анодных эффектов за период, минут/шт."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.2.	Расчет абсолютных выбросов перфторуглеродов (C",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "2",
                                        subScript: true,
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "F",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "6",
                                        subScript: true,
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: ") для каждого производственного процесса электролитического получения алюминия проводится по формуле (4):",
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                pageBreakBefore: true,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            })
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (4)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" / т"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выпуск алюминия (электролитического), т/год."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет удельного выброса перфторуглеродов (C",
                                        bold: false,
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: false,
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: "F",
                                        bold: false,
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "6",
                                        bold: false,
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ") от производственного процесса электролитического получения алюминия проводится по формуле (5):",
                                        bold: false,
                                        size: 24,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("C2F6/CF4")],
                                            })
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (5)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ЭЛ")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" от производства алюминия, т C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" / т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [new MathRun("C2F6/CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – весовая доля C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun("/CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(", кг C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" / кг CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun("."),
                                ],
                                style: "textPar",
                                spacing: {
                                    after: 240
                                }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет выбросов перфторуглеродов выполняется по отдельным корпусам электролиза с учетом применяемой технологии получения первичного алюминия."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Производство электролитического алюминия (M"),
                                    new TextRun({
                                        text: "Al",
                                        subScript: true
                                    }),
                                    new TextRun("), включающего наработку первичного алюминия в электролизерах за отчетный период, определяется организациями по корпусам электролиза в соответствии с утвержденными на предприятиях технологическими регламентами."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Угловой коэффициент выбросов CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" (SCF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(") зависит от используемой технологии получения первичного алюминия и технологических параметров производства. Следует использовать значения угловых коэффициентов, приведенные в таблице Д.2 настоящего приложения. Организации могут самостоятельно определять значения углового коэффициента (SCF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(") на основе выполненных инструментальных измерений. Значения угловых коэффициентов устанавливаются для отдельного предприятия и конкретной технологии производства первичного алюминия с актуализацией не менее 1 раза в пять лет или при существенных изменениях в технологии производства."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Значение весового отношения C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" к CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" (FC"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun("/CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(") принимается в соответствии с данными для различных технологий производства первичного алюминия, приведенными в таблице 1. Организации могут самостоятельно определять значения весового отношения C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" к CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" (FC"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun("/CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(") на основе выполненных инструментальных измерений. Значение весового отношения C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(" к CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" устанавливается для отдельного предприятия и конкретной технологии производства первичного алюминия с актуализацией не менее 1 раза в пять лет или при существенных изменениях в технологии производства."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Средняя частота анодных эффектов (AEF) и средняя продолжительность анодных эффектов (AED) принимается по фактическим данным регистрации технологических параметров электролизных корпусов."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Таблица 1 – Угловые коэффициенты, весовое отношение C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun("/CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(" и содержание окисляемого углерода в анодной массе (предварительно обожженных анодах) для расчета выбросов ПГ от производства алюминия по различным технологиям"),
                                ],
                                style: "textPar",
                                pageBreakBefore: true
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Технология"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Угловой коэффициент для CF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: " (SCF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: "), (кг CF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: " / т алюминия) / (минуты анодного эффекта /ванно-сутки)"
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Весовое отношение C"
                                                            }),
                                                            new TextRun({
                                                                text: "2",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: "F"
                                                            }),
                                                            new TextRun({
                                                                text: "6",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: " / CF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: " (",
                                                            }),
                                                            new Math({
                                                                children: [
                                                                    new MathSubScript({
                                                                        children: [
                                                                            new MathRun("F")
                                                                        ],
                                                                        subScript: [new MathRun("C2F6/CF4")],
                                                                    })
                                                                ]
                                                            }),
                                                            new TextRun({
                                                                text: "), кг C"
                                                            }),
                                                            new TextRun({
                                                                text: "2",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: "F"
                                                            }),
                                                            new TextRun({
                                                                text: "6",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: " / кг CF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Электролизеры с предварительно обожженными анодами (CWPB)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,143"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,121"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Электролизеры Содерберга с верхним токоподводом (VSS)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,092"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,053"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Электролизеры Содерберга с боковым токоподводом (HSS)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,099"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0,085"
                                                            })
                                                        ],
                                                        style: "tabCellStyle",
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.3.	Расчет абсолютных выбросов диоксида углерода (СО",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: true,
                                        size: 28,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ") для производственных процессов электролитического получения алюминия проводится по формулам (5-6):",
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (5)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (6)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролитического получения алюминия на электролизерах Содерберга, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролитического получения алюминия на электролизерах с обожженными анодами, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролитического получения алюминия на электролизерах Содерберга, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролитического получения алюминия на электролизерах с обожженными анодами, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выпуск алюминия (электролитического), т/год."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1) на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом (формула 5)",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет удельного выброса диоксида углерода(CО",
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "2",
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ") от производственного процесса электролитического получения алюминия проводится по формуле (7):",
                                        size: 24,
                                    }),
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" = ("),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("am")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("н")],
                                                subScript: [new MathRun("am")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("sz")],
                                                subScript: [new MathRun("am")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("cm")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun(" ) * 44/12 + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("MO")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (7)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролизных корпусов, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – расход анодной массы, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("н")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери анодной массы с водородом, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("sz")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери анодной массы с серой и золой, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода со смолистыми веществами, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с пылью, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с угольной пеной, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("MO")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" при мокрой очистке отходящих газов содовым раствором, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("44/12")
                                        ]
                                    }),
                                    new TextRun(" – стехиометрический коэффициент пересчета углерода в CO"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь анодной массы в связи с содержанием в ней водорода осуществляется по формуле (8):",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("н")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("H")],
                                                subScript: [new MathRun("n")],
                                            }),
                                            new MathRun("/100 "),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (8)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("н")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери анодной массы с водородом, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("H")],
                                                subScript: [new MathRun("n")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание водорода в анодной массе, %"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь анодной массы в связи с содержанием в ней серы и золы осуществляется по формуле (9):",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("sz")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(" * ("),
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("Z")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                            new MathRun(")/100 "),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (9)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("sz")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери анодной массы с серой и золой, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание серы в анодной массе, %;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("Z")],
                                                subScript: [new MathRun("ам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание золы в анодной массе, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь углерода со смолистыми веществами осуществляется по формулам (10-13):",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "-	при наличии мокрой ступени газоочистки:",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("с")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" /100 + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun("/100 "),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (10)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потерь углерода со смолистыми веществами, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выброс смолистых веществ через фонарь, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в смолистых веществах, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельное поступление смолистых веществ в ГОУ, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "-	при наличии сухой газоочистки:",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("с")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" /100 + ")
                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (11)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потерь углерода со смолистыми веществами, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выброс смолистых веществ через фонарь, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в смолистых веществах, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом:",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" = (1 - "),
                                            new MathSubScript({
                                                children: [new MathRun("η")],
                                                subScript: [new MathRun("k")],
                                            }),
                                            new MathRun(") * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("пш")],
                                                subScript: [new MathRun("см")],
                                            }),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (12)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выброс смолистых веществ через фонарь, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("η")],
                                                subScript: [new MathRun("k")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – эффективность укрытия электролизера, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельное поступление смолистых веществ в ГОУ, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("пш")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – количество смолистых веществ, выделяющихся в атмосферу при перестановке штырей для электролизеров с верхним токоподводом, кг/т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом:",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("пш")],
                                                subScript: [new MathRun("см")],
                                            }),
                                            new MathRun(" = "),

                                            new MathRun("0,785"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("d")],
                                                superScript: [new MathRun("2")],
                                                subScript: [new MathRun("л")],
                                            }),
                                            new MathRun(" * "),
                                            new MathRun("h"),
                                            new MathRun(" * "),
                                            new MathRun("q"),
                                            new MathRun(" * "),
                                            new MathRun("c"),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("p")],
                                                subScript: [new MathRun("жам")],
                                            }),
                                            new MathRun(" * (1 - k)"),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("n")],
                                                subScript: [new MathRun("ш")],
                                            }),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (13)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("пш")],
                                                subScript: [new MathRun("см")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – количество смолистых веществ, выделяющихся в атмосферу при перестановке штырей для электролизеров с верхним токоподводом, кг/т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("d")],
                                                subScript: [new MathRun("л")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – диаметр лунки (принят равным среднему диаметру участка штыря, запеченного в теле анода), дм;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "h",
                                        italics: true
                                    }),
                                    new TextRun(" – средняя по электролизеру высота лунки (высота штыря в запечной части анода), дм;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "q",
                                        italics: true
                                    }),
                                    new TextRun(" – степень заполнения лунки, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "c",
                                        italics: true
                                    }),
                                    new TextRun(" – содержание пека в анодной массе, загружаемой перед перестановкой штырей, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("p")],
                                                subScript: [new MathRun("жам")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – плотность жидкой анодной массы, кг/дм3;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "k",
                                        italics: true
                                    }),
                                    new TextRun(" – выход кокса при быстром коксовании пека, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("n")],
                                                subScript: [new MathRun("ш")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – количество переставляемых штырей в расчете на 1 т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь углерода с пылью осуществляется по формулам (14 -16):",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "-	при наличии мокрой ступени газоочистки:",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("с")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" /100 + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/100 "),

                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (14)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с пылью, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс пыли через фонарь, кг/т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельное поступление пыли в ГОУ, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в пыли, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "-	при наличии сухой газоочистки:",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("с")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/1000"),
                                            new MathRun(" * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" /100")
                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (15)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с пылью, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс пыли через фонарь, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в пыли, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом:",
                                        // bold: true,
                                        // italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" = ("),

                                            new MathRun("1 - "),
                                            new MathSubScript({
                                                children: [new MathRun("η")],
                                                subScript: [new MathRun("k")],
                                            }),
                                            new MathRun(") * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ], style: "formula",
                                    }),
                                    new TextRun({
                                        text: ",    (16)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс пыли через фонарь, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("η")],
                                                subScript: [new MathRun("k")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" –эффективность укрытия электролизера, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельное поступление пыли в ГОУ, кг/т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь углерода с угольной пеной осуществляется по формуле (17):",
                                        bold: false,
                                        size: 24,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("вых")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun("/1000 * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun("/100"),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (17)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с угольной пеной, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("вых")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выход угольной пены, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в пене, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет выбросов диоксида углерода при мокрой очистке отходящих газов содовым раствором осуществляется по формуле (18):",
                                        bold: false,
                                        size: 24,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("мо")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("SO2")],
                                            }),
                                            new MathRun("/1000 * "),
                                            new MathRun("η"),
                                            new MathRun(" * 44/64 "),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (18)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("мо")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс диоксида углерода при мокрой очистке отходящих газов содовым раствором, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("SO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – Удельное поступление диоксида серы в ГОУ, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("η")
                                        ]
                                    }),
                                    new TextRun(" – эффективность улавливания диоксида серы в ГОУ, доли ед.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("44/64")
                                        ]
                                    }),
                                    new TextRun(" – стехиометрический коэффициент пересчета углерода в CO"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет выбросов СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" выполняется по отдельным корпусам электролиза с учетом применяемой технологии получения первичного алюминия."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Производство электролитического алюминия (M"),
                                    new TextRun({
                                        text: "Al",
                                        subScript: true
                                    }),
                                    new TextRun("), включающего наработку первичного алюминия в электролизерах за отчетный период, определяется организациями по корпусам электролиза в соответствии с утвержденными на предприятиях технологическими регламентами."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Удельный расход анодной массы (P"),
                                    new TextRun({
                                        text: "ам",
                                        subScript: true
                                    }),
                                    new TextRun(") за отчетный период принимается по фактическим данным организаций, определенным по материальным балансам сырья."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Содержание водорода в анодной массе (H"),
                                    new TextRun({
                                        text: "n",
                                        subScript: true
                                    }),
                                    new TextRun(") (формула 8) определяется по данным, полученным в результате установленных в организации процедур, при отсутствии данных принимается 1,4%."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Содержание серы и золы в анодной массе (S"),
                                    new TextRun({
                                        text: "ам",
                                        subScript: true
                                    }),
                                    new TextRun(") и (Z"),
                                    new TextRun({
                                        text: "ам",
                                        subScript: true
                                    }),
                                    new TextRun(") (формула 9) принимается по данным, полученным в результате установленных в организации процедур."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("При расчете потерь углерода со смолистыми веществами (формула 10) удельное поступление смолистых веществ в газоочистные установки ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("см")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") принимается по данным учета выбросов, содержание углерода в смолистых веществах ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("см")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") принимается по лабораторного анализа, при отсутствии экспериментальных данных принимается равным 95%."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Количество смолистых веществ ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("пш")],
                                                subScript: [new MathRun("см")]
                                            })
                                        ]
                                    }),
                                    new TextRun("), выделяющихся в атмосферу при перестановке штырей для электролизеров с верхним токоподводом определяется формулой (13)."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Удельное поступление пыли в газоочистные установки принимается по данным учета выбросов ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("г")],
                                                subScript: [new MathRun("пыль")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") содержание углерода в пыли ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") определяются по данным, полученным в результате установленных в организации процедур (формула 14)."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("При расчете потерь углерода с угольной пеной (формула 17) содержание углерода в пене ("),
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") принимается по данным учета выбросов."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Удельное поступление диоксида серы ("),
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("SO2")]
                                            })
                                        ]
                                    }),
                                    new TextRun(") (формула 18) в газоочистные установки принимается по данным учета выбросов."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "2) на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA) (формула 19):",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" = ("),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("a")],
                                            }),
                                            new MathRun(" * (100 - "),
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("a")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubScript({
                                                children: [new MathRun("Z")],
                                                subScript: [new MathRun("a")],
                                            }),
                                            new MathRun("/100)"),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun(") *  "),
                                            new MathRun("44/12"),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (19)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельный выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролизных корпусов, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" / т;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("a")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – расход обожженных анодов нетто, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("S")],
                                                subScript: [new MathRun("a")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание серы в обожженном аноде, %;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("Z")],
                                                subScript: [new MathRun("a")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание золы в обожженном аноде, %;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с пылью, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери диоксида углерода с угольной пеной, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathRun("44/12")
                                        ]
                                    }),
                                    new TextRun(" – стехиометрический коэффициент пересчета углерода в CO"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь углерода с пылью осуществляется по формуле (20):",
                                        bold: false,
                                        size: 24,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/1000 * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                            new MathRun("/100"),

                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (20)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с пылью, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("ф")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельное поступление пыли в ГОУ, кг/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пыль")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в пыли, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "При этом расчет потерь углерода с угольной пеной осуществляется по формуле (21):",
                                        bold: false,
                                        size: 24,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("вых")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun("/1000 * "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                            new MathRun("/100"),

                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (21)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("M")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потери углерода с угольной пеной, т/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("P")],
                                                superScript: [new MathRun("вых")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выход угольной пены, кг/т Ал."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("W")],
                                                superScript: [new MathRun("c")],
                                                subScript: [new MathRun("пена")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – содержание углерода в пене, %."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Удельный расход обожженных анодов ("),
                                    new TextRun({
                                        text: "P",
                                        italics: true
                                    }),
                                    new TextRun({
                                        text: "a",
                                        italics: true,
                                        subScript: true
                                    }),
                                    new TextRun(") за отчетный период принимается по фактическим данным организаций, определенным по материальным балансам сырья."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Содержание серы и золы в обожженных анодах ("),
                                    new TextRun({
                                        text: "S",
                                        italics: true
                                    }),
                                    new TextRun({
                                        text: "a",
                                        italics: true,
                                        subScript: true
                                    }),
                                    new TextRun(") и ("),
                                    new TextRun({
                                        text: "Z",
                                        italics: true
                                    }),
                                    new TextRun({
                                        text: "a",
                                        italics: true,
                                        subScript: true
                                    }),
                                    new TextRun(") принимается по данным, полученным в результате установленных в организации процедур."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.4.	Приведение суммарных прямых абсолютных выбросов ПГ от электролитического получения алюминия к эквиваленту СО",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: true,
                                        size: 28,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ":",
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1)	на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом (формула 22):",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2-экв.")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (22)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовые выбросы ПГ, т СО"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс диоксида углерода от производственного процесса электролитического получения алюминия, т C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролизных корпусов, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал;."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "2)	на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA) (формула 23):",
                                        bold: true,
                                        italics: true,
                                        size: 24,
                                    })
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2-экв.")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                            new MathRun(" * "),
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (23)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовые выбросы ПГ, т СО"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (CF4)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от производственного процесса электролитического получения алюминия, т CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CF4")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл")],
                                                subScript: [new MathRun("ПФУ (C2F6)")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс диоксида углерода от производственного процесса электролитического получения алюминия, т C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("C2F6")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовый выброс СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролизных корпусов, т СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ПГП")],
                                                subScript: [new MathRun("CO2")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – потенциал глобального потепления для СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun("/т Ал;."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Потенциал глобального потепления (ПГП) для перфторметана (CF"),
                                    new TextRun({
                                        text: "4",
                                        subScript: true
                                    }),
                                    new TextRun("), перфторэтана (C"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun("F"),
                                    new TextRun({
                                        text: "6",
                                        subScript: true
                                    }),
                                    new TextRun("), диоксида углерода (СО"),
                                    new TextRun({
                                        text: "2",
                                        subScript: true
                                    }),
                                    new TextRun(") определяется в соответствии с перечнем ПГ, утвержденным распоряжением Правительства Российской Федерации от 22.10.2021 № 2979-р «Об утверждении перечня парниковых газов, в отношении которых осуществляется государственный учет выбросов парниковых газов и ведение кадастра парниковых газов». Следует использовать значения потенциалов глобального потепления, приведенные в таблице 2."),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Таблица 2 – Значения потенциалов глобального потепления парниковых газов"),
                                ],
                                style: "usualPar"
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "N"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Парниковый газ"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Код вещества"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Химическая формула"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Потенциал глобальногопотепления (ПГПi"
                                                            }),
                                                            new TextRun({
                                                                text: "i",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: ")"
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "1"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Диоксид углерода"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0380"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "CO"
                                                            }),
                                                            new TextRun({
                                                                text: "2",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "1"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "2"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Метан"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0410"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "CH"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "25"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "3"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Закись азота"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0381"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "N"
                                                            }),
                                                            new TextRun({
                                                                text: "2",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: "O"
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "298"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "4"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Трифторметан (HFC-23)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0966"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "CHF"
                                                            }),
                                                            new TextRun({
                                                                text: "3",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "14800"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "5"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Перфторметан (PFC-14)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0965"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "CF"
                                                            }),
                                                            new TextRun({
                                                                text: "4",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "7390"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "6"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Перфторэтан (PFC-116)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0963"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "C"
                                                            }),
                                                            new TextRun({
                                                                text: "2",
                                                                subScript: true
                                                            }),
                                                            new TextRun({
                                                                text: "F"
                                                            }),
                                                            new TextRun({
                                                                text: "6",
                                                                subScript: true
                                                            }),
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "12200"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "7"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Гексафторид серы"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "0369"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "SF"
                                                            }),
                                                            new TextRun({
                                                                text: "6",
                                                                subScript: true
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "22800"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Решение 24/CP.19 Конференции Сторон Рамочной Конвенции Организации Объединенных Наций об изменении климата (далее - РКИК), ратифицированной Федеральным законом от 04.11.1994 N 34-ФЗ \"О ратификации рамочной конвенции ООН об изменении климата\" (Собрание законодательства Российской Федерации, 1994, N 28, ст. 2927) (официальный сайт РКИК http://unfccc.int/).",
                                                                size: 20
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                columnSpan: 5,
                                            })
                                        ]
                                    })
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.5.	Расчет суммарных выбросов ПГ в т СО",
                                        bold: true,
                                        size: 28,
                                    }),
                                    new TextRun({
                                        text: "2-экв.",
                                        bold: true,
                                        size: 28,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: " проводится по формуле (24):",
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                pageBreakBefore: true,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (24)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл содб")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовые выбросы ПГ, т СО"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("E")],
                                                superScript: [new MathRun("эл оа")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – массовые выбросы ПГ, т СО"),
                                    new TextRun({
                                        text: "2-экв.",
                                        subScript: true
                                    }),
                                    new TextRun(";"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1.6.	Расчет удельных выбросов парниковых газов",
                                        bold: true,
                                        size: 28,
                                        // color: "#00b050",
                                    })
                                ],
                                alignment: AlignmentType.LEFT,
                                spacing: {
                                    before: 100,
                                    after: 100
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет удельных выбросов ПГ (УВ",
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "СО2-экв.",
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ") проводится на основании данных о суммарных значениях выбросов ПГ, выраженных в т СО",
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: "2-экв.",
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: ", и сведениях о производимой продукции по видам производственных процессов электролитического получения алюминия по формуле (25):",
                                        size: 24,
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("УВ")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                            new MathRun("/"),
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ],
                                    }),
                                    new TextRun({
                                        text: ",    (25)",
                                        italics: false
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "formula",
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("УВ")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – удельные выбросы CO"),
                                    new TextRun({
                                        text: "2-экв",
                                        subScript: true
                                    }),
                                    new TextRun(", т CO"),
                                    new TextRun({
                                        text: "2-экв",
                                        subScript: true
                                    }),
                                    new TextRun("/ т Ал.;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [new MathRun("CO2-экв")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выброс CO"),
                                    new TextRun({
                                        text: "2-экв",
                                        subScript: true
                                    }),
                                    new TextRun(" от электролизного производства, т/год;"),
                                ],
                                style: "textPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("M")],
                                                subScript: [new MathRun("Al")],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – выпуск алюминия (электролитического), т Ал. /год."),
                                ],
                                style: "textPar"
                            }),
                        ]
                    }, {
                        properties: {
                            page: {
                                size: {
                                    orientation: PageOrientation.LANDSCAPE,
                                },
                                margin: {
                                    top: "0.5in",
                                    bottom: "0.5in",
                                    left: "0.8in",
                                    right: "0.8in"
                                }
                            },
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "2.	ИСХОДНЫЕ ДАННЫЕ",
                                        bold: true,
                                        size: 24,
                                        // color: "#00b050",
                                        marginTop: 24
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    makeFirstTitle()
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Продукция",
                                                                bold: true,
                                                            })
                                                        ],
                                                        style: "YearsCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                                rowSpan: 2,
                                                shading: {
                                                    fill: "d0cece",
                                                    color: "auto",
                                                },
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Обозначение",
                                                                bold: true,
                                                            })
                                                        ],
                                                        style: "YearsCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                                rowSpan: 2,
                                                shading: {
                                                    fill: "d0cece",
                                                    color: "auto",
                                                },
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Единица измерения",
                                                                bold: true,
                                                            })
                                                        ],
                                                        style: "YearsCellStyle"
                                                    })
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                                rowSpan: 2,
                                                shading: {
                                                    fill: "d0cece",
                                                    color: "auto",
                                                },
                                            }),
                                            new TableCell({
                                                children: [new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: "Величина",
                                                            bold: true,
                                                        })
                                                    ],
                                                    style: "YearsCellStyle"
                                                })],
                                                verticalAlign: VerticalAlign.CENTER,
                                                columnSpan: store.state.firstPage.year.length + 1,
                                                shading: {
                                                    fill: "d0cece",
                                                    color: "auto",
                                                },
                                            }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: makeYearsForTable()
                                    }),
                                    new TableRow({
                                        children: makeYearsWeightForTable()
                                    })
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Исходные данные для расчета выбросов СО",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: false,
                                        italics: true,
                                        size: 24,
                                        subScript: true
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                style: "usualPar"
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: createBigTable()
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Исходные данные для расчета выбросов СF",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                    new TextRun({
                                        text: "4",
                                        bold: false,
                                        italics: true,
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: " и C",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: false,
                                        italics: true,
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: "F",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                    new TextRun({
                                        text: "6",
                                        bold: false,
                                        italics: true,
                                        size: 24,
                                        subScript: true
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                style: "usualPar"
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: createBigTableTwo()
                            }),
                            new Paragraph({
                                children: [
                                    ""
                                ]
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: createBigTableThree()
                            }),
                        ]
                    }, {
                        properties: {
                            page: {
                                size: {
                                    orientation: PageOrientation.PORTRAIT,
                                },
                                margin: {
                                    top: "0.5in",
                                    bottom: "0.5in",
                                    left: "0.8in",
                                    right: "0.8in"
                                }
                            },
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "3.	РЕЗУЛЬТАТЫ ПРОВЕДЕНИЯ РАСЧЕТА ВЫБРОСОВ ПАРНИКОВЫХ ГАЗОВ",
                                        bold: true,
                                        size: 24,
                                        // color: "#00b050",
                                        marginTop: 24
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Итоговые абсолютные и удельные выбросы СО",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                    new TextRun({
                                        text: "2",
                                        bold: false,
                                        italics: true,
                                        size: 24,
                                        subScript: true
                                    }),
                                    new TextRun({
                                        text: " по производственному процессу",
                                        bold: false,
                                        italics: true,
                                        size: 24
                                    }),
                                ],
                                alignment: AlignmentType.LEFT,
                                style: "usualPar"
                            }),
                            new Table({
                                alignment: AlignmentType.CENTER,
                                width: {
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: makeYearsForTableTwo()
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Абсолютные выбросы, т",
                                                                bold: true
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER,
                                                    })
                                                ],
                                                columnSpan: store.state.firstPage.year.length + 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы СО"
                                            }),
                                            new TextRun({
                                                text: "2",
                                                subScript: true
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т"
                                            })
                                        ], dioxideData.value.absDioxideEmission, dioxideData.value.absDioxideEmissionMiddle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы СF"
                                            }),
                                            new TextRun({
                                                text: "4",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " (ПФУ-14)"
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т"
                                            })
                                        ], performData.value.absEmissionCF4, performData.value.absEmissionCF4Middle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы C"
                                            }),
                                            new TextRun({
                                                text: "2",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: "F"
                                            }),
                                            new TextRun({
                                                text: "6",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " (ПФУ-116)"
                                            })
                                        ], [
                                            new TextRun({
                                                text: "т"
                                            })
                                        ], performData.value.absEmissionC2F6, performData.value.absEmissionC2F6Middle)
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Абсолютные выбросы, т СО2-экв",
                                                                bold: true
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER,
                                                    })
                                                ],
                                                columnSpan: store.state.firstPage.year.length + 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы СО"
                                            }),
                                            new TextRun({
                                                text: "2",
                                                subScript: true
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв",
                                                subScript: true
                                            }),
                                        ], equivalentData.value.AbcEquivCO2, equivalentData.value.AbcEquivCO2Middle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы СF"
                                            }),
                                            new TextRun({
                                                text: "4",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " (ПФУ-14)"
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв",
                                                subScript: true
                                            }),
                                        ], equivalentData.value.AbcEquivCF4, equivalentData.value.AbcEquivCF4Middle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Абсолютные выбросы C"
                                            }),
                                            new TextRun({
                                                text: "2",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: "F"
                                            }),
                                            new TextRun({
                                                text: "6",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " (ПФУ-116)"
                                            })
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв",
                                                subScript: true
                                            }),
                                        ], equivalentData.value.AbcEquivC2F6, equivalentData.value.AbcEquivC2F6Middle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Суммарные абсолютные выбросы ПГ"
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв",
                                                subScript: true
                                            }),
                                        ], equivalentData.value.absSummVybr, equivalentData.value.absSummVybrMiddle)
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Удельные выбросы, т СО2-экв. / т Ал.",
                                                                bold: true
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER,
                                                    })
                                                ],
                                                columnSpan: store.state.firstPage.year.length + 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Удельные выбросы СО",
                                            }),
                                            new TextRun({
                                                text: "2",
                                                subScript: true
                                            }),
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв.",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " / т Ал."
                                            }),
                                        ], equivalentData.value.udelVybrCO2, equivalentData.value.udelVybrCO2Middle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Удельные выбросы ПФУ"
                                            })
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв.",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " / т Ал."
                                            }),
                                        ], equivalentData.value.udelVybrPFU, equivalentData.value.udelVybrPFUMiddle)
                                    }),
                                    new TableRow({
                                        children: totalTableString([
                                            new TextRun({
                                                text: "Суммарные удельные выбросы ПГ"
                                            })
                                        ], [
                                            new TextRun({
                                                text: "т СО"
                                            }),
                                            new TextRun({
                                                text: "2-экв.",
                                                subScript: true
                                            }),
                                            new TextRun({
                                                text: " / т Ал."
                                            }),
                                        ], equivalentData.value.udelVybrSumm, equivalentData.value.udelVybrSummMiddle)
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "4.	ДИНАМИКА ВЫБРОСОВ ПАРНИКОВЫХ ГАЗОВ",
                                        bold: true,
                                        size: 24,
                                        // color: "#00b050",
                                        marginTop: 24
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: dataURL,
                                        transformation: {
                                            width: 700,
                                            height: 400,
                                        },
                                    })
                                ]
                            })
                        ]
                    }

                ]
            })
            const mimeType =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            // const fileName = "test.docx";
            const fileName = store.state.firstPage.company_name + ".docx";
            Packer.toBlob(doc).then((blob) => {
                const docblob = blob.slice(0, blob.size, mimeType);
                saveAs(docblob, fileName);
            });
        }
        function totalTableString(nameArr, unitsArr, obj, middle) {
            let result = []
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: nameArr,
                            alignment: AlignmentType.CENTER
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: unitsArr,
                            alignment: AlignmentType.CENTER
                        })
                    ]
                })
            )
            for (let i = 0; i < store.state.firstPage.year.length; i++) {
                let year = store.state.firstPage.year[i]
                result.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: (Number(obj[year])).toFixed(3)
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })
                        ]
                    }),
                )
            }
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: middle
                                })
                            ],
                            alignment: AlignmentType.CENTER
                        })
                    ]
                })
            )

            return result
        }
        function createBigTableThree() {
            let result = makeBigTableHeader()
            result.push(makeTableMainStringThree())
            result.push(makeTableString("1.1", "Весовая доля C2F6/CF4", [
                new Math({
                    children: [
                        new MathSubScript({
                            children: [new MathRun("F")],
                            subScript: [new MathRun("C2F6/CF4")],
                        }),
                    ]
                }),
            ],
                "кг C2F6 / кг CF4", "значения таблицы 16.1 / инструментальные измерения предприятия", performData.value.weightFractionC2F6, performData.value.weightFractionC2F6Middle, true))

            return result
        }
        function createBigTableTwo() {
            let result = makeBigTableHeader()
            result.push(makeTableMainStringTwo())
            result.push(makeTableString("1.1", "Угловой коэффициент для CF4", [
                new Math({
                    children: [
                        new MathSubScript({
                            children: [new MathRun("S")],
                            subScript: [new MathRun("CF4")],
                        }),
                    ]
                }),
            ],
                "т CF4 / т Ал.", "расчетная величина", performData.value.SlopeCF4, performData.value.SlopeCF4Middle, true))
            result.push(makeTableString("1.2", "Минуты анодного эффекта на ванно-сутки", [
                new TextRun({
                    text: "AEM",
                })
            ],
                "минуты анодного эффекта / ванно-сутки", "расчетная величина", performData.value.MinutesAnodeEffectCF4, performData.value.MinutesAnodeEffectCF4Middle, true))
            result.push(makeTableString("1.2", "Средняя частота анодных эффектов за период", [
                new TextRun({
                    text: "AEF",
                })
            ],
                "шт. / ванно-сутки", "данные предприятия", performData.value.AverageFrequencyAnodeEffectsCF4, performData.value.AverageFrequencyAnodeEffectsCF4Middle))
            result.push(makeTableString("1.2", "Средняя продолжительность анодных эффектов за период", [
                new TextRun({
                    text: "AED",
                })
            ],
                "минут / шт.", "данные предприятия", performData.value.AverageDurationAnodeEffectsCF4, performData.value.AverageDurationAnodeEffectsCF4Middle))

            return result
        }
        function createBigTable() {
            let result = makeBigTableHeader()
            result.push(makeTableMainString())
            if (store.state.colorkMetProcess.type == 1) {
                result.push(makeTableString("1.1", "расход анодной массы", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("P")],
                                subScript: [new MathRun("ам")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал.", "фактические данные предприятия", dioxideData.value.anodePasteConsumption, dioxideData.value.anodePasteConsumptionMiddle))
                result.push(makeTableString("1.2", "потери анодной массы с водородом", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("H")],
                                subScript: [new MathRun("ам")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.LossOfAnodeMassHydrogen, dioxideData.value.LossOfAnodeMassHydrogenMiddle, true))
                result.push(makeTableString("1.2", "содержание водорода в анодной массе", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("H")],
                                subScript: [new MathRun("n")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия или 1,4 %", dioxideData.value.HydrogenPathInAnodeMass, dioxideData.value.HydrogenPathInAnodeMassMiddle))
                result.push(makeTableString("1.3", "потери анодной массы с серой и золой", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("sz")],
                                subScript: [new MathRun("ам")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал.", "расчетная величина", dioxideData.value.LossOfAnodeMassSulfurAndAsh, dioxideData.value.LossOfAnodeMassSulfurAndAshMiddle, true))
                result.push(makeTableString("1.3", "содержание серы в анодной массе", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("S")],
                                subScript: [new MathRun("ам")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.sulfurPathInAnodeMass, dioxideData.value.sulfurPathInAnodeMassMiddle))
                result.push(makeTableString("1.3", "содержание золы в анодной массе", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("Z")],
                                subScript: [new MathRun("ам")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.ashPathInAnodeMass, dioxideData.value.ashPathInAnodeMassMiddle))
                result.push(makeTableString("1.4", "потери углерода со смолистыми веществами (при наличии мокрой ступени газоочистки)", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал.", "расчетная величина", dioxideData.value.carbonLossResinousSubstancesWet, dioxideData.value.carbonLossResinousSubstancesWetMiddle, true))
                result.push(makeTableString("1.4", "удельное поступление смолистых веществ через фонарь", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("E")],
                                superScript: [new MathRun("ф")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.intakeResinousSubstancesLanternWet, dioxideData.value.intakeResinousSubstancesLanternWetMiddle))
                result.push(makeTableString("1.4", "содержание углерода в смолистых веществах", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("W")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия (лабораторный анализ) или 95 %", dioxideData.value.resinousCarbonPathWet, dioxideData.value.resinousCarbonPathWetMiddle))
                result.push(makeTableString("1.4", "удельное поступление смолистых веществ в ГОУ", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("г")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.resinousSubstancesInGOUWet, dioxideData.value.resinousSubstancesInGOUWetMiddle))
                result.push(makeTableString("1.5", "потери углерода со смолистыми веществами (при наличии сухой газоочистки)", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал.", "расчетная величина", dioxideData.value.carbonLossResinousSubstancesDry, dioxideData.value.carbonLossResinousSubstancesDryMiddle, true))
                result.push(makeTableString("1.5", "удельное поступление смолистых веществ через фонарь", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("ф")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "расчетная величина", dioxideData.value.intakeResinousSubstancesLanternDry, dioxideData.value.intakeResinousSubstancesLanternDryMiddle))
                result.push(makeTableString("1.5", "эффективность укрытия электролизера", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("η")],
                                subScript: [new MathRun("k")],
                            }),
                        ]
                    }),
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.potСoverEfficiencyDry, dioxideData.value.potСoverEfficiencyDryMiddle))
                result.push(makeTableString("1.5", "количество смолистых веществ, выделяющихся в атмосферу при перестановке штырей для электролизеров с верхним токоподводом", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("пш")],
                                subScript: [new MathRun("см")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "расчетная величина", dioxideData.value.amountSubstancesToPinDry, dioxideData.value.amountSubstancesToPinDryMiddle))
                result.push(makeTableString("1.5", "переводной коэффициент", [
                    new TextRun({
                        text: "-",
                    })
                ],
                    "-", "-", dioxideData.value.conversionFactorDry, dioxideData.value.conversionFactorDryMiddle))
                result.push(makeTableString("1.5", "диаметр лунки (принят равным среднему диаметру участка штыря, запеченного в теле анода)", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("d")],
                                subScript: [new MathRun("n")],
                            }),
                        ]
                    }),
                ],
                    "дм", "данные предприятия", dioxideData.value.HoleDiameterDry, dioxideData.value.HoleDiameterDryMiddle))
                result.push(makeTableString("1.5", "средняя по электролизу высота лунки (высота штыря в запеченной части анода", [
                    new TextRun({
                        text: "h",
                    })
                ],
                    "дм", "данные предприятия", dioxideData.value.averageWellHeightDry, dioxideData.value.averageWellHeightDryMiddle))
                result.push(makeTableString("1.5", "степень заполнения лунки", [
                    new TextRun({
                        text: "q",
                    })
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.WellFillingDry, dioxideData.value.WellFillingDryMiddle))
                result.push(makeTableString("1.5", "содержание пека в анодной массе, загружаемой перед перестановкой штырей", [
                    new TextRun({
                        text: "c",
                    })
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.PitchContentDry, dioxideData.value.PitchContentDryMiddle))
                result.push(makeTableString("1.5", "плотность жидкой анодной массы", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("P")],
                                subScript: [new MathRun("жам")],
                            }),
                        ]
                    }),
                ],
                    "кг / дм3.", "данные предприятия", dioxideData.value.DensityLiquidAnodePasteDry, dioxideData.value.DensityLiquidAnodePasteDryMiddle))
                result.push(makeTableString("1.5", "выход кокса при быстром коксовании пека", [
                    new TextRun({
                        text: "k",
                    })
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.OutpuCokeDry, dioxideData.value.OutpuCokeDryMiddle))
                result.push(makeTableString("1.5", "количество переставляемых штырей в расчете на 1 т Ал.", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("n")],
                                subScript: [new MathRun("ш")],
                            }),
                        ]
                    }),
                ],
                    "шт.", "данные предприятия", dioxideData.value.NumberOfPinsDry, dioxideData.value.NumberOfPinsDryMiddle))
                result.push(makeTableString("1.6", "потери углерода с пылью (при наличии мокрой ступени газоочистки)", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.carbonLossDustWet, dioxideData.value.carbonLossDustWetMiddle, true))
                result.push(makeTableString("1.6", "удельный выброс пыли через фонарь", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("E")],
                                superScript: [new MathRun("ф")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.intakeDustLanternWet, dioxideData.value.intakeDustLanternWetMiddle))
                result.push(makeTableString("1.6", "содержание углерода в пыли", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("W")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.carbonPathInDustWet, dioxideData.value.carbonPathInDustWetMiddle))
                result.push(makeTableString("1.6", "удельное поступление пыли в ГОУ", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("г")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.udelDustInGOUWet, dioxideData.value.udelDustInGOUWetMiddle))
                result.push(makeTableString("1.7", "потери углерода с пылью (при наличии сухой газоочистки)", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.carbonLossDustDry, dioxideData.value.carbonLossDustDryMiddle, true))
                result.push(makeTableString("1.7", "удельное поступление пыли через фонарь", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("ф")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.intakeDustLanternDry, dioxideData.value.intakeDustLanternDryMiddle))
                result.push(makeTableString("1.7", "эффективность укрытия электролизера", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("η")],
                                subScript: [new MathRun("k")],
                            }),
                        ]
                    }),
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.PotCoverEfficiency, dioxideData.value.PotCoverEfficiencyMiddle))
                result.push(makeTableString("1.8", "потери углерода с угольной пеной", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.carbonLossWithFoam, dioxideData.value.carbonLossWithFoamMiddle, true))
                result.push(makeTableString("1.8", "выход угольной пены", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("вых")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.outputCoalFoam, dioxideData.value.outputCoalFoamMiddle))
                result.push(makeTableString("1.8", "содержание углерода в пене", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("W")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.foamCarbonPath, dioxideData.value.foamCarbonPathMiddle))
                result.push(makeTableString("1.9", "стехиометрический коэффициент пересчета углерода в CO2", [
                    new TextRun({
                        text: "-",
                    })
                ],
                    "-", "-", dioxideData.value.stehKoeffMain, dioxideData.value.stehKoeffMainMiddle, true))
                result.push(makeTableString("1.10", "удельный выброс диоксида углерода при мокрой очистке отходящих газов содовым раствором", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("E")],
                                superScript: [new MathRun("MO")],
                                subScript: [new MathRun("CO2")],
                            }),
                        ]
                    }),
                ],
                    "т CO2 / т Ал.", "расчетная величина", dioxideData.value.udelCarbonEmissionGasWithSoda, dioxideData.value.udelCarbonEmissionGasWithSodaMiddle, true))
                result.push(makeTableString("1.10", "удельное поступление диоксида серы в ГОУ", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("P")],
                                subScript: [new MathRun("SO2")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.udelDioxideSulfurInGOU, dioxideData.value.udelDioxideSulfurInGOUMiddle))
                result.push(makeTableString("1.10", "эффективность улавливания диоксида серы в ГОУ", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("η")],
                                subScript: [new MathRun("k")],
                            }),
                        ]
                    }),
                ],
                    "доли ед.", "данные предприятия", dioxideData.value.captureEffDioxideSulfurInGOU, dioxideData.value.captureEffDioxideSulfurInGOUMiddle))
                result.push(makeTableString("1.10", "стехиометрический коэффициент пересчета углерода в CO2", [
                    new TextRun({
                        text: "-",
                    })
                ],
                    "-", "-", dioxideData.value.stehKoeff, dioxideData.value.stehKoeffMiddle))
            } else {
                result.push(makeTableString("1.1", "расход обожженых анодов", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("P")],
                                subScript: [new MathRun("a")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "фактические данные предприятия", dioxideData.value.consumptioBakedAnodes, dioxideData.value.consumptioBakedAnodesMiddle, true))
                result.push(makeTableString("1.2", "содержание серы в обожженом аноде", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("S")],
                                subScript: [new MathRun("a")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.sulfurPathBakedAnode, dioxideData.value.sulfurPathBakedAnodeMiddle, true))
                result.push(makeTableString("1.3", "содержание золы в обожженом аноде", [
                    new Math({
                        children: [
                            new MathSubScript({
                                children: [new MathRun("Z")],
                                subScript: [new MathRun("a")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.ashPathBakedAnode, dioxideData.value.ashPathBakedAnodeMiddle, true))
                result.push(makeTableString("1.4", "потери углерода с пылью", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.dustCarbonLossAnode, dioxideData.value.dustCarbonLossAnodeMiddle, true))
                result.push(makeTableString("1.4", "удельное поступление пыли через фонарь", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("ф")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.intakeDustLanternAnode, dioxideData.value.intakeDustLanternAnodeMiddle))
                result.push(makeTableString("1.4", "содержание углерода в пыли", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("W")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пыль")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.carbonPathInDustAnode, dioxideData.value.carbonPathInDustAnodeMiddle))
                result.push(makeTableString("1.5", "потери углерода с угольной пеной", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("M")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "т / т Ал", "расчетная величина", dioxideData.value.carbonLossWithFoam, dioxideData.value.carbonLossWithFoamMiddle, true))
                result.push(makeTableString("1.5", "выход угольной пены", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("P")],
                                superScript: [new MathRun("вых")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "кг / т Ал", "данные предприятия", dioxideData.value.outputCoalFoam, dioxideData.value.outputCoalFoamMiddle))
                result.push(makeTableString("1.5", "содержание углерода в пене", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("W")],
                                superScript: [new MathRun("c")],
                                subScript: [new MathRun("пена")],
                            }),
                        ]
                    }),
                ],
                    "%", "данные предприятия", dioxideData.value.foamCarbonPath, dioxideData.value.foamCarbonPathMiddle))
                result.push(makeTableString("1.6", "стехиометрический коэффициент пересчета углерода в CO2", [
                    new TextRun({
                        text: "-",
                    })
                ],
                    "-", "-", dioxideData.value.stehKoeffMain, dioxideData.value.stehKoeffMainMiddle, true))
            }

            return result
        }

        function forString(npp, name, desArr, units, source, obj, middle, bold = false, backgroundColor = "ffffff") {
            let result = []
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: npp,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: name,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: desArr,
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: units,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: source,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),
            )
            for (let i = 0; i < store.state.firstPage.year.length; i++) {
                let year = store.state.firstPage.year[i]
                if (npp == "1") {
                    result.push(
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: (Number(obj[year])).toFixed(3),
                                            bold: bold
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            shading: {
                                fill: backgroundColor,
                                color: "auto",
                            },
                        }),
                    )
                } else {
                    result.push(
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: (Number(obj[year])).toFixed(3),
                                            bold: bold
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                        }),
                    )
                }

            }
            if (npp == "1") {
                result.push(new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: middle,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ],
                    shading: {
                        fill: backgroundColor,
                        color: "auto",
                    },
                }),)
            } else {
                result.push(new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: middle,
                                    bold: bold
                                })
                            ],
                            style: "BigTablesCellStyle"
                        })
                    ]
                }),)
            }

            return result
        }
        function makeTableMainString(npp, name, des, units, source, obj, middle) {
            return new TableRow({
                children: forStringMain(npp, name, des, units, source, obj, middle, "e2efda"),
            })
        }
        function makeTableMainStringTwo(npp, name, des, units, source, obj, middle) {
            return new TableRow({
                children: forStringMainTwo(npp, name, des, units, source, obj, middle)
            })
        }
        function makeTableMainStringThree(npp, name, des, units, source, obj, middle) {
            return new TableRow({
                children: forStringMainThree(npp, name, des, units, source, obj, middle)
            })
        }

        function makeTableString(npp, name, des, units, source, obj, middle, bold) {
            return new TableRow({
                children: forString(npp, name, des, units, source, obj, middle, bold)
            })
        }
        function forStringMainThree() {
            let span = 2
            let result = forString("1", "Удельный выброс C2F6 от электролизеров Содерберга", [
                new Math({
                    children: [
                        new MathSubSuperScript({
                            children: [new MathRun("E")],
                            superScript: [new MathRun("содб")],
                            subScript: [new MathRun("C2F6")],
                        }),
                    ]
                }),
            ],
                "т C2F6 / т Ал.", "расчетная величина", performData.value.udelEmissionC2F6, performData.value.udelEmissionC2F6Middle, true, "fce4d6")
            if (store.state.colorkMetProcess.type == 1) {
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом",
                                        bold: true
                                    })
                                ],
                                style: "BigTablesCellStyle"
                            })
                        ],
                        rowSpan: span
                    }),
                )
            } else {
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA)",
                                        bold: true
                                    })
                                ],
                                style: "BigTablesCellStyle"
                            })
                        ],
                        rowSpan: span
                    }),
                )
            }

            return result
        }
        function forStringMainTwo() {
            let span = 5
            let result = forString("1", "Удельный выброс CF4 от электролизеров Содерберга", [
                new Math({
                    children: [
                        new MathSubSuperScript({
                            children: [new MathRun("E")],
                            superScript: [new MathRun("содб")],
                            subScript: [new MathRun("CF4")],
                        }),
                    ]
                }),
            ],
                "т CF4 / т Ал.", "расчетная величина", performData.value.udelEmissionCF4, performData.value.udelEmissionCF4Middle, true, "fff2cc")
            if (store.state.colorkMetProcess.type == 1) {
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом",
                                        bold: true
                                    })
                                ],
                                style: "BigTablesCellStyle"
                            })
                        ],
                        rowSpan: span
                    }),
                )
            } else {
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA)",
                                        bold: true
                                    })
                                ],
                                style: "BigTablesCellStyle"
                            })
                        ],
                        rowSpan: span
                    }),
                )
            }

            return result
        }
        function forStringMain() {
            let span = 0
            let result
            if (store.state.colorkMetProcess.type == 1) {
                result = forString("1", "Удельный выброс диоксида углерода от электролизеров Содерберга", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("E")],
                                superScript: [new MathRun("содб")],
                                subScript: [new MathRun("CO2")],
                            }),
                        ]
                    }),
                ], "т CO2 / т Ал.", "расчетная величина", dioxideData.value.udelDioxideEmission, dioxideData.value.udelDioxideEmissionMiddle, true, "e2efda")
                span = 38
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом",
                                        bold: true
                                    })
                                ],
                                style: "BigTablesCellStyle"
                            })
                        ],
                        rowSpan: span
                    }),
                )
            } else {
                result = forString("1", "Удельный выброс CO2 от электролизеров с обожженными анодами", [
                    new Math({
                        children: [
                            new MathSubSuperScript({
                                children: [new MathRun("E")],
                                superScript: [new MathRun("оа")],
                                subScript: [new MathRun("CO2")],
                            }),
                        ]
                    }),
                ],
                    "т CO2 / т Ал.", "расчетная величина", dioxideData.value.udelDioxideEmissionOa, dioxideData.value.udelDioxideEmissionOaMiddle, true, "e2efda")
                span = 11
                result.unshift(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Производство первичного алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA)",
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        rowSpan: span
                    }),
                )
            }
            return result
        }

        function makeBigTableHeader() {
            let result = []
            result.push(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Производственный процесс",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 3,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Показатель",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            columnSpan: store.state.firstPage.year.length + 6,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "№ п/п",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 2,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Наименование",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 2,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Обозначения",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 2,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Единицы измерения",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 2,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Источник данных",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            rowSpan: 2,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "Величина",
                                            bold: true
                                        })
                                    ],
                                    style: "BigTablesCellStyle"
                                })
                            ],
                            columnSpan: store.state.firstPage.year.length + 1,
                            shading: {
                                fill: "d0cece",
                                color: "auto",
                            },
                        }),
                    ]
                }),
                new TableRow({
                    children: makeYearsForTable("BigTablesCellStyle")
                })
            )
            return result
        }

        function makeYearsWeightForTable() {
            let result = []
            let middle = 0
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Выпуск алюминия (электролитического)",
                                })
                            ],
                            style: "YearsCellStyle"
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new Math({
                                    children: [
                                        new MathSubScript({
                                            children: [new MathRun("M")],
                                            subScript: [new MathRun("Al")],
                                        }),
                                    ]
                                }),
                            ],
                            style: "YearsCellStyle",
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "т / год"
                                })
                            ],
                            style: "YearsCellStyle"
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            )
            for (let i = 0; i < store.state.firstPage.year.length; i++) {
                let year = store.state.firstPage.year[i]
                result.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: weightByYear.value[year]
                                    })
                                ],
                                style: "YearsCellStyle"
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                )
                middle += Number(weightByYear.value[year])
            }
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: (Number(middle / store.state.firstPage.year.length).toFixed(2))
                                })
                            ],
                            style: "YearsCellStyle"
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            )
            return result
        }

        function makeFirstTitle() {
            if (store.state.colorkMetProcess.type == 1) {
                return new TextRun({
                    text: "Производство первичного алюминия на электролизерах Содерберга с боковым токоподводом и верхним токоподводом, на электролизерах ЭкоСодерберга с верхним токоподводом",
                    bold: true,
                    size: 24,
                    marginTop: 24
                })
            } else {
                return new TextRun({
                    text: "Производство первичного алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA и >300 kA)",
                    bold: true,
                    size: 24,
                    marginTop: 24
                })
            }

        }
        function makeYearsForTableTwo() {
            let result = []
            result.push(
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Выбросы",
                                    bold: true,
                                    size: 20
                                })
                            ],
                            style: "tabCellStyle"
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Ед. измерения",
                                    bold: true,
                                    size: 20
                                })
                            ],
                            style: "tabCellStyle"
                        })
                    ]
                }),
            )
            for (let i = 0; i < store.state.firstPage.year.length; i++) {

                result.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: String(store.state.firstPage.year[i]) + " год",
                                        bold: true,
                                        size: 20
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                )
            }
            result.push(new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: "Среднее значение за период",
                            bold: true,
                            size: 20
                        })
                    ],
                    style: "tabCellStyle"
                })],
                verticalAlign: VerticalAlign.CENTER,
            }),)
            return result
        }
        function makeYearsForTable(styleType = "YearsCellStyle") {
            let result = []
            for (let i = 0; i < store.state.firstPage.year.length; i++) {

                result.push(
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: String(store.state.firstPage.year[i]) + " год",
                                        bold: true,
                                    })
                                ],
                                style: styleType
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        shading: {
                            fill: "d0cece",
                            color: "auto",
                        },
                    }),
                )
            }
            result.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Среднее значение за период",
                                bold: true,
                            })
                        ],
                        style: styleType
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
                shading: {
                    fill: "d0cece",
                    color: "auto",
                },
            }),)
            return result
        }

        return {
            visible, getWeights,
            tabs: ['Расчет выбросов диоксида углерода', 'Расчет выбросов перфторуглеродов'], currentTabComponent, currentTab, weightByYear, componentKey,
            showComponent, toggleTabs, showResult, getAllFromChilds, getAllPerformData, getAllDioxideData, performData, dioxideData, allKey,
            getAllEquivalentData, createDocument,
            chartData, width1, height1, BarMainChart, chartOptions, showGraf
        }
    }
}

</script>
<style scoped>
.tab-button {
    transition: .5s;
    opacity: .7;
}

.tab-button:hover {
    transform: scale(1.05);
    opacity: .8;
    transition: .5s;
}

.tab-button.active {
    /* transform: scale(1.3); */
    opacity: 1;
    /* width: 25%; */
    transition: 1s;
}

.modal-xl {
    max-width: 90% !important;
}

.result_button {
    position: fixed;
    top: 60px;
    right: 20px;
    z-index: 1021;
}

.big-chart {
    opacity: 0;
    position: absolute;
    top: -500px;
}
</style>