<template>
    <div class="fon">
        <div class="bd-main">
            <div class="info_servies" v-if="!showForm">
                <span>Информационный сервис по расчету<br>выбросов парниковых газов</span>
                <button class="btn my-btn" @click="(showForm = !showForm)">Войти</button>
            </div>
            <div class="row justify-content-center mt-5" v-else>
                <div class="col-md-6">
                    <div class="my-form">
                        <h3 class="mb-4">Добро пожаловать!</h3>
                        <Form @submit="handleLogin" :validation-schema="schema">
                            <div class="form-group mb-3">
                                <!-- <label for="username">Username</label> -->
                                <div class="">
                                    <Field name="email" type="email" class="form-el form-comtrol" placeholder="Email" />
                                    <div class="e-feed">
                                        <ErrorMessage name="email" class="error-feedback mb-3 ms-5" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3 mt-3">
                                <!-- <label for="password">Password</label> -->
                                <div class="">
                                    <Field name="password" type="password" class="form-el" placeholder="Пароль" />
                                    <div class="e-feed">
                                        <ErrorMessage name="password" class="error-feedback mb-3 ms-5" />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">
                                <button class="btn my-btn" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Войти</span>
                                </button>
                                <div style="text-align: right" class="me-5">
                                    <router-link to="/register">
                                        <p class="perehod">регистрация</p>
                                    </router-link>
                                </div>
                                <!-- <div style="text-align: right" class="me-5">
                                    <a href="" @click.prevent="showStrange">
                                        <p class="perehod">восстановление пароля</p>
                                    </a>
                                </div> -->
                                <div style="text-align: right" class="me-5">
                                    <router-link to="/password-reset">
                                        <p class="perehod">забыли пароль</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                </div>
                            </div>
                        </Form>
                        <!-- <div class="strange-text" v-if="showStrangeText">
                            <p>Для восстановления пароля, напишите нам на email: <br> <a href = "mailto: pass-restore@eipc.center">pass-restore@eipc.center</a></p>
                        </div> -->
                        <!-- <router-link to="/register" style="float: right;">Регистрация</router-link> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-bottom bottommm">
            <img src="/img/logo/acyr_logo.png">
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { ref, onMounted, computed } from "vue"
export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    setup() {
        // const showStrangeText = ref(false)
        const showForm = ref(false)
        const loading = ref(false)
        const message = ref("")
        const store = useStore()
        const router = useRouter()
        const schema = yup.object().shape({
            email: yup
                .string()
                .required("Email обязательно!")
                .email("Email некорректен!")
                .max(50, "Максимум 50 символов!"),
            password: yup
                .string()
                .required("Пароль обязателен!")
                .min(6, "Минимум 6 символов!")
                .max(40, "Максимум 40 символов!"),
        });

        const loggedIn = computed(() => {
            return store.state.auth.status.loggedIn;
        })
        onMounted(() => {
            if (loggedIn.value) {
                router.push("/");
            }
        })

        function handleLogin(user) {
            loading.value = true;

            store.dispatch("auth/login", user).then(
                () => {
                    router.push("/");
                },
                (error) => {
                    loading.value = false;
                    message.value =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
        // function showStrange(){
        //     showStrangeText.value = !showStrangeText.value
        // }

        return {
            schema, message, loading, handleLogin, showForm
        }
    }
}
</script>

<style scoped>
.fon {
    background: linear-gradient(to right, #295575, #4CA6C6, #75DFB2) !important;
    min-height: 100vh;
    margin-top: -20px;
}

.bd-main {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
}

label {
    text-align: left;
}

.error-feedback {
    color: red;
    font-size: 12px;
    /* float: left; */
    margin-left: 0.5rem;
}

.bottommm {
    text-align: left;
}

.my-form h3 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    align-items: center;
}

.form-el {
    width: 500px;
    background: transparent;
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 7px;
    /* margin-bottom: 10px; */
    color: #fff;
    outline: none;
}

.my-btn {
    background: #fff;
    border: 0;
    padding: 8px;
    border-radius: 10px;
    color: #4ea5bb;
    font-size: 24px;
    margin: 10px 0 20px 0;
    border: 2px solid #fff;
    width: 180px;
}

.my-btn:hover {
    cursor: pointer;
    background: transparent;
    color: #fff;
}

.info_servies {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5rem;
}

.info_servies span {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
}

.perehod {
    color: white;
    text-decoration: underline;
}

.perehod:hover {
    text-decoration: none;
    color: rgb(245, 227, 66);
}

::placeholder {
    color: white;
}
.e-feed {
    text-align: left;
}
.strange-text{
    color: white;
}
.strange-text p a{
    text-decoration: none;
    color: whitesmoke;
}

@media screen and (max-width : 700px) {
   .bd-main{
    width: 96%;
   }
   .form-el{
    width: 100%;
   }
   .info_servies span{
    font-size: 22px;
   }
}
</style>