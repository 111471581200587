<template>
    <div class="border rounded p-2 my-3 css-carbonate">
        <div class="row mb-3">
            <div class="col-lg-12 mb-3">
                <label class="mx-2 pt-2" :for="'carbonate_' + prodTypeNum + '_' + num">
                    <p class="h6">Выберите карбонат, содержащийся в минеральном сырье</p>
                </label>
                <select class="form-select form-select-lg" aria-label="Default select example"
                    :id="'carbonate_' + prodTypeNum + '_' + num" :name="'carbonate_' + prodTypeNum + '_' + num"
                    v-model="carbonate" @change="showHandleCarbonate">
                    <option :value="{ EF: 0.44, F: 1, GWP: 1, cName: 'Известняк (мел) CaCO3' }">
                        карбонат СаСО3
                    </option>
                    <option :value="{ EF: 0.522, F: 1, GWP: 1, cName: 'Магнезит MgCO3' }">карбонат MgCO3</option>
                    <option :value="{ EF: 0.477, F: 1, GWP: 1, cName: 'Доломит CaMg(CO3)2' }">
                        карбонат CaMg(CO3)2
                    </option>
                    <option :value="{ EF: 0.38, F: 1, GWP: 1, cName: 'Сидерит FeCO3' }">карбонат FeCO3</option>
                    <option value="other">
                        Прочие карбонаты
                    </option>
                </select>
                <small class="invalid" v-if="carbonateError">{{ carbonateError }}</small>
            </div>
            <div class="row" v-if="handleCarbonate">
                <div class="col-lg-5 form-floating">
                    <input type="text" class="form-control" :name="'carbName_' + prodTypeNum + '_' + num"
                        :id="'carbName_' + prodTypeNum + '_' + num" v-model="carbName"
                        placeholder="Название минерального сырья" @input="setCarbonateName">
                    <label class="mx-2 pt-2" :for="'carbName_' + prodTypeNum + '_' + num">Название карбоната</label>
                    <small class="invalid" v-if="carbNameError">{{ carbNameError }}</small>
                </div>
                <div class="col-lg-3 form-floating">
                    <input type="text" class="form-control" :name="'carbFormula_' + prodTypeNum + '_' + num"
                        :id="'carbFormula_' + prodTypeNum + '_' + num" v-model="carbFormula"
                        placeholder="Химическая формула карбоната" @input="setCarbonateName">
                    <label class="mx-2 pt-2" :for="'carbFormula_' + prodTypeNum + '_' + num">Химическая формула карбоната</label>
                    <small class="invalid" v-if="carbFormulaError">{{ carbFormulaError }}</small>
                </div>
                <div class="col-lg-4 form-floating">
                    <input type="number" class="form-control" :name="'carbKoeff_' + prodTypeNum + '_' + num"
                        :id="'carbKoeff_' + prodTypeNum + '_' + num" v-model="carbKoeff"
                        placeholder="Коэффициент выбросов для карбоната" @input="setKoeff">
                    <label class="mx-2 pt-2" :for="'carbKoeff_' + prodTypeNum + '_' + num">Коэффициент выбросов для карбоната т СО2/т</label>
                    <small class="invalid" v-if="carbKoeffError">{{ carbKoeffError }}</small>
                </div>
            </div>
            <div class="col-lg-6">
                <label class="mx-2 pt-2" :for="'mineralMaterial_' + prodTypeNum + '_' + num">
                    <p class="h6">Выберите наименование минерального сырья</p>
                </label>
                <select class="form-select form-select-lg" aria-label="Default select example"
                    :id="'mineralMaterial_' + prodTypeNum + '_' + num" :name="'mineralMaterial_' + prodTypeNum + '_' + num"
                    v-model="mineralMaterial" @change="changeMineralMaterial($event), showOther($event)">
                    <option value="Глина">Глина</option>
                    <option value="Мел">Мел</option>
                    <option value="other">Прочее минеральное сырье</option>
                </select>
                <small class="invalid" v-if="mineralMaterialError">{{ mineralMaterialError }}</small>
            </div>
            <div v-if="showMineralMaterialName" class="col-lg-6 form-floating mt-4">
                <input type="text" class="form-control" :name="'mineralMaterialName_' + prodTypeNum + '_' + num"
                    :id="'mineralMaterialName_' + prodTypeNum + '_' + num" v-model="mineralMaterialName"
                    placeholder="Название минерального сырья" @input="changeMineralMaterial">
                <label class="mx-2 pt-2" :for="'mineralMaterialName_' + prodTypeNum + '_' + num">Название минерального
                    сырья</label>
                <small class="invalid" v-if="mineralMaterialNameError">{{ mineralMaterialNameError }}</small>
            </div>
        </div>
        <div class="row">
            <div class="form-floating col-lg-4 mb-3">
                <input type="number" class="form-control" :name="'mRawMaterial_' + prodTypeNum + '_' + num"
                    :id="'mRawMaterial_' + prodTypeNum + '_' + num" v-model="mRawMaterial" placeholder="">
                <label class="mx-2 pt-2" :for="'mRawMaterial_' + prodTypeNum + '_' + num">Расход минерального сырья, т</label>
                <small class="invalid" v-if="mRawMaterialError">{{ mRawMaterialError }}</small>
            </div>
            <div class="form-floating col-lg-4 mb-3">
                <input type="number" class="form-control" :name="'carbonateContent_' + prodTypeNum + '_' + num"
                    :id="'carbonateContent_' + prodTypeNum + '_' + num" v-model="carbonateContent" placeholder="">
                <label class="mx-2 pt-2" :for="'carbonateContent_' + prodTypeNum + '_' + num">содержание карбоната в
                    минеральном сырье, доля</label>
                <small class="invalid" v-if="carbonateContentError">{{ carbonateContentError }}</small>
            </div>
            <div class="form-floating col-lg-4 mb-3">
                <input type="number" class="form-control" :name="'degreeOfCalcination_' + prodTypeNum + '_' + num"
                    :id="'degreeOfCalcination_' + prodTypeNum + '_' + num" v-model="degreeOfCalcination" placeholder="">
                <label class="mx-2 pt-2" :for="'degreeOfCalcination_' + prodTypeNum + '_' + num">степень кальцинирования
                    карбоната, доля</label>
                <small class="invalid" v-if="degreeOfCalcinationError">{{ degreeOfCalcinationError }}</small>
            </div>
            <div class="form-floating col-lg-7 mb-3">
                <input disabled type="number" class="form-control" :name="'carbEmission_' + prodTypeNum + '_' + num"
                    :id="'carbEmission_' + prodTypeNum + '_' + num" v-model="carbEmission" placeholder="">
                <label class="mx-2 pt-2" :for="'carbEmission_' + prodTypeNum + '_' + num">Выбросы СО2 от кальцинации
                    карбонатов &#171;{{ data.carbonateName
                    }}&#187; при производства
                    керамических изделий за период</label>
            </div>
        </div>
    </div>
</template>
  
<script>
import { reactive, watch, toRefs, ref } from "vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
export default {
    name: "TheBrickCarbonate",
    props: ["num", "prodTypeNum"],
    emits: ["getBrickCarbData"],
    setup(props, context) {
        const { handleSubmit } = useForm()
        const { num, prodTypeNum } = toRefs(props)
        const {
            value: carbonate,
            errorMessage: carbonateError,
            handleChange: carbonateBlur,
        } = useField("carbonate",
            yup.mixed()
                .required("Выберите карбонат"), {
            initialValue: "",
        });
        const handleCarbonate = ref(false)
        const {
            value: carbName,
            errorMessage: carbNameError
        } = useField("carbName", value => {
            if (handleCarbonate.value) {
                if (!value) {
                    return 'Поле обязательно';
                }
            }
            return true
        })
        const {
            value: carbFormula,
            errorMessage: carbFormulaError
        } = useField("carbFormula", value => {
            if (handleCarbonate.value) {
                if (!value) {
                    return 'Поле обязательно';
                }
            }
            return true
        })
        const {
            value: carbKoeff,
            errorMessage: carbKoeffError
        } = useField("carbKoeff", value => {
            if (handleCarbonate.value) {
                if (!value) {
                    return 'Поле обязательно';
                }
                if (value > 1) {
                    return 'не может быть больше 1';
                }
                if (value <= 0) {
                    return 'должно быть быть больше 0';
                }
            }
            return true
        },{
                initialValue: 0,
            })


        const mineralMatVal = ref("")
        const {
            value: mineralMaterial,
            errorMessage: mineralMaterialError,
            handleChange: mineralMaterialBlur,
        } = useField("mineralMaterial",
            yup.string()
                .typeError("Выберите минеральное сырье")
                .required("Выберите минеральное сырье")
            ,
            {
                // initialValue: "",
            });
        const showMineralMaterialName = ref(false)
        const {
            value: mineralMaterialName,
            errorMessage: mineralMaterialNameError,
        } = useField("mineralMaterialName", value => {
            if (showMineralMaterialName.value) {
                if (!value) {
                    return 'Поле обязательно';
                }
            }
            return true
        })

        const { value: mRawMaterial, errorMessage: mRawMaterialError } =
            useField(
                "mRawMaterial",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0"),
                { initialValue: 0 }
            );
        const { value: carbonateContent, errorMessage: carbonateContentError } =
            useField(
                "carbonateContent",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0").max(1, "Значение не может быть больше 1"),
                { initialValue: 0 }
            );
        const { value: degreeOfCalcination, errorMessage: degreeOfCalcinationError } =
            useField(
                "degreeOfCalcination",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0").max(1, "Значение не может быть больше 1"),
                { initialValue: 1 }
            );
        const { value: carbEmission, errorMessage: carbEmissionError } = useField(
            "carbEmission",
            yup.number(),
            { initialValue: 0 }
        )
        const calcKoeff = ref(0)

        let data = reactive({
            carbonate: carbonate,
            carbEmission: carbEmission,
            mRawMaterial: mRawMaterial,
            carbonateContent: carbonateContent,
            degreeOfCalcination: degreeOfCalcination,
            mineralMatVal: mineralMatVal,
            showMineralMaterialName: showMineralMaterialName,
            calcKoeff: calcKoeff,
            carbonateName: "",
            num: num
        })
        function setCarbonateName(event){
            data.carbonateName = carbName.value + " " + carbFormula.value
        }
        function setKoeff(event){
            calcKoeff.value = event.target.value
        }
        function showHandleCarbonate(event) {
            carbEmission.value = 0
            if (event.target.value == 'other') {
                handleCarbonate.value = true
            } else {
                handleCarbonate.value = false
                calcKoeff.value = clearDataStr(carbonate.value.EF)
            }
            data.carbonateName = carbonate.value.cName
        }
        function showOther(event) {//показывает поле ручного ввода названия минерал. сырья
            if (mineralMatVal.value == 'other') {
                showMineralMaterialName.value = true
            } else {
                showMineralMaterialName.value = false
            }
        }
        function changeMineralMaterial(event) {
            mineralMatVal.value = event.target.value

        }
        const calc = handleSubmit(values => {
            // console.log(values)
            carbEmission.value = clearDataStr(mRawMaterial.value) * clearDataStr(carbonateContent.value) * clearDataStr(degreeOfCalcination.value) * calcKoeff.value
            context.emit("getBrickCarbData", data);
        })
        watch([data], ([], []) => {
            // carbEmission.value = clearDataStr(mRawMaterial.value) * clearDataStr(carbonateContent.value) * clearDataStr(degreeOfCalcination.value) * clearDataStr(carbonate.value.EF)
            // context.emit("getBrickCarbData", data);
            calc()
        })
        function clearDataStr(data) {
            if (data == '' || data === undefined) {
                return 0
            }
            return data
        }
        return {
            carbonate, carbonateError, carbonateBlur, mineralMaterial, mineralMaterialError, mineralMaterialBlur, mineralMaterialName, mineralMaterialNameError,
            mRawMaterial, mRawMaterialError,
            carbonateContent, carbonateContentError,
            degreeOfCalcination, degreeOfCalcinationError,
            carbEmission, carbEmissionError, prodTypeNum, changeMineralMaterial, showMineralMaterialName, showOther, showHandleCarbonate, handleCarbonate,
            carbName, carbNameError, carbFormula, carbFormulaError, carbKoeff, carbKoeffError, setKoeff, setCarbonateName, data
        };
    },
};
</script>
  
<style scoped>
.green {
    background-color: aquamarine;
}

.rawMaterial {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

.invalid {
    color: red;
    font-size: 12px;
    float: left;
    margin-left: 0.5rem;
}

.form-control:disabled {
    background-color: #e2f8e8 !important;
}

.carb-btn {
    background-color: #4ca6c6;
    color: white;
    border: 2px solid #4ca6c6;
}

.carb-btn:hover {
    background-color: white;
    border: 2px solid #4ca6c6;
    color: #4ca6c6;
}

.css-carbonate {
    background-color: rgb(172, 241, 250);
}
</style>