export const cherMetData = {
	"Производство кокса": {
        image: 'url(/img/black-metallurgy/koks.png)',
		inputDataArr: [
			{
				name: "Сырье",
				subName: "Коксующиеся угли",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 1,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Природный газ",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0.52,
				disabledCO: true,
				volumeK: 0.52 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 1,
				CO2pryamVybr: 1,
			},
			{
				name: "ВЭР",
				subName: "Доменный газ",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: 0.0584684965689332,
				disabledCO: true,
				volumeK: 0.0584684965689332 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 2,
				popravka: 1,
			},
			{
				name: "ВЭР",
				subName: "Коксовый газ (на производство кокса)",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: 0.25166874610106,
				disabledCO: true,
				volumeK: 0.25166874610106 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				popravka: 1,
			},
			{
				name: "Энергия",
				subName: "Электроэнергия",
				units: "МВт*ч",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.504,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 2,
				elVybr: 1,
			},
			{
				name: "Энергия",
				subName: "Теплоэнергия (пар, сетевая вода)",
				units: "Гкал",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.27,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				tepVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Кислород",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.355,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 3,
				techGasVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Азот",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.103,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				techGasVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Аргон",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.103,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				techGasVybr: 1,
			},
		],
		outputDataArr: [
			{
				name: "Продукция",
				subName: "Кокс (валовой, всех фракций)",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 1,
				CO2pryamVybr: 1,
				productWeight: 1,
			},
			{
				name: "Побочная продукция",
				subName: "Каменноугольная смола + Нафтален",
				units: "т",
				volume: {},
				volumeCO: -0.924945414847,
				disabledCO: true,
				volumeK: -0.924945414847 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 2,
				CO2pryamVybr: 1,
			},
			{
				name: "Побочная продукция",
				subName: "Бензол",
				units: "т",
				volume: {},
				volumeCO: -0.923034934497,
				disabledCO: true,
				volumeK: -0.923034934497 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "ВЭР",
				subName: "Коксовый газ (выработано всего)",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: ((-4000 / 7000) * 1.63 * 0.99) / 3.664,
				disabledCO: true,
				volumeK: (-4000 / 7000) * 1.63 * 0.99,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 3,
				popravka: 1,
			},
			{
				name: "ВЭР",
				subName: "Коксовый газ (отпущенный другим цехам и на сторону)",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: ((-4000 / 7000) * 1.63 * 0.99) / 3.664,
				disabledCO: true,
				volumeK: (-4000 / 7000) * 1.63 * 0.99,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: false,
			},
			{
				name: "ВЭР",
				subName: "Коксовый газ (потери)",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: ((4000 / 7000) * 1.63 * 0.99) / 3.664,
				disabledCO: true,
				volumeK: (4000 / 7000) * 1.63 * 0.99,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				popravka: 1,
			},
			{
				name: "Энергия",
				subName: "Электроэнергия",
				units: "МВт*ч",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.504,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 2,
				elVybr: 1,
			},
			{
				name: "Энергия",
				subName: "Теплоэнергия (пар, сетевая вода)",
				units: "Гкал",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.27,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: false,
				tepVybr: 1,
			},
		],
	},
	"Производство агломерата": {
        image: 'url(/img/black-metallurgy/glomerat.png)',
		inputDataArr: [
			{
				name: "Сырье",
				subName: "Концентрат железорудный",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.0005,
				disabledCO: true,
				volumeK: 0.0018,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 8,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName:
					"Другие железосодержащие материалы (окалина, отсевы, колошниковая пыль и др.)",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.01,
				disabledCO: true,
				volumeK: 0.01 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Пыль газоочисток",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.25,
				disabledCO: true,
				volumeK: 0.25 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Известняк",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.12,
				disabledCO: true,
				volumeK: 0.12 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Доломит",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.13,
				disabledCO: true,
				volumeK: 0.13 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Доломитовая известь",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.0065,
				disabledCO: true,
				volumeK: 0.0065 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Известь",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.0065,
				disabledCO: true,
				volumeK: 0.0065 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Сырье",
				subName: "Оливин",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Коксовая мелочь (валовой, всех фракций)",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 5,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Антрацит",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Другие виды углей",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Природный газ",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0.52,
				disabledCO: true,
				volumeK: 0.52 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "Топливо",
				subName: "Другие виды топлива",
				units: "т. у. т.",
				volume: {},
				volumeCO: 0,
				disabledCO: false,
				volumeK: 0,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				CO2pryamVybr: 1,
			},
			{
				name: "ВЭР",
				subName: "Доменный газ",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: 0.0584684965689332,
				disabledCO: true,
				volumeK: 0.0584684965689332 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 2,
				popravka: 1,
			},
			{
				name: "ВЭР",
				subName: "Коксовый газ",
				units: "прив. тыс. м³",
				volume: {},
				volumeCO: 0.25166874610106,
				disabledCO: true,
				volumeK: 0.25166874610106 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				popravka: 1,
			},
			{
				name: "Энергия",
				subName: "Электроэнергия",
				units: "МВт*ч",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.504,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 2,
				elVybr: 1,
			},
			{
				name: "Энергия",
				subName: "Теплоэнергия (пар, сетевая вода)",
				units: "Гкал",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.27,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				tepVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Кислород",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.355,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: 3,
				techGasVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Азот",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.103,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				techGasVybr: 1,
			},
			{
				name: "Технические газы",
				subName: "Аргон",
				units: "тыс. м³",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.103,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: false,
				span: false,
				techGasVybr: 1,
			},
		],
		outputDataArr: [
			{
				name: "Продукция",
				subName: "Агломерат (бункерный)",
				units: "т (сухой вес)",
				volume: {},
				volumeCO: 0.0003,
				disabledCO: true,
				volumeK: -0.0003 * 3.664,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 1,
				CO2pryamVybr: 1,
				productWeight: 1,
			},
			{
				name: "Энергия",
				subName: "Электроэнергия",
				units: "МВт*ч",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.504,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: 2,
				elVybr: 1,
			},
			{
				name: "Энергия",
				subName: "Теплоэнергия (пар, сетевая вода)",
				units: "Гкал",
				volume: {},
				volumeCO: 0,
				disabledCO: true,
				volumeK: 0.27,
				volumeAbs: {},
				volumeMiddle: 0,
				volumeAbsMiddle: 0,
				minus: true,
				span: false,
				tepVybr: 1,
			},
		],
	},
	"Производство железорудных окатышей": {
        image: 'url(/img/black-metallurgy/okatysh.png)',
		inputDataArr: [
            {
                name: "Сырье",
                subName: "Концентрат железорудный",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0005,
                disabledCO: true,
                volumeK: 0.0018,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 6,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие железосодержащие материалы (окалина, отсевы, колошниковая пыль и др.)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Бентонит",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.005,
                disabledCO: true,
                volumeK: 0.005 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известь",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0065,
                disabledCO: true,
                volumeK: 0.0238,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известняк",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.12,
                disabledCO: true,
                volumeK: 0.12 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие Флюсы (ФМ-1, МАХГ, ФМИ, ФОМИ и др.)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Природный газ",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0.52,
                disabledCO: true,
                volumeK: 0.52 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 3,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Мазут",
                units: "т. у. т.",
                volume: {},
                volumeCO: 0.62,
                disabledCO: true,
                volumeK: 0.62 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Другие виды топлива",
                units: "т. у. т.",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "ВЭР",
                subName: "Доменный газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.0584684965689332,
                disabledCO: true,
                volumeK: 0.0584684965689332 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                popravka: 1
            },
            {
                name: "ВЭР",
                subName: "Коксовый газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.25166874610106,
                disabledCO: true,
                volumeK: 0.25166874610106 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                popravka: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                tepVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Кислород",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.355,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 3,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Азот",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Аргон",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
        ],
		outputDataArr: [
            {
                name: "Продукция",
                subName: "Окатыши",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0003,
                disabledCO: true,
                volumeK: -0.0003 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
                productWeight: 1
            },
            {
                name: "Отход/ВМР",
                subName: "Отсев окатышей",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0003,
                disabledCO: true,
                volumeK: -0.0003 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                tepVybr: 1
            },
        ],
	},
	"Производство чугуна в доменных печах": {
        image: 'url(/img/black-metallurgy/chugun.png)',
		inputDataArr: [
            {
                name: "Сырье",
                subName: "Кусковая руда",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 6,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Концентрат железорудный",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0005,
                disabledCO: true,
                volumeK: 0.0018,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Агломерат",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0003,
                disabledCO: true,
                volumeK: 0.0003 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Окатыши",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0003,
                disabledCO: true,
                volumeK: 0.0003 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие железосодержащие материалы (окалина, отсевы, колошниковая пыль и др.)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Лом",
                units: "т",
                volume: {},
                volumeCO: 0.0025,
                disabledCO: true,
                volumeK: 0.0025 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Кокс (валовой, всех фракций)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 5,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Антрацит",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Пылеуглеродное топливо (ПУТ)",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Другие виды углей",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Природный газ",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0.52,
                disabledCO: true,
                volumeK: 0.52 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "ВЭР",
                subName: "Доменный газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.0584684965689332,
                disabledCO: true,
                volumeK: 0.0584684965689332 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                popravka: 1
            },
            {
                name: "ВЭР",
                subName: "Коксовый газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.25166874610106,
                disabledCO: true,
                volumeK: 0.25166874610106 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                popravka: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                tepVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Дутье",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.05,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 4,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Кислород",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.355,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Азот",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Аргон",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
        ],
		outputDataArr: [
            {
                name: "Продукция",
                subName: "Чугун жидкий",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
                productWeight: 1
            },
            {
                name: "ВЭР",
                subName: "Доменный газ (выработано всего)",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: (-1000/7000*1.63*0.92)/3.664,
                disabledCO: true,
                volumeK: -1000/7000*1.63*0.92,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 3,
                popravka: 1,
            },
            {
                name: "ВЭР",
                subName: "Доменный газ (отпущенный другим цехам)",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: (-1000/7000*1.63*0.92)/3.664,
                disabledCO: true,
                volumeK: -1000/7000*1.63*0.92,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
            },
            {
                name: "ВЭР",
                subName: "Доменный газ (потери)",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: (1000/7000*1.63*0.92)/3.664,
                disabledCO: true,
                volumeK: 1000/7000*1.63*0.92,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                popravka: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Газовая пыль (колошниковая, газоочисток)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.25,
                disabledCO: true,
                volumeK: -0.25 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 3,
                CO2pryamVybr: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Шлак",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: -0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                CO2pryamVybr: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Шлам газоочисток",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.25,
                disabledCO: true,
                volumeK: -0.25 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                CO2pryamVybr: 1,
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                tepVybr: 1
            },
        ],
	},
	"Производство железа прямого восстановления": {
        image: 'url(/img/black-metallurgy/zhelezo-pryamogo-vosstanovleniya.png)',
		inputDataArr: [
            {
                name: "Сырье",
                subName: "Кусковая руда для прямого восстановления",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Окатыши для прямого восстановления",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0003,
                disabledCO: true,
                volumeK: 0.0003 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Природный газ",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0.52,
                disabledCO: true,
                volumeK: 0.52 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 1,
                CO2pryamVybr: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                tepVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Кислород",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.355,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 3,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Азот",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Аргон",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
        ],
		outputDataArr: [
            {
                name: "Продукция",
                subName: "Железо прямого восстановления",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
                productWeight: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                tepVybr: 1
            },
        ],
	},
	"Производство стали в конвертерах": {
        image: 'url(/img/black-metallurgy/stali-v-konverterax.png)',
		inputDataArr: [
            {
                name: "Сырье",
                subName: "Чугун жидкий",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 16,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Чугун твердый (чушки)",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Лом",
                units: "т",
                volume: {},
                volumeCO: 0.0025,
                disabledCO: true,
                volumeK: 0.0025 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Железо прямого восстановления",
                units: "т",
                volume: {},
                volumeCO: 0.017,
                disabledCO: true,
                volumeK: 0.017 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Руда",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Окалина",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известь",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0065,
                disabledCO: true,
                volumeK: 0.0238,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известняк",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.12,
                disabledCO: true,
                volumeK: 0.12 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Доломит",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.13,
                disabledCO: true,
                volumeK: 0.13 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Доломитовая известь",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0065,
                disabledCO: true,
                volumeK: 0.0065 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие Флюсы (ФМ-1, МАХГ, ФМИ, ФОМИ и др.)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферросилиций",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферросиликомарганец",
                units: "т",
                volume: {},
                volumeCO: 0.005,
                disabledCO: true,
                volumeK: 0.005 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферромарганец",
                units: "т",
                volume: {},
                volumeCO: 0.05,
                disabledCO: true,
                volumeK: 0.05 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Феррохром",
                units: "т",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие ферросплавы (феррованадий, ферротитан и др.)",
                units: "т",
                volume: {},
                volumeCO: 0.012,
                disabledCO: true,
                volumeK: 0.012 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Уголь и другие углеродосодержащие",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Природный газ",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0.52,
                disabledCO: true,
                volumeK: 0.52 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Электроды",
                subName: "Графитовые электроды",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.999,
                disabledCO: true,
                volumeK: 0.999 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 1,
                CO2pryamVybr: 1
            },
            {
                name: "ВЭР",
                subName: "Доменный газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.0584684965689332,
                disabledCO: true,
                volumeK: 0.0584684965689332 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                popravka: 1
            },
            {
                name: "ВЭР",
                subName: "Коксовый газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.25166874610106,
                disabledCO: true,
                volumeK: 0.25166874610106 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                popravka: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                tepVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Кислород",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.355,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 3,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Аргон",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Азот",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
        ],
		outputDataArr: [
            {
                name: "Продукция",
                subName: "Литая сталь (слябы, слитки, блюм и др. заготовки)",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
                productWeight: 1
            },
            {
                name: "Отход/ВМР",
                subName: "Лом (немерные заготовки, обрезь, угар, брак)",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 3,
                CO2pryamVybr: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Шлак конвертерный",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                CO2pryamVybr: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Пыль и шламы газоочистки конверторной печи",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.047,
                disabledCO: true,
                volumeK: 0.047 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                CO2pryamVybr: 1,
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                tepVybr: 1
            },
        ],
	},
	"Производство стали в электродуговых печах": {
        image: 'url(/img/black-metallurgy/stali-v-elektrodugovyx-pechax.png)',
		inputDataArr: [
            {
                name: "Сырье",
                subName: "Чугун жидкий",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 14,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Чугун твердый (чушки)",
                units: "т",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Лом",
                units: "т",
                volume: {},
                volumeCO: 0.0025,
                disabledCO: true,
                volumeK: 0.0025 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Железо прямого восстановления",
                units: "т",
                volume: {},
                volumeCO: 0.017,
                disabledCO: true,
                volumeK: 0.017 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Руда",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известь",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.0065,
                disabledCO: true,
                volumeK: 0.0238,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Известняк",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.12,
                disabledCO: true,
                volumeK: 0.12 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие Флюсы (ФМ-1, МАХГ, ФМИ, ФОМИ и др.)",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Окалина",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферросилиций",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферросиликомарганец",
                units: "т",
                volume: {},
                volumeCO: 0.005,
                disabledCO: true,
                volumeK: 0.005 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Ферромарганец",
                units: "т",
                volume: {},
                volumeCO: 0.05,
                disabledCO: true,
                volumeK: 0.05 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Феррохром",
                units: "т",
                volume: {},
                volumeCO: 0.01,
                disabledCO: true,
                volumeK: 0.01 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Сырье",
                subName: "Другие ферросплавы (феррованадий, ферротитан и др.)",
                units: "т",
                volume: {},
                volumeCO: 0.012,
                disabledCO: true,
                volumeK: 0.012 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Уголь и другие углеродосодержащие",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0,
                disabledCO: false,
                volumeK: 0,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                CO2pryamVybr: 1
            },
            {
                name: "Топливо",
                subName: "Природный газ",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0.52,
                disabledCO: true,
                volumeK: 0.52 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                CO2pryamVybr: 1
            },
            {
                name: "Электроды",
                subName: "Электроды",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.999,
                disabledCO: true,
                volumeK: 0.999 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 1,
                CO2pryamVybr: 1
            },
            {
                name: "ВЭР",
                subName: "Доменный газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.0584684965689332,
                disabledCO: true,
                volumeK: 0.0584684965689332 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                popravka: 1
            },
            {
                name: "ВЭР",
                subName: "Коксовый газ",
                units: "прив. тыс. м³",
                volume: {},
                volumeCO: 0.25166874610106,
                disabledCO: true,
                volumeK: 0.25166874610106 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                popravka: 1
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                tepVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Кислород",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.355,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: 3,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Азот",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
            {
                name: "Технические газы",
                subName: "Аргон",
                units: "тыс. м³",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.103,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: false,
                span: false,
                techGasVybr: 1
            },
        ],
		outputDataArr: [
            {
                name: "Продукция",
                subName: "Литая сталь (слябы, слитки, блюм и др. заготовки)",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 1,
                CO2pryamVybr: 1,
                productWeight: 1
            },
            {
                name: "Отход/ВМР",
                subName: "Шлак ЭСП производства",
                units: "т (сухой вес)",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                CO2pryamVybr: 1,
            },
            {
                name: "Отход/ВМР",
                subName: "Лом (немерные заготовки, обрезь, угар, брак)",
                units: "т",
                volume: {},
                volumeCO: 0.001,
                disabledCO: true,
                volumeK: 0.001 * 3.664,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                CO2pryamVybr: 1,
            },
            {
                name: "Энергия",
                subName: "Электроэнергия",
                units: "МВт*ч",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.504,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: 2,
                elVybr: 1
            },
            {
                name: "Энергия",
                subName: "Теплоэнергия (пар, сетевая вода)",
                units: "Гкал",
                volume: {},
                volumeCO: 0,
                disabledCO: true,
                volumeK: 0.27,
                volumeAbs: {},
                volumeMiddle: 0,
                volumeAbsMiddle: 0,
                minus: true,
                span: false,
                tepVybr: 1
            },
        ],
	},
}
