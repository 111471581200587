import axios from 'axios';
import authHeader from './auth-header';
import { environment } from './api_url';

class UserService {
  getPublicContent() {
    return axios.get(environment.apihost + 'protected.php', { headers: authHeader() });
  }

//   getUserBoard() {
//     return axios.get(API_URL + 'user', { headers: authHeader() });
//   }

//   getModeratorBoard() {
//     return axios.get(API_URL + 'mod', { headers: authHeader() });
//   }

//   getAdminBoard() {
//     return axios.get(API_URL + 'admin', { headers: authHeader() });
//   }
}

export default new UserService();