export const productionData = [
	{
		k: "0.21",
		l: "Листовое стекло",
		pT: 44,
	},
	{
		k: "0.21",
		l: "Тарное (бесцветное) стекло",
		pT: 44,
	},
	{
		k: "0.21",
		l: "Тарное цветное стекло тарное (желтое/зеленое)",
		pT: 44,
	},
	{
		k: "0.19",
		l: "Из стекловолокна (марки Е)",
		pT: 44,
	},
	{
		k: "0.25",
		l: "Из стекловолокна (изоляционное)",
		pT: 44,
	},
	{
		k: "0.18",
		l: "Специального назначения (телевизионные панели)",
		pT: 44,
	},
	{
		k: "0.13",
		l: "Специального назначения (телевизионные трубки)",
		pT: 44,
	},
	{
		k: "0.1",
		l: "Специального назначения (столовая посуда)",
		pT: 44,
	},
	{
		k: "0.03",
		l: "Специального назначения (лаборат./фарм.)",
		pT: 44,
	},
	{
		k: "0.2",
		l: "Специального назначения (ламповое)",
		pT: 44,
	},
	{ k: "1", l: "Чугун", pT: 29 },
	{ k: "1", l: "Электросталь", pT: 31 },
	{ k: "1", l: "Алюминий", pT: 38 },
	// производство азотной кислоты
	{
		k: "7",
		l: "1/3,5",
		pT: 60
	},
	{
		k: "9",
		l: "АК-72",
		pT: 60
	},
	{
		k: "9",
		l: "АК-72М",
		pT: 60
	},
	{
		k: "9",
		l: "АК-500",
		pT: 60
	},
	{
		k: "9",
		l: "УКЛ-7",
		pT: 60
	},
]
