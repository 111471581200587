<template>
  <div class="bd-main">
    <form class="bd-content ps-lg-4 container" id="typeOne" @submit.prevent="onSubmit">
      <div class="row mb-3">
        <div class="col-sm-12">
          <input v-model="company" type="text" class="form-control" id="company" name="company" @blur="companyBlur"
            placeholder="Введите наименование предприятия" />
          <small class="invalid" v-if="companyError">{{ companyError }}</small>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-5">
          <div class="col-sm-12">
            <input v-model="onvCode" type="text" class="form-control" id="onvCode" name="onvCode" @blur="onvCodeBlur"
              placeholder="Введите код ОНВ" />
            <small class="invalid" v-if="onvCodeError">{{
                onvCodeError
            }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-4">
          <div class="col-sm-12">
            <select v-model="period" class="form-select" aria-label="Default select example" id="period" name="period"
              @change="periodChange">
              <option disabled value="" selected="selected">
                Выберите период
              </option>
              <option value="Год">Год</option>
              <option value="Квартал">Квартал</option>
              <option value="Месяц">Месяц</option>
            </select>
            <small class="invalid" v-if="periodError">{{ periodError }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-3">
          <div class="col-sm-12">
            <input v-model="year" type="number" class="form-control" id="year" name="year" @blur="yearBlur"
              placeholder="Год" />
            <small class="invalid" v-if="yearError">{{ yearError }}</small>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="row mb-3 mt-3">
          <label for="thousandsTonnsProduction" class="col-sm-5 col-form-label">Вес (масса) произведённого клинкера,
            тонны</label>
          <div class="col-sm-7">
            <input v-model="thousandsTonnsProduction" type="number" step="0.01" @blur="thousandsTonnsProductionBlur"
              class="form-control form-control-type-two" id="thousandsTonnsProduction"
              name="thousandsTonnsProduction" />
            <small class="invalid" v-if="thousandsTonnsProductionError">{{
                thousandsTonnsProductionError
            }}</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="klinkerKoeff" class="col-sm-5 col-form-label">Коэффициент выбросов для клинкера, тонны CO2/тонну
            клинкера</label>
          <div class="col-sm-7">
            <input v-model="klinkerKoeff" type="number" step="0.01" class="form-control form-control-type-two"
              id="klinkerKoeff" name="klinkerKoeff" disabled />
            <small class="invalid" v-if="klinkerKoeffError">{{
                klinkerKoeffError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="weightCP" class="col-sm-5 col-form-label">вес ЦП, не возвращённой в обжиговую печь, тонны</label>
          <div class="col-sm-7">
            <input v-model="weightCP" type="number" step="0.01" class="form-control form-control-type-two" id="weightCP"
              name="weightCP" @input="getKoeff" />
            <small class="invalid" v-if="weightCPError">{{
                weightCPError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="weightKlinker" class="col-sm-5 col-form-label">вес произведённого клинкера, тонны</label>
          <div class="col-sm-7">
            <input v-model="weightKlinker" type="number" step="0.01" class="form-control form-control-type-two"
              id="weightKlinker" name="weightKlinker" @input="getKoeff" />
            <small class="invalid" v-if="weightKlinkerError">{{
                weightKlinkerError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="carbonatePath" class="col-sm-5 col-form-label">доля исходного карбоната в составе ЦП (т.е. перед
            кальцинированием), дробь</label>
          <div class="col-sm-7">
            <input v-model="carbonatePath" type="number" class="form-control form-control-type-two" id="carbonatePath"
              name="carbonatePath" @input="getKoeff" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="calceCarbonPath" class="col-sm-5 col-form-label">доля кальцинированного исходного карбоната в
            составе ЦП, дробь</label>
          <div class="col-sm-7">
            <input v-model="calceCarbonPath" type="number" class="form-control form-control-type-two"
              id="calceCarbonPath" name="calceCarbonPath" @input="getKoeff" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="koefCarbonVybr" class="col-sm-5 col-form-label">коэффициент выбросов для карбоната</label>
          <div class="col-sm-7">
            <input v-model="koefCarbonVybr" type="number" class="form-control form-control-type-two" id="koefCarbonVybr"
              name="koefCarbonVybr" @input="getKoeff" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="klinkerKoeffWithoutPopravka" class="col-sm-5 col-form-label">коэффициент выбросов для клинкера без
            поправки на ЦП</label>
          <div class="col-sm-7">
            <input v-model="klinkerKoeffWithoutPopravka" type="number" step="0.01"
              class="form-control form-control-type-two" id="klinkerKoeffWithoutPopravka"
              name="klinkerKoeffWithoutPopravka" @input="getKoeff" />
            <small class="invalid" v-if="klinkerKoeffWithoutPopravkaError">{{
                klinkerKoeffWithoutPopravkaError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="popravKoeff" class="col-sm-5 col-form-label">Поправочный коэффициент для цементной пыли
            (ЦП)</label>
          <div class="col-sm-7">
            <input v-model="popravKoeff" type="number" class="form-control form-control-type-two" id="popravKoeff"
              name="popravKoeff" :disabled="isPopravKoeffDisabled" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="vybrCO2Cement" class="col-sm-5 col-form-label">Выбросы CO2 от производства цемента, тонны</label>
          <div class="col-sm-7">
            <input v-model="vybrCO2Cement" type="text" class="form-control form-control-type-two" id="vybrCO2Cement"
              name="vybrCO2Cement" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <label for="udelPokVybr" class="col-sm-5 col-form-label">Удельный показатель выбросов, т CO2-экв/т</label>
          <div class="col-sm-7">
            <input v-model="udelPokVybr" type="text" class="form-control form-control-type-two" id="udelPokVybr"
              name="udelPokVybr" disabled :class="{ excess: excess, noexcees: !excess }" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="koefUdelVybr" class="col-sm-5 col-form-label">Производство цемента, коэффициент удельных выбросов
            (CO2-экв)</label>
          <div class="col-sm-7">
            <input v-model="koefUdelVybr" type="text" class="form-control form-control-type-two" id="koefUdelVybr"
              name="koefUdelVybr" disabled />
          </div>
        </div>
        <!-- <div class="row mb-3">
          <label for="udelCheckVybr" class="col-sm-5 col-form-label"
            >Удельный показатель выбросов, т. СО2-экв./т.</label
          >
          <div class="col-sm-7">
            <input
              v-model="udelCheckVybr"
              type="number"
              class="form-control form-control-type-two"
              id="udelCheckVybr"
              name="udelCheckVybr"
              disabled
            />
            <small class="invalid" v-if="udelCheckVybrError">{{
              udelCheckVybrError
            }}</small>
          </div>
        </div> -->
        <div class="d-grid d-md-flex justify-content-between">
          <button type="button" @click.prevent="calculateTypeTwo" class="btn devise-btn col-sm-6">
            Рассчитать
          </button>
          <button @click.prevent="generatePdfFile" type="submit" class="btn pdf-make me-2 sub_btn" disabled>
            <img src="/img/disk.svg" width="25" alt="">
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { calculateTypeTwoCement } from "../use/type_two_cement";
import { Field, useForm } from "vee-validate";
export default {
  name: "TheTypeTwoCement",
  components: {
    Field,
  },
  setup() {
    return { ...calculateTypeTwoCement() };
  },
};
</script>

<style scoped>
.excess {
  color: red;
}

.noexcees {
  color: green;
}

.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 0.5rem;
}

.devise-btn {
  background-color: #4ca6c6;
  color: white;
  border: 2px solid #4ca6c6;
}

.devise-btn:hover {
  background-color: white;
  border: 2px solid #4ca6c6;
  color: #4ca6c6;
}

.pdf-make {
  background-color: #4ca6c6 !important;
  float: right;
  cursor: pointer;
}

.pdf-make:hover {
  cursor: pointer !important;
}
.form-control:disabled {
  background-color: #e2f8e8 !important;
}
</style>