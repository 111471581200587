export const indicators = {
    "Производство кокса": {
        min: 0.348,
        max: 0.434
    },
    "Производство агломерата": {
        min: 0.224,
        max: 0.280
    },
    "Производство железорудных окатышей": {
        min: 0.053,
        max: 0.062
    },
    "Производство чугуна в доменных печах": {
        min: 1.343,
        max: 1.460
    },
    "Производство железа прямого восстановления": {
        min: 0.561,
        max: 0
    },
    "Производство стали в конвертерах": {
        min: 0.225,
        max: 0.250
    },
    "Производство стали в электродуговых печах": {
        min: 0.395,
        max: 0.496
    },

}