<template>
  <div class="bd-main">
    <form class="bd-content ps-lg-4 container" id="typeOne" @submit.prevent="onSubmit">
      <div class="row mb-3">
        <div class="col-sm-12">
          <input v-model="company" type="text" class="form-control" id="company" name="company" @blur="companyBlur"
            placeholder="Введите наименование предприятия" />
          <small class="invalid" v-if="companyError">{{ companyError }}</small>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-5">
          <div class="col-sm-12">
            <input v-model="onvCode" type="text" class="form-control" id="onvCode" name="onvCode" @blur="onvCodeBlur"
              placeholder="Введите код ОНВ" />
            <small class="invalid" v-if="onvCodeError">{{
                onvCodeError
            }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-4">
          <div class="col-sm-12">
            <select v-model="period" class="form-select" aria-label="Default select example" id="period" name="period"
              @change="periodChange">
              <option disabled value="" selected="selected">
                Выберите период
              </option>
              <option value="Год">Год</option>
              <option value="Квартал">Квартал</option>
              <option value="Месяц">Месяц</option>
            </select>
            <small class="invalid" v-if="periodError">{{ periodError }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-3">
          <div class="col-sm-12">
            <input v-model="year" type="number" class="form-control" id="year" name="year" @blur="yearBlur"
              placeholder="Год" />
            <small class="invalid" v-if="yearError">{{ yearError }}</small>
          </div>
        </div>
      </div>
      <div class="row p-4" v-if="carbonate">
        <the-carbonate v-for="rawNumber in rawCount" :key="rawNumber" :numberO="rawCount" @getCarbData="getCarbsData"
          :noCarbonates="noCarbonates"></the-carbonate>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-2">
          <button type="button" class="btn add-btn btn-sm d-flex align-items-center" @click.prevent="addRaw">
            <img src="/img/plus.svg" alt="" width="40" class="add-img">
            Добавить<br />карбонат
          </button>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="row mb-3 mt-3">
          <label for="weightCP" class="col-sm-5 col-form-label">вес или масса ЦП, не возвращённой в обжиговую печь (=
            «потери» ЦП), тонны</label>
          <div class="col-sm-7">
            <input v-model="weightCP" type="number" step="0.01" @blur="weightCPBlur"
              class="form-control form-control-type-two" id="weightCP" name="weightCP" />
            <small class="invalid" v-if="weightCPError">{{
                weightCPError
            }}</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="carbPath" class="col-sm-5 col-form-label">весовая доля исходного карбоната в составе ЦП, не
            возвращённой в обжиговую печь, дробь</label>
          <div class="col-sm-7">
            <input v-model="carbPath" type="number" step="0.01" class="form-control form-control-type-two" id="carbPath"
              name="carbPath" />
            <small class="invalid" v-if="carbPathError">{{
                carbPathError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="calcPathCP" class="col-sm-5 col-form-label">степень кальцинирования ЦП, не возвращённой в
            обжиговую печь, дробь</label>
          <div class="col-sm-7">
            <input v-model="calcPathCP" type="number" step="0.01" class="form-control form-control-type-two"
              id="calcPathCP" name="calcPathCP" />
            <small class="invalid" v-if="calcPathCPError">{{
                calcPathCPError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="kVybrNoCalcCarb" class="col-sm-5 col-form-label">коэффициент выбросов для некальцинированного
            карбоната в составе ЦП, не возвращённой в обжиговую печь, тонны CO2/тонну карбоната</label>
          <div class="col-sm-7">
            <input v-model="kVybrNoCalcCarb" type="number" step="0.01" class="form-control form-control-type-two"
              id="kVybrNoCalcCarb" name="kVybrNoCalcCarb" />
            <small class="invalid" v-if="kVybrNoCalcCarbError">{{
                kVybrNoCalcCarbError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="weightNoFuelMaterial" class="col-sm-5 col-form-label">вес или масса органического или другого
            углеродсодержащего нетопливного сырьевого материала k, тонны</label>
          <div class="col-sm-7">
            <input v-model="weightNoFuelMaterial" type="number" class="form-control form-control-type-two"
              id="weightNoFuelMaterial" name="weightNoFuelMaterial" />
            <small class="invalid" v-if="weightNoFuelMaterialError">{{
                weightNoFuelMaterialError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="pathUglerod" class="col-sm-5 col-form-label">часть общего органического или другого углерода в
            составе нетопливного сырьевого материала k, дробь</label>
          <div class="col-sm-7">
            <input v-model="pathUglerod" type="number" class="form-control form-control-type-two" id="pathUglerod"
              name="pathUglerod" />
            <small class="invalid" v-if="pathUglerodError">{{
                pathUglerodError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="koefKerogenVybr" class="col-sm-5 col-form-label">коэффициент выбросов для кероген-содержащего (или
            другого углеродсодержащего) нетопливного сырьевого материала k, тонны CO2/тонну карбоната</label>
          <div class="col-sm-7">
            <input v-model="koefKerogenVybr" type="number" class="form-control form-control-type-two"
              id="koefKerogenVybr" name="koefKerogenVybr" />
            <small class="invalid" v-if="koefKerogenVybrError">{{
                koefKerogenVybrError
            }}</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="vybrCO2Cement" class="col-sm-5 col-form-label">Выбросы CO2 от производства цемента, тонны</label>
          <div class="col-sm-7">
            <input v-model="vybrCO2Cement" type="text" class="form-control form-control-type-two" id="vybrCO2Cement"
              name="vybrCO2Cement" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <label for="udelPokVybr" class="col-sm-5 col-form-label">Удельный показатель выбросов, т CO2-экв/т</label>
          <div class="col-sm-7">
            <input v-model="udelPokVybr" type="text" class="form-control form-control-type-two" id="udelPokVybr"
              name="udelPokVybr" disabled :class="{ excess: excess, noexcees: !excess }" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="koefUdelVybr" class="col-sm-5 col-form-label">Производство цемента, коэффициент удельных выбросов
            (CO2-экв)</label>
          <div class="col-sm-7">
            <input v-model="koefUdelVybr" type="text" class="form-control form-control-type-two" id="koefUdelVybr"
              name="koefUdelVybr" disabled />
          </div>
        </div>
        <!-- <div class="row mb-3">
          <label for="udelCheckVybr" class="col-sm-5 col-form-label"
            >Удельный показатель выбросов, т. СО2-экв./т.</label
          >
          <div class="col-sm-7">
            <input
              v-model="udelCheckVybr"
              type="number"
              class="form-control form-control-type-two"
              id="udelCheckVybr"
              name="udelCheckVybr"
              disabled
            />
            <small class="invalid" v-if="udelCheckVybrError">{{
              udelCheckVybrError
            }}</small>
          </div>
        </div> -->
        <div class="d-grid d-md-flex justify-content-between">
          <button type="button" @click.prevent="calculateTypeThree" class="btn devise-btn col-sm-6">
            Рассчитать
          </button>
          <button @click.prevent="generatePdfFile" type="submit" class="btn pdf-make me-2 sub_btn" disabled>
            <img src="/img/disk.svg" width="25" alt="">
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TheCarbonate from "../components/TheCarbonate.vue";
import { calculateTypeThreeCement } from "../use/type_three_cement";
import { Field, useForm } from "vee-validate";
export default {
  name: "TheTypeThreeCement",
  components: {
    Field,
    TheCarbonate,
  },
  setup() {
    return { ...calculateTypeThreeCement() };
  },
};
</script>

<style scoped>
.excess {
  color: red;
}

.noexcees {
  color: green;
}

.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 0.5rem;
}

.devise-btn {
  background-color: #4ca6c6;
  color: white;
  border: 2px solid #4ca6c6;
}

.devise-btn:hover {
  background-color: white;
  border: 2px solid #4ca6c6;
  color: #4ca6c6;
}

.add-btn {
  color: #4ca6c6;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  transition: .3s;
}

.add-btn:hover {
  color: #4ca6c6;
  transform: scale(1.05);
  transition: .3s;
}

.pdf-make {
  background-color: #4ca6c6 !important;
  float: right;
  cursor: pointer;
}

.pdf-make:hover {
  cursor: pointer !important;
}
#typeOne {
  background: #f3f8fa !important;
  padding: 1rem;
  border-radius: 10px;
}
.form-control:disabled {
  background-color: #e2f8e8 !important;
}
</style>