export const environment = {
	// realHost: 'http://localhost/api/',

	// локалка
	// apihost: "http://jwt-example/api/",

	// тестовый сервер
	// realHost: 'http://192.168.9.52/api/',
	// urlMakerHost: "http://192.168.9.52/",


	//Прод сервер
	// apihost: 'http://gigajar.tmweb.ru/php-jwt-example/api/',
	apihost: 'https://eipc.center/php-jwt-example/api/',
}