<template>
    <div class="row mt-3 ">
        <div class="form-floating col-lg-6 mb-3">
            <input type="text" class="form-control" :name="'otherProcessName_' + processNum" :id="'otherProcessName_' + processNum"
                v-model="otherProcessName" placeholder="">
            <label class="mx-2 pt-2" :for="'otherProcessName_' + processNum">Наименование процесса</label>
            <small class="invalid" v-if="otherProcessNameError">{{ otherProcessNameError }}</small>
        </div>
        <div class="form-floating col-lg-6 mb-3">
            <input type="number" class="form-control" :name="'otherProcessEmission_' + processNum" :id="'otherProcessEmission_' + processNum"
                v-model="otherProcessEmission" placeholder="">
            <label class="mx-2 pt-2" :for="'otherProcessEmission_' + processNum">Выбросы <i>CO<sub>2</sub></i> от прочих процессов, т.
                <i>CO<sub>2</sub></i></label>
                <small class="invalid" v-if="otherProcessEmissionError">{{ otherProcessEmissionError }}</small>
        </div>
    </div>
</template>

<script>

import { reactive, watch, toRefs, ref } from "vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";

export default {
    props: ["processNum"],
    emit: ["getOtherProcessData"],
    setup(props, context) {
        const { handleSubmit } = useForm()
        const { processNum } = toRefs(props)
        const {
            value: otherProcessName,
            errorMessage: otherProcessNameError,
        } = useField("otherProcessName",
            yup.string(),
            {
                initialValue: "",
            });
            const {
            value: otherProcessEmission,
            errorMessage: otherProcessEmissionError,
        } = useField("otherProcessEmission",
            yup.number().moreThan(0, 'Должно быть больше 0'),
            {
                initialValue: 0,
            }); 

        const processData = reactive({
            otherProcessName: otherProcessName,
            otherProcessEmission: otherProcessEmission,
            num: processNum
        })
        watch([processData], ([], []) => {
            calc()
        })
        const calc = handleSubmit(values => {
            context.emit("getOtherProcessData", processData);
        })

        return {
            otherProcessName, otherProcessNameError, otherProcessEmission, otherProcessEmissionError, processNum
        }
    }
}
</script>

<style scoped>
.invalid {
    color: red;
    font-size: 12px;
    float: left;
    margin-left: 0.5rem;
}
</style>