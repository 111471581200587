<template>
    <div class="container mb-4">
        <h4>Введите данные</h4>
        <form action="">
            <div class="row">
                <div class="col-lg-9 mb-2">
                    <div class="form-floating mb-3">
                        <input v-model="company" id="company" name="company" @blur="companyBlur" type="text"
                            class="form-control">
                        <label for="company">Наименование предприятия</label>
                        <small class="invalid" v-if="companyError">{{ companyError }}</small>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input v-model="onvCode" name="onvCode" id="onvCode" @blur="onvCodeBlur" type="text"
                            class="form-control">
                        <label for="floatingInput">Код ОНВ</label>
                        <small class="invalid" v-if="onvCodeError">{{ onvCodeError }}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <div class="form-floating mb-3">
                        <input v-model="tonnsByYear" @blur="tonnsByYearBlur" name="tonnsByYear" type="number"
                            class="form-control" id="tonnsByYear">
                        <label for="tonnsByYear">Годовой фактический выпуск азотной кислоты, тонн</label>
                        <small class="invalid" v-if="tonnsByYearError">{{ tonnsByYearError }}</small>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input v-model="year" @blur="yearBlur" name="year" step="1" type="number" class="form-control"
                            id="year">
                        <label for="year">Год</label>
                        <small class="invalid" v-if="yearError">{{ yearError }}</small>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="form-floating mb-3">
                        <input disabled type="number" name="MRNO2e" id="MRNO2e" class="form-control" :value="MRNO2e"> 
                        <label for="MRNO2e">Удельный выброс закиси азота, отнесённый к тонне произведённой азотной кислоты</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input disabled type="text" name="agregateName" id="agregateName" class="form-control" :value="agregateName"> 
                        <label for="agregateName">Агрегат</label>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="emissionN2O" name="emissionN2O" class="form-control" id="emissionN2O">
                        <label for="emissionN2O">Выброс <i>N<sub>2</sub>O</i>,т</label>
                        <small class="invalid" v-if="emissionN2OError">{{ emissionN2OError }}</small>
                    </div>
                </div>
            </div>
            <h5>Расход природного газа (заполнить известное)</h5>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="onFuel" name="onFuel" class="form-control" id="onFuel">
                        <label for="onFuel">на топливо, тыс. м<sup><small>3</small></sup> в год</label>
                        <small class="invalid" v-if="onFuelError">{{ onFuelError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="onTechnology" name="onTechnology" class="form-control"
                            id="onTechnology">
                        <label for="onTechnology">на восстановление NOx, тыс. м<sup><small>3</small></sup> в год</label>
                        <small class="invalid" v-if="onTechnologyError">{{ onTechnologyError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="onTorch" name="onTorch" class="form-control" id="onTorch">
                        <label for="onTorch">на факельную установку, тыс. м<sup><small>3</small></sup> в год</label>
                        <small class="invalid" v-if="onTorchError">{{ onTorchError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="onBoiler" name="onBoiler" class="form-control" id="onBoiler">
                        <label for="onBoiler">на котёл, ТЭЦ и т.п., тыс. м<sup><small>3</small></sup> в год</label>
                        <small class="invalid" v-if="onBoilerError">{{ onBoilerError }}</small>
                    </div>
                </div>
                <input type="hidden" v-model="rashodNo" name="rashodNo">
                <small class="invalid mb-2" v-if="rashodNoError">{{ rashodNoError }}</small>
                <div class="col-lg-8">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="underburningRatio" name="underburningRatio" class="form-control"
                            id="underburningRatio">
                        <label for="underburningRatio">Коэффициент недожога углеводородной смеси на факельной
                            установке</label>
                    </div>
                </div>
                
            </div>
            <h5>Состав природного газа (заполнить известное)</h5>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="metan" name="metan" class="form-control" id="metan"
                            placeholder="name@example.com">
                        <label for="metan">Метан (<i>CH<sub>4</sub></i>), мол.%</label>
                        <small class="invalid" v-if="metanError">{{ metanError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="ethane" name="ethane" class="form-control" id="ethane"
                            placeholder="name@example.com">
                        <label for="ethane">Этан (<i>C<sub>2</sub>H<sub>6</sub></i>), мол.%</label>
                        <small class="invalid" v-if="ethaneError">{{ ethaneError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="propan" name="propan" class="form-control" id="propan"
                            placeholder="name@example.com">
                        <label for="propan">Пропан (<i>C<sub>3</sub>H<sub>8</sub></i>), мол.%</label>
                        <small class="invalid" v-if="propanError">{{ propanError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="uvc4" name="uvc4" class="form-control" id="uvc4"
                            placeholder="name@example.com">
                        <label for="uvc4">Углеводороды <i>С<sub>4</sub></i>, мол.%</label>
                        <small class="invalid" v-if="uvc4Error">{{ uvc4Error }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="uvc5" name="uvc5" class="form-control" id="uvc5"
                            placeholder="name@example.com">
                        <label for="uvc5">Углеводороды <i>С<sub>5</sub></i>, мол.%</label>
                        <small class="invalid" v-if="uvc5Error">{{ uvc5Error }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="uvc6" name="uvc6" class="form-control" id="uvc6"
                            placeholder="name@example.com">
                        <label for="uvc6">Углеводороды <i>С<sub>6</sub></i>, мол.%</label>
                        <small class="invalid" v-if="uvc6Error">{{ uvc6Error }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="carbonDioxide" name="carbonDioxide" class="form-control"
                            id="carbonDioxide" placeholder="name@example.com">
                        <label for="carbonDioxide">Углекислый газ (<i>СO<sub>2</sub></i>), мол.%</label>
                        <small class="invalid" v-if="carbonDioxideError">{{ carbonDioxideError }}</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="nonCarbonSum" name="nonCarbonSum" class="form-control"
                            id="nonCarbonSum" placeholder="name@example.com">
                        <label for="nonCarbonSum">Сумма неуглеродсодержащих компонентов, мол.%</label>
                        <small class="invalid" v-if="nonCarbonSumError">{{ nonCarbonSumError }}</small>
                    </div>
                    
                </div>
                <input type="hidden" v-model="sostavNo" name="rashodNo">
                    <small class="invalid mb-2" v-if="sostavNoError">{{ sostavNoError }}</small>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="emissionCH4" name="emissionCH4" class="form-control" id="emissionCH4"
                            placeholder="name@example.com">
                        <label for="emissionCH4">Выбросы <i>СH<sub>4</sub></i>, т в год</label>
                        <small class="invalid" v-if="emissionCH4Error">{{ emissionCH4Error }}</small>
                    </div>
                </div>
            </div>
            <button @click.prevent="calculate" class="btn btn-primary btn-lg">Рассчитать</button>
        </form>
        <!-- модальное окошко -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-md-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Результаты расчетов</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Наименование</th>
                                    <th>Значение</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Наименование предприятия</td>
                                    <td>{{ company }}</td>
                                </tr>
                                <tr>
                                    <td>код ОНВ</td>
                                    <td>{{ onvCode }}</td>
                                </tr>
                                <tr>
                                    <td>Агрегат</td>
                                    <td>{{ agregateName }}</td>
                                </tr>
                                <tr>
                                    <td>Σ <i>СO<sub>2</sub></i> т</td>
                                    <td>{{result.sumCO2t.toFixed(4)}}</td>
                                </tr>
                                <tr>
                                    <td>Σ <i>СO<sub>2</sub></i> т <i>СO<sub>2</sub></i>e</td>
                                    <td>{{result.sumCO2tCO2e.toFixed(4)}}</td>
                                </tr>
                                <tr>
                                    <td>т <i>СO<sub>2</sub></i> e/т <i>HNO<sub>3</sub></i></td>
                                    <td>{{result.CO2e_NH3.toFixed(4)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- модальное окошко -->
    </div>
</template>

<script>

import * as yup from "yup"
import { useField, useForm } from "vee-validate"
import { watch, ref, reactive, onMounted } from "vue"
import { Modal } from 'bootstrap'
import { useStore } from "vuex";

export default {
    setup() {
        const store = useStore();
        const MRNO2e = Number(store.state.production.k)//коэфф агрегата
        const agregateName = store.state.production.l //название агрегата
        
        const mod_state = reactive({
            modal_demo: null,
        })

        onMounted(() => {
            mod_state.modal_demo = new Modal('#exampleModal', {})
        })

        function openModal() {
            mod_state.modal_demo.show()
        }

        function closeModal() {
            mod_state.modal_demo.hide()
        }


        const { handleSubmit, isSubmitting, submitCount } = useForm()
        const FOR_METAN = 1
        const FOR_ETHANE = 2
        const FOR_PROPAN = 3
        const FOR_UVC4 = 4
        const FOR_UVC5 = 5
        const FOR_UVC6 = 6
        const CARBON_DIOXIDE = 1

        const DENSITY_CO2 = 1.9768
        const DENSITY_CH4 = 0.717
        const GWP_CP4 = 25

        const showModal = ref(false)

        const {
            value: rashodNo,
            errorMessage: rashodNoError,
        } = useField(
            "rashodNo",
            yup
                .number()
                .moreThan(
                    0,
                    `Отсутствует расход природного газа`
                ), { initialValue: 0 }
        )
        const {
            value: sostavNo,
            errorMessage: sostavNoError,
        } = useField(
            "sostavNo",
            yup
                .number()
                .moreThan(
                    0,
                    `Отсутствует состав природного газа`
                )
                .max(
                    100, `Не может быть больше 100%`
                )
                , { initialValue: 0 }
        )

        const {
            value: company,
            errorMessage: companyError,
            handleBlur: companyBlur,
        } = useField(
            "company",
            yup
                .string()
                .trim()
                .required("Введите наименование компании")
                .min(
                    3,
                    `Наименование компании не должно быть меньше 3 символов`
                )
        )
        const {
            value: onvCode,
            errorMessage: onvCodeError,
            handleBlur: onvCodeBlur,
        } = useField(
            "onvCode",
            yup
                .string()
                .trim()
                .required("Введите код ОНВ")
                .min(
                    5,
                    `Код ОНВ не должен быть меньше меньше 5 символов`
                )
        )
        const {
            value: tonnsByYear,
            errorMessage: tonnsByYearError,
            handleBlur: tonnsByYearBlur,
        } = useField(
            "tonnsByYear",
            yup
                .number()
                .typeError("Введите число")
                .moreThan(0, "Значение должно быть больше 0"),
            { initialValue: 0 }
        )
        const {
            value: year,
            errorMessage: yearError,
            handleBlur: yearBlur,
        } = useField(
            "year",
            yup
                .string()
                .trim()
                .required("Введите год")
                .min(4, "Введите корректный год")
                .max(4, "Введите корректный год")
        )
        const {
            value: emissionN2O,
            errorMessage: emissionN2OError,
            handleBlur: emissionN2OBlur,
        } = useField(
            "emissionN2O",
            yup
                .number()
                // .transform((value) => Number.isNaN(value) ? 0 : value )
                .typeError('Должно быть число')
                // .default(0)
                .min(0, `Не может быть меньше 0`),
            { initialValue: 0 }
        )
        const {
            value: onFuel,
            errorMessage: onFuelError,
            handleBlur: onFuelBlur,
        } = useField(
            "onFuel",
            yup
                .number()
                // .transform((value) => Number.isNaN(value) ? 0 : value )
                .typeError('Должно быть число')
                // .default(0)
                .min(0, `Не может быть меньше 0`),
            { initialValue: 0 }
        )
        const {
            value: onTechnology,
            errorMessage: onTechnologyError,
            handleBlur: onTechnologyBlur,
        } = useField(
            "onTechnology",
            yup
                .number()
                // .transform((value) => Number.isNaN(value) ? 0 : value )
                .typeError('Должно быть число')
                .min(0, `Не может быть меньше 0`),
            { initialValue: 0 }
        )
        const {
            value: onTorch,
            errorMessage: onTorchError,
            handleBlur: onTorchBlur,
        } = useField(
            "onTorch",
            yup
                .number()
                // .transform((value) => Number.isNaN(value) ? 0 : value )
                .typeError('Должно быть число')
                // .default(0)
                .min(0, `Не может быть меньше 0`),
            { initialValue: 0 }
        )
        const {
            value: onBoiler,
            errorMessage: onBoilerError,
            handleBlur: onBoilerBlur,
        } = useField(
            "onBoiler",
            yup
                .number()
                // .transform((value) => Number.isNaN(value) ? 0 : value )
                .typeError('Должно быть число')
                // .default(0)
                .min(0, `Не может быть меньше 0`),
            { initialValue: 0 }
        )
        const {
            value: underburningRatio,
        } = useField(
            "underburningRatio",
            yup
                .number(),
            { initialValue: 0.005 }
        )
        const {
            value: metan,
            errorMessage: metanError,
        } = useField(
            "metan",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: ethane,
            errorMessage: ethaneError,
        } = useField(
            "ethane",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: propan,
            errorMessage: propanError,
        } = useField(
            "propan",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: uvc4,
            errorMessage: uvc4Error,
        } = useField(
            "uvc4",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: uvc5,
            errorMessage: uvc5Error,
        } = useField(
            "uvc5",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: uvc6,
            errorMessage: uvc6Error,
        } = useField(
            "uvc6",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: carbonDioxide,
            errorMessage: carbonDioxideError,
        } = useField(
            "carbonDioxide",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: nonCarbonSum,
            errorMessage: nonCarbonSumError,
        } = useField(
            "nonCarbonSum",
            yup
                .number()
                .min(0, "Значение не может быть меньше 0")
                .max(100, "Значение не может быть больше 100"),
            { initialValue: 0 }
        )
        const {
            value: emissionCH4,
            errorMessage: emissionCH4Error,
            handleBlur: emissionCH4Blur,
        } = useField(
            "emissionCH4",
            yup
                .number()
                .typeError("Введите число")
                .min(0, "Значение не может быть меньше 0"),
            { initialValue: 0 }
        )
        let forFuel = reactive({
            metan: 0,
            ethane: 0,
            propan: 0,
            uvc4: 0,
            uvc5: 0,
            uvc6: 0,
            carbonDioxide: 0
        })
        let forTorch = reactive({
            metan: 0,
            ethane: 0,
            propan: 0,
            uvc4: 0,
            uvc5: 0,
            uvc6: 0
        })
        let tonnsCO2 = reactive({
            onFuel: 0,
            onTechnology: 0,
            onTorch: 0,
            onBoiler: 0,
        })
        let tonnsCH4 = reactive({
            onTorch: 0,
        })
        let tonnsCH4CO2e = reactive({
            onTorch: 0
        })
        let gas = reactive({
            metan: metan,
            ethane: ethane,
            propan: propan,
            uvc4: uvc4,
            uvc5: uvc5,
            uvc6: uvc6,
            carbonDioxide: carbonDioxide,
            nonCarbonSum: nonCarbonSum,
            onFuel: onFuel,
            onTechnology: onTechnology,
            onTorch: onTorch,
            onBoiler: onBoiler,
            underburningRatio: underburningRatio,
            emissionCH4: emissionCH4
        })
        let result = reactive({
            sumCO2t: 0,
            sumCO2tCO2e: 0,
            CO2e_NH3: 0
        })
        let realEmissN2O = ref(0)
        let fuelCompositionSum = ref(0)
        let torchSum = ref(0)
        watch([gas, tonnsByYear, emissionN2O], ([], []) => {

            if(emissionN2O.value == 0) {
                realEmissN2O.value = MRNO2e * tonnsByYear.value * 0.001
            } else {
                realEmissN2O.value = emissionN2O.value
            }
            
            fuelCompositionSum.value = 0
            torchSum.value = 0
            rashodNo.value = gas.onFuel + gas.onTechnology + gas.onTorch + gas.onBoiler
            sostavNo.value = gas.metan + gas.ethane + gas.propan + gas.uvc4 + gas.uvc5 + gas.uvc6 + gas.carbonDioxide + gas.nonCarbonSum

            forFuel.metan = FOR_METAN * 0.01 * gas.metan
            forFuel.ethane = FOR_ETHANE * 0.01 * gas.ethane
            forFuel.propan = FOR_PROPAN * 0.01 * gas.propan
            forFuel.uvc4 = FOR_UVC4 * 0.01 * gas.uvc4
            forFuel.uvc5 = FOR_UVC5 * 0.01 * gas.uvc5
            forFuel.uvc6 = FOR_UVC6 * 0.01 * gas.uvc6
            forFuel.carbonDioxide = CARBON_DIOXIDE * 0.01 * gas.carbonDioxide
            for (let key in forFuel) {
                fuelCompositionSum.value = fuelCompositionSum.value + forFuel[key]
            }
            // console.log(fuelCompositionSum.value)
            forTorch.metan = FOR_METAN * 0.01 * gas.metan
            forTorch.ethane = FOR_ETHANE * 0.01 * gas.ethane
            forTorch.propan = FOR_PROPAN * 0.01 * gas.propan
            forTorch.uvc4 = FOR_UVC4 * 0.01 * gas.uvc4
            forTorch.uvc5 = FOR_UVC5 * 0.01 * gas.uvc5
            forTorch.uvc6 = FOR_UVC6 * 0.01 * gas.uvc6
            for (let key in forTorch) {
                torchSum.value = torchSum.value + forTorch[key]
            }
            
            tonnsCH4.onTorch = (forTorch.metan * gas.onTorch * gas.underburningRatio * DENSITY_CH4) + gas.emissionCH4
            tonnsCO2.onFuel = gas.onFuel * fuelCompositionSum.value * DENSITY_CO2
            tonnsCO2.onTechnology = gas.onTechnology * fuelCompositionSum.value * DENSITY_CO2
            tonnsCO2.onBoiler = gas.onBoiler * fuelCompositionSum.value * DENSITY_CO2
            tonnsCO2.onTorch = (gas.onTorch * (gas.carbonDioxide * 0.01 + torchSum.value * (1 - gas.underburningRatio))) * DENSITY_CO2
            tonnsCH4CO2e.onTorch = tonnsCH4.onTorch * GWP_CP4

            result.sumCO2t = tonnsCO2.onFuel + tonnsCO2.onTechnology + tonnsCO2.onBoiler + tonnsCO2.onTorch
            result.sumCO2tCO2e = result.sumCO2t + tonnsCH4CO2e.onTorch + realEmissN2O.value * 298
            result.CO2e_NH3 = tonnsByYear.value > 0 ? result.sumCO2tCO2e / tonnsByYear.value : 0

        })

        const calculate = handleSubmit((values) => {
            showModal.value = true
            openModal()
        })
        
        return {
            calculate,
            company, companyError, companyBlur,
            onvCode, onvCodeError, onvCodeBlur,
            tonnsByYear, tonnsByYearError, tonnsByYearBlur,
            year, yearError, yearBlur,
            onFuel, onFuelError, onFuelBlur, onTechnology, onTechnologyError, onTechnologyBlur,
            onTorch, onTorchError, onTorchBlur, onBoiler, onBoilerError, onBoilerBlur, underburningRatio,
            metan, metanError,
            ethane, ethaneError,
            propan, propanError,
            uvc4, uvc4Error,
            uvc5, uvc5Error,
            uvc6, uvc6Error,
            carbonDioxide, carbonDioxideError,
            nonCarbonSum, nonCarbonSumError,
            emissionCH4, emissionCH4Error, emissionCH4Blur,
            showModal, result,
            rashodNo, rashodNoError,
            sostavNo, sostavNoError,
            MRNO2e, emissionN2O, emissionN2OError, emissionN2OBlur, agregateName
        }
    },
}
</script>

<style scoped>
.invalid {
    color: red;
    font-size: 12px;
    float: left;
    margin-left: 0.5rem;
}
</style>