<template>
    <div class="sCont">
        <div class="input_container table-responsive">
            <h3 class="subTitle">Входной поток</h3>
            <table class="table table-bordered align-middle table-responsive table-sm">
                <TableHeaderComponent :years="years" />
                <tbody>
                    <TableRowComponent v-for="(vol, index) in inputDataArr" :key="index" :number="index" :type="'input'"
                        :minus="vol.minus" :span="vol.span" :name="vol.name" :secondName="vol.subName" :units="vol.units"
                        :years="years" :volume="vol.volume" :volumeMid="vol.volumeMiddle"
                        :CO="vol.volumeCO" :disabledCO="vol.disabledCO"
                        :koeff="vol.volumeK" :volumeAbs="vol.volumeAbs" :volumeAbsMid="vol.volumeAbsMiddle"
                        @showButton="showB" @changeCO="changeCO" @changeK="changeK"
                    />
                </tbody>
            </table>
        </div>
        <hr>
        <div class="input_container table-responsive">
            <h3 class="subTitle">Выходной поток</h3>
            <table class="table table-bordered align-middle table-responsive table-sm">
                <TableHeaderComponent :years="years" />
                <tbody>
                    <TableRowComponent v-for="(vol, index) in outputDataArr" :key="index" :number="index" :type="'output'"
                        :minus="vol.minus" :span="vol.span" :name="vol.name" :secondName="vol.subName" :units="vol.units"
                        :years="years" :volume="vol.volume" :volumeMid="vol.volumeMiddle"
                        :CO="vol.volumeCO" :disabledCO="vol.disabledCO"
                        :koeff="vol.volumeK" :volumeAbs="vol.volumeAbs" :volumeAbsMid="vol.volumeAbsMiddle"
                        @showButton="showB" @changeCO="changeCO" @changeK="changeK"
                    />
                </tbody>
            </table>
        </div>
        <div class="b-submitter mt-3" v-if="!showButton">
            <FormKit type="button" class="bSubmit" outer-class="bSubmitter" label="Произвести расчет"
                @click.prevent="sendNewData" />
        </div>
    </div>
</template>

<script>

import { useStore } from 'vuex'
import { ref, toRefs } from "vue"
import TableRowComponent from '@/components/blackMetal/TableRowComponent.vue'
import TableHeaderComponent from '@/components/blackMetal/TableHeaderComponent.vue'
import {cherMetData} from '@/use/cherMetData.js'

export default {
    components: { TableRowComponent, TableHeaderComponent },
    emits: ['sendData', 'hideResults'],
    props: {
        showButton: Boolean
    },
    setup(props, { emit }) {
        const store = useStore()
        const years = store.state.firstPage.year.sort()
        const process = store.state.productionType
        const prop = toRefs(props)
        const showButton = ref()
        showButton.value = prop.showButton.value
        const inputDataArr = ref(cherMetData[process].inputDataArr)
        const outputDataArr = ref(cherMetData[process].outputDataArr)
        
        for (let key = 0; key < years.length; key++) {
            for (let i = 0; i < inputDataArr.value.length; i++) {
                inputDataArr.value[i].volume[key] = 0
                inputDataArr.value[i].volumeAbs[key] = 0
            }
            for (let i = 0; i < outputDataArr.value.length; i++) {
                outputDataArr.value[i].volume[key] = 0
                outputDataArr.value[i].volumeAbs[key] = 0
            }
        }

        function sendNewData() {
            const data =
            {
                'CO2pryamVybr': [
                    ...inputDataArr.value.filter(item => { return item.CO2pryamVybr === 1 }), ...outputDataArr.value.filter(item => { return item.CO2pryamVybr === 1 })
                ],
                'elVybr': [
                    ...inputDataArr.value.filter(item => { return item.elVybr === 1 }), ...outputDataArr.value.filter(item => { return item.elVybr === 1 })
                ],
                'tepVybr': [
                    ...inputDataArr.value.filter(item => { return item.tepVybr === 1 }), ...outputDataArr.value.filter(item => { return item.tepVybr === 1 })
                ],
                'techGasVybr': [
                    ...inputDataArr.value.filter(item => { return item.techGasVybr === 1 }), ...outputDataArr.value.filter(item => { return item.techGasVybr === 1 })
                ],
                'popravka': [
                    ...inputDataArr.value.filter(item => { return item.popravka === 1 }), ...outputDataArr.value.filter(item => { return item.popravka === 1 })
                ],
                'productWeight': [
                    ...outputDataArr.value.filter(item => { return item.productWeight === 1 })
                ],
                'showResults': true,
                'input': inputDataArr.value,
                'output': outputDataArr.value,

            }
            emit('sendData', data)
            showButton.value = true
        }
        function showB(event) {
            showButton.value = event
            emit('hideResults', [])
        }
        function changeCO(event){
            if(event.type == "output"){
                outputDataArr.value[event.num].volumeCO = event.CO
            } 
            if(event.type == "input") {
                inputDataArr.value[event.num].volumeCO = event.CO
            }
        }
        function changeK(event){
            if(event.type == "output"){
                outputDataArr.value[event.num].volumeK = event.ko
            } 
            if(event.type == "input") {
                inputDataArr.value[event.num].volumeK = event.ko
            }
        }

        return {
            years, sendNewData,
            showButton,
            inputDataArr, showB, outputDataArr,
            changeCO, changeK
        }
    }
}
</script>

<style scoped>
p.qwerty {
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #f2f2f2;
    text-align: left;
    margin-top: -15px;
}

.input_container {
    width: 98%;
    margin: 0 auto;
    padding: 1rem;
    border: 2px solid darkcyan;
    border-radius: 10px;
    background-color: rgb(239, 242, 248);
}

</style>