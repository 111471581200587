<template>
    <thead>
        <tr>
            <th scope="col" rowspan="2" class="align-middle text-center" style="width: 5%"></th>
            <th scope="col" rowspan="2" class="align-middle text-center" style="width: 55%"></th>
            <th scope="col" :colspan="years.length + 1" class="align-middle text-center" v-html="header"></th>
        </tr>
        <tr>
            <th class="text-center" scope="col" v-for="year in years" :key="year">{{ year }} год
            </th>
            <th>Среднее</th>
        </tr>
    </thead>
</template>

<script>

export default {
    name: 'ResultTableHeaderComponent',
    props: {
        years: Array,
        header: String
    },

    setup(props) {
        const years = props.years
        const header = props.header
        return {
            years, header
        }
    }
}

</script>