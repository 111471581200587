export function colorMetalFunctions(){

    const arrayMiddle = function (array, objWeight) {

        let summ = 0
        let middle = 0
        for (let key in objWeight) {
            summ += Number(array[key])
        }
        let objSize = Object.keys(objWeight).length;
        middle = summ / Number(objSize)
        return middle.toFixed(3)
    }
    let makeString = function (point) {
        return point.replace(/[.]/g, ",");
    }
    return {
        arrayMiddle, makeString
    }
}