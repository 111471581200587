<template>
  <div class="home">
    <div class="row gy-2 mt-5">
      <div class="col-xxl-4 col-xl-6 item" v-if="checkerPage">
        <router-link to="/type-one-cement">
          <div class=" card-one">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 1</h5> -->
              <p class="card-text mt-2">
                Известны данные о производстве по типам цемента и о доле клинкера в цементе.
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-xxl-4 col-xl-6 item" v-else>
        <router-link to="/type-one">
          <div class=" card-one">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 1</h5> -->
              <p class="card-text mt-2">
                Расчет выбросов при наличии данных только по типам продукции
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-xxl-4 col-xl-6 item" v-if="checkerPage">
        <router-link to="/type-two-cement">
          <div class=" card-two">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 2</h5> -->
              <p class="card-text mt-2">
                Рассчитать выбросы на основе национальной статистики по производству клинкера.
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-xxl-4 col-xl-6 item" v-else>
        <router-link to="/type-two">
          <div class=" card-two">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 2</h5> -->
              <p class="card-text mt-2">
                Расчет выбросов с учетом технологии производства
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-xxl-4 col-xl-6 item" v-if="checkerPage">
        <router-link to="/type-three-cement">
          <div class=" card-three">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 3</h5> -->
              <p class="card-text mt-2">
                Собрать заводские данные о потреблении карбонатов.
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-xxl-4 col-xl-6 item" v-else>
        <router-link to="/type-three">
          <div class=" card-three">
            <div class="card-body">
              <!-- <h5 class="card-title">Расчет 3</h5> -->
              <p class="card-text mt-2">
                Расчет выбросов на основании загрузки карбонатов
              </p>
              <!-- <a href="#" class="btn btn-primary">Перейти</a> -->
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "Types",
  setup() {
    const store = useStore();
    const checkerPage = ref(false);
    if (
      store.state.activityType == "Производство минеральных материалов" &&
      store.state.productionType == "Производство цемента"
    ) {
      checkerPage.value = true;
    }
    return {
      checkerPage,
    };
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}
.home {
  width: 70%;
  margin: 0 auto;
}
.card {
  /* border: 2px solid rgb(63, 135, 243) !important;
  border-radius: 10px !important; */
}
.bd-main {
  /* border: 2px solid lightgray; */
  padding: 2rem;
  border-radius: 15px;
}
.card-one{
  width: 310px;
  background: url(/img/count_type/img1.jpg) no-repeat;
  height: 290px;
}
.card-two{
  width: 310px;
  background: url(/img/count_type/img2.jpg) no-repeat;
  height: 290px;
}
.card-three{
  width: 310px;
  background: url(/img/count_type/img3.jpg) no-repeat;
  height: 290px;
}
.card-text{
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.item:hover {
  transition: .5s;
  transform: scale(1.05);
}
.item {
  transition: 0.5s;
}
</style>
