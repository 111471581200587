<template>
    <div class="row my-3 css-fuel border rounded">
        <div class="col-lg-12 mb-3">
            <label :for="'fuelType_' + prodTypeNum + '_' + fuelNum" class="col-form-label">
                <p class="h6">Выберите топливо</p>
            </label>
            <!-- <div class="col-sm-7"> -->
            <Field as="select" v-model="fuelType" class="form-select form-select-lg" aria-label="Default select example"
                :id="'fuelType_' + prodTypeNum + '_' + fuelNum" :name="'fuelType_' + prodTypeNum + '_' + fuelNum"
                @change="getFuelEmission">
                <option disabled value="zero">Выберите тип топлива</option>
                <template v-for="(value, name) in fuelTypeData" :key="name">
                    <option v-if="name != 'zero'" :value="name">{{ name }}</option>
                </template>
            </Field>
            <small class="invalid" v-if="fuelTypeError">{{ fuelTypeError }}</small>
        </div>
        <div class="form-floating col-lg-6 mb-3">
            <input type="number" class="form-control" :name="'fuelConsumption_' + prodTypeNum + '_' + fuelNum"
                :id="'fuelConsumption_' + prodTypeNum + '_' + fuelNum" v-model="fuelConsumption" @input="getFuelEmission" />
            <label :for="'fuelConsumption_' + prodTypeNum + '_' + fuelNum" class="col-form-label mx-2 pt-2">Расход топлива,
                ГДж</label>
        </div>
        <div class="form-floating col-lg-6 mb-3">
            <input type="number" class="form-control" :name="'fuelCO2_' + prodTypeNum + '_' + fuelNum"
                :id="'fuelCO2_' + prodTypeNum + '_' + fuelNum" v-model="fuelCO2" @input="setFuelCO2" />
            <label :for="'fuelCO2_' + prodTypeNum + '_' + fuelNum" class="col-form-label mx-2 pt-2">Выбросы СО2 от сжигания
                топлива, т.
                <i>CO<sub>2</sub></i> <small>(можно ввести вручную)</small></label>
            <small class="invalid" v-if="fuelCO2Error">{{ fuelCO2Error }}</small>
        </div>
    </div>
</template>

<script>
import { useField, Field, useForm } from "vee-validate"
import * as yup from 'yup';
import { reactive, ref, watch, toRefs } from 'vue'
import { fuelTypeData } from "../use/fuelTypeData"

export default {
    components: { Field },
    props: ["prodTypeNum", "fuelNum"],
    emits: ["getFuelData"],

    setup(props, context) {
        const { prodTypeNum, fuelNum } = toRefs(props)
        const { handleSubmit } = useForm()
        const {
            value: fuelType,
            errorMessage: fuelTypeError,
        } = useField(
            "fuelType",
            yup.string().matches(/^((?!zero).)/, { message: "Выберите тип топлива" }),
            {
                initialValue: "zero",
            }
        );
        const { value: fuelConsumption, errorMessage: fuelConsumptionError } =
            useField(
                "fuelConsumption",
                yup
                    .number()
                    .typeError("Должно быть число")
                // .moreThan(0, "Значение должно быть больше 0")
                ,
                { initialValue: 0 }
            );
        const { value: fuelCO2, errorMessage: fuelCO2Error } =
            useField(
                "fuelCO2",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0"),
                { initialValue: 0 }
            );

        const fuelData = reactive({
            fuelType: fuelType,
            fuelConsumption: fuelConsumption,
            fuelCO2: fuelCO2,
            num: fuelNum
        })
        const getFuelEmission = () => {
            fuelCO2.value = fuelConsumption.value * 0.001 * fuelTypeData[fuelType.value].k
            context.emit("getFuelData", fuelData);
        }
        const setFuelCO2 = (event) => {
            fuelCO2.value = Number(event.target.value)
            context.emit("getFuelData", fuelData);
        }

        return {
            fuelType, fuelTypeError, fuelConsumption, fuelConsumptionError, fuelCO2, fuelCO2Error, fuelTypeData, fuelNum, prodTypeNum, getFuelEmission, setFuelCO2
        }
    }
}

</script>

<style scoped></style>