<template>
    <Production :showButton="results" @sendData="getData" @hideResults="hideResults" />
    <ResultComponent v-if="results" :key="key" :data="data" />
</template>

<script>
import Production from '../components/blackMetal/Production.vue'
import ResultComponent from '../components/blackMetal/ResultComponent.vue'
import { useStore } from 'vuex'
import { ref, watch } from "vue"
export default {
    components: {
        Production, ResultComponent,
    },
    setup() {
        const key = ref(0)
        const store = useStore()
        const process = store.state.productionType
        const years = store.state.firstPage.year.sort()
        const results = ref(false)
        const data = ref({})


        function getData(event) {
            data.value = event
            results.value = data.value.showResults
            setTimeout(function () {
                document.querySelector('.result_container').scrollIntoView({ behavior: "smooth", block: "start" })
            }, 500)
            key.value++
        }
        watch(data, () => {

        }, { deep: true })
        function hideResults() {
            results.value = false
        }
        return {
            getData, results, years, key, data, process, hideResults
        }
    }

}
</script>