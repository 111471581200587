import { computed, ref, watch } from "vue"
import * as yup from "yup"
import { useField, useForm } from "vee-validate"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
// import { jsPDF } from "jspdf";
import { logoData } from "./logo"
import {cementData}  from "./cementData"

export function calculateTypeOneCement() {
	const { handleSubmit, isSubmitting, submitCount } = useForm()
	const router = useRouter()
	const store = useStore()

	// const formValues = {
	//   thousandsTonnsProduction: 0,
	//   udelCheckVybr: 0
	// };
	// useForm({
	//   initialValues: formValues,
	// });

	const MIN_LENGTH = 4
	const {
		value: company,
		errorMessage: companyError,
		handleBlur: companyBlur,
	} = useField(
		"company",
		yup
			.string()
			.trim()
			.required("Введите наименование компании")
			.min(
				MIN_LENGTH,
				`Наименование компании не должно быть меньше ${MIN_LENGTH} символов`
			)
	)
	const {
		value: onvCode,
		errorMessage: onvCodeError,
		handleBlur: onvCodeBlur,
	} = useField(
		"onvCode",
		yup
			.string()
			.trim()
			.required("Введите код ОНВ")
			.min(
				MIN_LENGTH,
				`Код ОНВ не должен быть меньше ${MIN_LENGTH} символов`
			)
	)
	const {
		value: period,
		errorMessage: periodError,
		handleBlur: periodChange,
	} = useField("period", yup.string().trim().required("Введите период"), {
		initialValue: "",
	})
	const {
		value: year,
		errorMessage: yearError,
		handleBlur: yearBlur,
	} = useField(
		"year",
		yup
			.string()
			.trim()
			.required("Введите год")
			.min(4, "Введите корректный год")
	)

	const {
		value: udelCheckVybr,
		errorMessage: udelCheckVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"udelCheckVybr",
		// yup.number().min(0, "Значение не должно быть меньше 0"),
		yup.number(),
		{ initialValue: 0 }
	)

	const {
		value: thousandsTonnsProduction,
		errorMessage: thousandsTonnsProductionError,
		handleBlur: thousandsTonnsProductionBlur,
	} = useField(
		"thousandsTonnsProduction",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 1 }
	)

	const {
		value: stecloboyPath,
		errorMessage: stecloboyPathError,
		handleBlur: stecloboyPathBlur,
	} = useField(
		"stecloboyPath",
		yup
			.number()
			.typeError("Введите число")
			.min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 10 }
	)

	const {
		value: klinkerImport,
		errorMessage: klinkerImportError,
		handleBlur: klinkerImportBlur,
	} = useField(
		"klinkerImport",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 1 }
	)
	const {
		value: klinkerFraction,
		errorMessage: klinkerFractionError,
		handleBlur: klinkerFractionBlur,
	} = useField(
		"klinkerFraction",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)
	const {
		value: klinkerKoeff,
		errorMessage: klinkerKoeffError,
		handleBlur: klinkerKoeffBlur,
	} = useField(
		"klinkerKoeff",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)
	const {
		value: klinkerExport,
		errorMessage: klinkerExportError,
		handleBlur: klinkerExportBlur,
	} = useField(
		"klinkerExport",
		yup
			.number()
			.typeError("Введите число")
			.min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 0 }
	)

	const {
		value: cementCo2,
		errorMessage: cementCo2Error,
		// handleBlur: yearBlur,
	} = useField(
		"cementCo2",
		yup.number().min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 0 }
	)

	const {
		value: udelPokVybr,
		errorMessage: udelPokVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"udelPokVybr",
		yup.number().min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 0 }
	)

	const {
		value: koefUdelVybr,
		errorMessage: koefUdelVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"koefUdelVybr",
		// yup.number().min(0, "Значение не должно быть меньше 0"),
		yup.number(),
		{ initialValue: 0.53 }
	)

	// const {
	// 	value: activityType,
	// 	errorMessage: activityTypeError,
	// 	// handleBlur: yearBlur,
	// } = useField(
	// 	"activityType",
	// 	yup.string().required("Выберите вид деятельности").min(1),
	// 	{ initialValue: "" }
	// )

	// const {
	// 	value: productionType,
	// 	errorMessage: productionTypeError,
	// 	// handleBlur: yearBlur,
	// } = useField("productionType", yup.string(), { initialValue: "" })

	// const {
	// 	value: production,
	// 	// errorMessage: productionError,
	// 	// handleBlur: yearBlur,
	// } = useField("production", yup.object(), { initialValue: { k: "0", l: "zero" } })

	// const production = ref({ k: "0", l: "zero" })

	const {
		value: cementType,
		errorMessage: cementTypeError,
		// handleBlur: yearBlur,
	} = useField(
		"cementType",
		yup
			.string()
			.matches(/^((?!zero).)/, { message: "Выберите тип цемента" }),
		{
			initialValue: "zero",
		}
	)

	const onSubmit = handleSubmit(async (values) => {
		const data = new FormData()
		data.append("dfdf", "sdsd")
		const response = await fetch("http://calc/api/", {
			method: "POST",
			body: JSON.stringify(values),
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
		})
		const res = await response.json()
		return
		router.push("/")
	})
	const onChangePT = () => {
		production.value = { k: "0", l: "zero" }
	}
	const onChangePTandVP = () => {
		production.value = { k: "0", l: "zero" }
		productionType.value = ""
	}

	// watch(activityType, (newValue, oldValue) => {
	// 	production.value = { k: "0", l: "zero" }
	// })
	// watch(productionType, (newValue, oldValue) => {
	// 	production.value = { k: "0", l: "zero" }
	// })
	// watch(techVybross, (newValue, oldValue) => {})

	const productionTypeInclArray = ["29", "31", "38", "44"]
	const diokUgl = 1
	const metan = 25
	const zakAzot = 298

	const excess = ref(false)

	function setValues(event){
		klinkerFraction.value = cementData[event.target.value].klinker
		klinkerKoeff.value = cementData[event.target.value].co2
	}
	const calculate = handleSubmit((values) => {
		cementCo2.value = (thousandsTonnsProduction.value * klinkerFraction.value - klinkerImport.value + klinkerExport.value) * klinkerKoeff.value
		udelPokVybr.value = cementCo2.value / thousandsTonnsProduction.value
		udelPokVybr.value > koefUdelVybr.value ? excess.value = true : excess.value = false

		document.querySelector("button.sub_btn").removeAttribute("disabled")
	})

	

	function generatePdfFile() {
		var pdfMake = require("pdfmake/build/pdfmake.js")
		var pdfFonts = require("pdfmake/build/vfs_fonts")

		pdfMake.fonts = {
			Arial: {
				normal: "ArialRegular.ttf",
				bold: "ArialBold.ttf",
				italics: "ArialItalic.ttf",
				bolditalics: "ArialRegular.ttf",
			},
		}
		pdfMake.vfs = pdfFonts.pdfMake.vfs
		var docDefinition = {
			content: company.value,
			defaultStyle: {
				font: "Roboto",
			},
		}
		let d = new Date()
		let formatter = new Intl.DateTimeFormat("ru", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		})
		let date = formatter.format(d)
		var dd = {
			pageOrientation: "landscape",
			defaultStyle: {
				font: "Arial",
				fontSize: 11,
			},
			footer: function (currentPage, pageCount) {
				// return currentPage.toString() + ' из ' + pageCount;
				return [
					{
						columns: [
							{
								width: 750,
								text: date,
								margin: [15, 0, 0, 8],
								fontSize: 10,
							},
							{
								text:
									"Стр. " +
									currentPage.toString() +
									" из " +
									pageCount,
								margin: [10, 0, 0, 0],
								fontSize: 10,
							},
						],
					},
					{
						text: 'данный отчёт сформирован калькулятором, разработанным ФГАУ "НИИ "ЦЭПП"" и носит рекомендательный характер',
						fontSize: 9,
						color: "#515151",
						margin: [15, 0, 0, 8],
						italics: true,
					},
				]
			},
			content: [
				{
					image: logoData,
					width: 800,
				},
				{
					text: "Тип расчета:  Расчет выбросов при наличии данных только по типам продукции.",
					fontSize: 11,
					margin: [2, 2],
				},
				{
					text: "Организация    " + company.value,
					fontSize: 11,
					margin: [5, 5],
				},
				{
					text:
						"Код ОНВ      " +
						onvCode.value +
						"     Отчетный период   " +
						period.value +
						"   " +
						year.value,
					fontSize: 11,
					margin: [5, 8],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text: store.state.activityType,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							text: store.state.productionType,
							margin: [10, 0, 0, 0],
							background: "#e8f0fe",
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text:
								store.state.production.l == "zero"
									? "-----"
									: store.state.production.l,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							columns: [
								{
									text:
										"Выпуск продукции тонн     " +
										thousandsTonnsProduction.value,
									margin: [10, 0, 0, 0],
								},
								{
									text:
										store.state.calcType == 1 ? "Доля стеклобоя %         " + stecloboyPath.value : "Кальцинированная сода, тонн    " +
										stecloboyPath.value,
									margin: [10, 0, 0, 0],
								},
							],
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text: "Тип цемента: " + cementType.value,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							columns: [
								{
									text:
										"Фракция клинкера в цементе типа i, дробь     " +
										klinkerFraction.value,
									margin: [10, 0, 0, 0],
								},
								{
									text:
										"Коэффициент выбросов для клинкера в конкретном цементе, тонны CO2/тонну клинкера        " +
										klinkerKoeff.value,
									margin: [10, 0, 0, 0],
								},
							],
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "ТИмпорт клинкера для потребления, тонны",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: klinkerImport.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Экспорт клинкера, тонны",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: klinkerExport.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Выбросы CO2 от производства цемента, тонны",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: cementCo2.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Удельный показатель выбросов, т. СО2-экв./т.",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: udelCheckVybr.value },
					],
				},
			],
		}
		pdfMake
			.createPdf(dd, null, pdfMake.fonts)
			.download(company.value + ".pdf")
	}
    
	return {
		onSubmit,
		company,
		companyError,
		companyBlur,
		onvCode,
		onvCodeError,
		onvCodeBlur,
		period,
		periodError,
		periodChange,
		year,
		yearError,
		yearBlur,
		thousandsTonnsProduction,
		thousandsTonnsProductionError,
		stecloboyPath,
		stecloboyPathError,
		klinkerImport,
		klinkerImportError,
		klinkerExport,
		klinkerExportError,
		// energoVybross,
		// summVybross,
		udelCheckVybr,
		udelCheckVybrError,
		calculate,
		productionTypeInclArray,
		onChangePT,
		onChangePTandVP,
		generatePdfFile,
		cementData,
        cementType, cementTypeError, klinkerFraction, klinkerFractionError, setValues, klinkerKoeff, klinkerKoeffError, cementCo2, cementCo2Error,
		udelPokVybr, koefUdelVybr, excess
	}
}