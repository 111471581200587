import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/dist/genesis/theme.css'
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.bundle.min"
import "bootstrap"
// import ploader from "vue-ui-preloader";
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue3'

function onMathJaxReady() {
    // The parent node of need to be rendered into the formula node set
    const el = document.getElementById('elementId')
    // ❗️❗️ When there is no el will begin to render the default root node
    renderByMathjax(el)
  }
  
  initMathJax({}, onMathJaxReady)

createApp(App).use(store).use(router).use(MathJax).use(plugin, defaultConfig).mount('#app')
