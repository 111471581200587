<template>
    <div class="result_container table-">
        <div class="table-responsive">
            <table class="table table-success table-bordered align-middle">
                <ResultTableHeaderComponent :years="years" :header="'Абсолютные валовые выбросы СО<sub>2</sub>, т'" />
                <tbody>
                    <ResultTableRowComponent :years="years" :formula="'E^*_{CO_{2},прям.}'"
                        :name="'Прямые выбросы в границах производства (передела) без учета вторичных топливных газов, т'"
                        :volume="inpEco2pryamV" :middle="midInpEco2pryamV" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},электр.}'"
                        :name="'Выбросы, связанные с электроэнергией, т СО<sub>2</sub>'" :volume="inpEcoElectro"
                        :middle="midInpEcoElectro" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},тепл.}'"
                        :name="'Выбросы, связанные с тепловой энергией, т СО<sub>2</sub>'" :volume="inpEcoTeploElectro"
                        :middle="midInpEcoTeploElectro" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},тех.газы}'"
                        :name="'Выбросы, связанные с техническими газами и дутьем, т СО<sub>2</sub>'"
                        :volume="inpEcoTechGas" :middle="midInpEcoTechGas" />

                    <ResultTableRowComponent :years="years" :formula="'\\Delta E_{CO_{2},втор.газы}'"
                        :name="'Поправка к прямым выбросам на вторичные топливные газы, т СО<sub>2</sub>'"
                        :volume="inpEcoPopravka" :middle="midInpEcoPopravka" />

                    <ResultTableRowComponent :years="years" :formula="''" :name="'Валовые выбросы, т СО<sub>2</sub>'"
                        :volume="inpValVybr" :middle="midInpValVybr" />

                </tbody>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table table-success table-bordered align-middle asasas">
                <ResultTableHeaderComponent :years="years" :header="'Удельные выбросы СО<sub>2</sub>, т / т продукции'" />
                <tbody>
                    <ResultTableRowComponent :years="years" :formula="'E^*_{CO_{2},прям.}'"
                        :name="'Удельные прямые выбросы в границах производства (передела) без учета вторичных топливных газов'"
                        :volume="inpUdelEco2pryamV" :middle="midInpUdelEco2pryamV" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},электр.}'"
                        :name="'Удельные выбросы, связанные с электроэнергией'" :volume="inpUdelEcopryamVelectro"
                        :middle="midInpUdelEcopryamVelectro" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},тепл.}'"
                        :name="'Удельные выбросы, связанные с тепловой энергией'" :volume="inpUdelEcoTeploElectro"
                        :middle="midInpUdelEcoTeploElectro" />

                    <ResultTableRowComponent :years="years" :formula="'E_{CO_{2},тех.газы}'"
                        :name="'Удельные выбросы, связанные с техническими газами и дутьем'" :volume="inpUdelEcoTechGas"
                        :middle="midInpUdelEcoTechGas" />

                    <ResultTableRowComponent :years="years" :formula="'\\Delta E_{CO_{2},втор.газы}'"
                        :name="'Удельная поправка к прямым выбросам на вторичные топливные газы'"
                        :volume="inpUdelEcoPopravka" :middle="midInpUdelEcoPopravka" />

                    <ResultTableRowComponent :years="years" :formula="''"
                        :name="'Интенсивность выбросов (бенчмарк) для определенного вида металлургической продукции'"
                        :volume="intensVybr" :middle="midIntensVybr" />

                </tbody>
            </table>
        </div>
        <div class="btn_docx_div mb-5">
            <div class="btn btn-success" @click="exportDocx">Сохранить в Docx</div>
        </div>
        <!-- если надо скрыть график -->
        <!-- <div style="opacity: 0"> -->
        <div>
            <Bar :chart-data="chartData" :chart-options="chartOptions" :width="width1" :height="height1"
                ref="BarMainChart" />
        </div>
        <!-- <DocxComponent /> -->
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, toRef } from "vue"
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, annotationPlugin)
// ChartJS.register(annotationPlugin);

import {
    Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, PageOrientation, Table, TableCell, TableRow,
    Math, MathRun, MathSubScript, MathSubSuperScript, ImageRun, WidthType
} from "docx";
import { saveAs } from 'file-saver'; // you can use this also
import { indicators } from '@/use/indicators.js'
import ResultTableHeaderComponent from './ResultTableHeaderComponent.vue'
import ResultTableRowComponent from './ResultTableRowComponent.vue'
export default {
    components: {
        Bar, ResultTableHeaderComponent, ResultTableRowComponent
    },
    props: {
        data: Object
    },
    setup(props) {

        const chartData = ref({})
        const chartOptions = ref({})
        const width1 = ref(800)
        const height1 = ref(400)


        const store = useStore()
        const process = store.state.productionType
        const years = store.state.firstPage.year.sort()
        // const results = ref(true)
        const data = toRef(props, 'data')
        let forDoc = ref({})

        const inpEco2pryamV = ref({})
        const inpEcoElectro = ref({})
        const inpEcoTeploElectro = ref({})//третья строка нижней таблицы
        const inpEcoTechGas = ref({})//четвертая строка нижней таблицы
        const inpEcoPopravka = ref({})//пятая строка нижней таблицы
        const inpValVybr = ref({})//шестая строка нижней таблицы

        const inpUdelEco2pryamV = ref({})//первая строка нижней таблицы
        const inpUdelEcopryamVelectro = ref({})
        const inpUdelEcoTeploElectro = ref({})
        const inpUdelEcoTechGas = ref({})
        const inpUdelEcoPopravka = ref({})
        const intensVybr = ref({})

        const midInpEco2pryamV = ref(0)
        const midInpEcoElectro = ref(0)
        const midInpEcoTeploElectro = ref(0)
        const midInpEcoTechGas = ref(0)
        const midInpEcoPopravka = ref(0)
        const midInpValVybr = ref(0)
        const midInpUdelEco2pryamV = ref(0)
        const midInpUdelEcopryamVelectro = ref(0)
        const midInpUdelEcoTeploElectro = ref(0)
        const midInpUdelEcoTechGas = ref(0)
        const midInpUdelEcoPopravka = ref(0)
        const midIntensVybr = ref(0)

        for (let i = 0; i < years.length; i++) {
            inpEco2pryamV.value[i] = 0
            inpEcoElectro.value[i] = 0
            inpEcoTeploElectro.value[i] = 0
            inpEcoTechGas.value[i] = 0
            inpEcoPopravka.value[i] = 0
            inpValVybr.value[i] = 0
            inpUdelEco2pryamV.value[i] = 0
            inpUdelEcopryamVelectro.value[i] = 0
            inpUdelEcoTeploElectro.value[i] = 0
            inpUdelEcoTechGas.value[i] = 0
            inpUdelEcoPopravka.value[i] = 0
            intensVybr.value[i] = 0
        }
        getData(data.value)
        function getData(event) {
            forDoc.value = event
            const data = event
            for (let key in data.CO2pryamVybr) {
                for (let k in data.CO2pryamVybr[key].volumeAbs) {
                    inpEco2pryamV.value[k] += data.CO2pryamVybr[key].volumeAbs[k]
                }
            }
            for (let key in data.elVybr) {
                for (let k in data.elVybr[key].volumeAbs) {
                    inpEcoElectro.value[k] += data.elVybr[key].volumeAbs[k]
                    // inpValVybr.value[k] += inpEcoElectro.value[k]
                }
            }
            for (let key in data.tepVybr) {
                for (let k in data.tepVybr[key].volumeAbs) {
                    inpEcoTeploElectro.value[k] += data.tepVybr[key].volumeAbs[k]
                    // inpValVybr.value[k] += inpEcoTeploElectro.value[k]
                }
            }
            for (let key in data.techGasVybr) {
                for (let k in data.techGasVybr[key].volumeAbs) {
                    inpEcoTechGas.value[k] += data.techGasVybr[key].volumeAbs[k]
                    // inpValVybr.value[k] += inpEcoTechGas.value[k]
                }
            }
            for (let key in data.popravka) {
                for (let k in data.popravka[key].volumeAbs) {
                    inpEcoPopravka.value[k] += data.popravka[key].volumeAbs[k]
                    // inpValVybr.value[k] += inpEcoPopravka.value[k]
                }
            }
            for (let k = 0; k < years.length; k++) {
                inpValVybr.value[k] = inpEco2pryamV.value[k] + inpEcoElectro.value[k] + inpEcoTeploElectro.value[k] + inpEcoTechGas.value[k] + inpEcoPopravka.value[k]
            }
            for (let key in years) {
                midInpEco2pryamV.value += inpEco2pryamV.value[key]
                midInpEcoElectro.value += inpEcoElectro.value[key]
                midInpEcoTeploElectro.value += inpEcoTeploElectro.value[key]
                midInpEcoTechGas.value += inpEcoTechGas.value[key]
                midInpEcoPopravka.value += inpEcoPopravka.value[key]
                midInpValVybr.value += inpValVybr.value[key]
            }
            midInpEco2pryamV.value = midInpEco2pryamV.value / years.length
            midInpEcoElectro.value = midInpEcoElectro.value / years.length
            midInpEcoTeploElectro.value = midInpEcoTeploElectro.value / years.length
            midInpEcoTechGas.value = midInpEcoTechGas.value / years.length
            midInpEcoPopravka.value = midInpEcoPopravka.value / years.length
            midInpValVybr.value = midInpValVybr.value / years.length
            for (let obj in data.productWeight) {
                for (let key in data.productWeight[obj].volume) {
                    inpUdelEco2pryamV.value[key] = data.productWeight[obj].volume[key] == 0 ? 0 : inpEco2pryamV.value[key] / data.productWeight[obj].volume[key]
                    midInpUdelEco2pryamV.value += inpUdelEco2pryamV.value[key]
                    inpUdelEcopryamVelectro.value[key] = data.productWeight[obj].volume[key] == 0 ? 0 : inpEcoElectro.value[key] / data.productWeight[obj].volume[key]
                    midInpUdelEcopryamVelectro.value += inpUdelEcopryamVelectro.value[key]
                    inpUdelEcoTeploElectro.value[key] = data.productWeight[obj].volume[key] == 0 ? 0 : inpEcoTeploElectro.value[key] / data.productWeight[obj].volume[key]
                    midInpUdelEcoTeploElectro.value += inpUdelEcoTeploElectro.value[key]
                    inpUdelEcoTechGas.value[key] = data.productWeight[obj].volume[key] == 0 ? 0 : inpEcoTechGas.value[key] / data.productWeight[obj].volume[key]
                    midInpUdelEcoTechGas.value += inpUdelEcoTechGas.value[key]
                    inpUdelEcoPopravka.value[key] = data.productWeight[obj].volume[key] == 0 ? 0 : inpEcoPopravka.value[key] / data.productWeight[obj].volume[key]
                    midInpUdelEcoPopravka.value += inpUdelEcoPopravka.value[key]
                    intensVybr.value[key] = inpUdelEco2pryamV.value[key] + inpUdelEcopryamVelectro.value[key] + inpUdelEcoTeploElectro.value[key] + inpUdelEcoTechGas.value[key] + inpUdelEcoPopravka.value[key]
                    midIntensVybr.value += intensVybr.value[key]
                }
            }
            midInpUdelEco2pryamV.value = midInpUdelEco2pryamV.value / years.length
            midInpUdelEcopryamVelectro.value = midInpUdelEcopryamVelectro.value / years.length
            midInpUdelEcoTeploElectro.value = midInpUdelEcoTeploElectro.value / years.length
            midInpUdelEcoTechGas.value = midInpUdelEcoTechGas.value / years.length
            midInpUdelEcoPopravka.value = midInpUdelEcoPopravka.value / years.length
            midIntensVybr.value = midIntensVybr.value / years.length
            // results.value = data.showResults

            let lab = Object.values(years)//labels для графика
            lab.push("Среднее")
            let dat = Object.values(intensVybr.value)//данные для графика
            dat.push(midIntensVybr.value)

            var gColors = []//массив для цветов графика
            for (let i = 0; i < dat.length; i++) {
                if (i == dat.length - 1) {
                    gColors.push('#0559C9')
                } else {
                    gColors.push('#72A6FB')
                }
            }

            chartData.value = {
                labels: lab,
                datasets: [
                    {
                        // backgroundColor: '#72A5FB',
                        backgroundColor: gColors,
                        data: dat
                    }
                ]
            }
            chartOptions.value = {
                barThickness: 50,
                plugins: {
                    legend: {
                        display: false,
                    },
                    autocolors: false,
                    annotation: {
                        annotations: {//две горизонтальные линии на графике
                            line1: {
                                type: 'line',
                                yMin: indicators[process].min,
                                yMax: indicators[process].min,
                                borderColor: "#00b050",
                                borderWidth: 2,
                                label: {
                                    content: indicators[process].min + " (ИП 2)",
                                    display: true,
                                    color: "#00b050",
                                    height: 50
                                }
                            },
                            line2: {
                                type: 'line',
                                yMin: indicators[process].max,
                                yMax: indicators[process].max,
                                borderColor: "#ff7f27",
                                borderWidth: 2,
                                label: {
                                    content: indicators[process].max + " (ИП 1)",
                                    display: true,
                                    color: "#ff7f27"
                                }
                            }
                        }
                    }
                }
            }
        }
        // docx
        const exportDocx = () => {//построение docx файла 

            const canvas = document.getElementById('bar-chart');//элемент канвас с графиком
            const dataURL = canvas.toDataURL();//получаем ссылку на файл графика

            // Create a new Document an save it in a variable
            const doc = new Document({
                styles: {
                    paragraphStyles: [
                        {
                            id: "usualPar",
                            name: "My Paragraf Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 22
                            },
                            paragraph: {
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                indent: {
                                    left: 420,
                                },
                            },
                        },
                        {
                            id: "tabCellStyle",
                            name: "Table Cell Style",
                            basedOn: "Normal",
                            next: "Normal",
                            quickFormat: true,
                            run: {
                                color: "000000",
                                size: 16,
                            },
                            paragraph: {
                                alignment: AlignmentType.CENTER,
                            }
                        }
                    ]
                },
                sections: [
                    {
                        properties: {
                            page: {
                                size: {
                                    orientation: PageOrientation.LANDSCAPE,
                                },
                                margin: {
                                    top: "0.5in",
                                    bottom: "0.5in",
                                    left: "0.8in",
                                    right: "0.8in"
                                }
                            },
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет выбросов ПГ для предприятий отрасли черной металлургии",
                                        bold: true,
                                        size: 32,
                                        color: "000000",
                                    })
                                ],
                                heading: HeadingLevel.HEADING_2,
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 0,
                                    after: 300
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Объект:  ",
                                        bold: true,
                                        size: 28,
                                        color: "000000",
                                    }),
                                    new TextRun({
                                        text: store.state.firstPage.company_name,
                                        size: 28,
                                        color: "000000",
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Код ОНВ:  ",
                                        bold: true,
                                        size: 28,
                                        color: "000000",
                                    }),
                                    new TextRun({
                                        text: store.state.firstPage.onv_code,
                                        size: 28,
                                        color: "000000",
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "1. Методика проведения расчета выбросов ПГ (СО₂)",
                                        bold: true,
                                        size: 28,
                                        color: "000000",
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                heading: HeadingLevel.HEADING_2,
                                alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет произведен по методике ГОСТ Р 113.26.01-2022. Наилучшие доступные технологии. Методические рекомендации по проведению бенчмаркинга удельных выбросов парниковых газов для отрасли черной металлургии",
                                        bold: true,
                                        size: 24,
                                        color: "000000",
                                    })
                                ],
                                heading: HeadingLevel.HEADING_3,
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет интенсивности выбросов СО₂ (бенчмарк) для производства продукции",
                                        italics: true,
                                        size: 22,
                                    }),
                                ],
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                indent: {
                                    left: 420,
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Расчет удельных выбросов СО₂ (интенсивности выбросов СО₂ (бенчмарк)) для производства продукции выполняется по формуле (1):",
                                        size: 22,
                                    }),
                                ],
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                                indent: {
                                    left: 420,
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("I")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                ],
                                            }),
                                            new MathRun(" = "),
                                            new MathSubSuperScript({
                                                children: [new MathRun("I")],
                                                superScript: [new MathRun("*")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",прям.")
                                                ],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",электр.")
                                                ],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тепл.")
                                                ],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тех.газы")
                                                ],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("∆E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",втор.газы")
                                                ],
                                            }),

                                        ],
                                    }),
                                    new TextRun(",    (1)"),
                                ],
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("I")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun(" – интенсивность выбросов (бенчмарк) для производства определенного вида металлургической продукции, т СО₂/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubSuperScript({
                                                children: [new MathRun("I")],
                                                superScript: [new MathRun("*")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",прям.")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельные прямые выбросы в границах производственного процесса (передела) без учета вторичных топливных газов, т СО₂/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",электр.")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельные выбросы, связанные с электроэнергией, т СО₂/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тепл.")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельные выбросы, связанные с тепловой энергией, т СО₂/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тех.газы")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельные выбросы, связанные с техническими газами и дутьем, т СО₂/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("∆E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",втор.газы")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельная поправка к прямым выбросам на вторичные топливные газы, т СО₂/т продукции."),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Удельные выбросы СО₂ определяются как валовые выбросы СО₂, отнесенные к объему основной произведенной продукции для каждого производственного процесса (передела)."),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с электроэнергией"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с электроэнергией, выполняется по формуле (3):"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",электр.")
                                                ],
                                            }),
                                            new MathRun(" = ("),

                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("потр.")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("выр.")],
                                            }),
                                            new MathRun(" )"),
                                            new MathRun(" × "),
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",электр.")
                                                ],
                                            }),

                                        ],
                                    }),
                                    new TextRun(",    (3)"),
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("потр.")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельное потребление электроэнергии в границах производственного процесса (передела), МВт∙ч/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("P")],
                                                subScript: [new MathRun("выр.")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельная выработка электроэнергии на источнике, находящемся в границах производственного процесса (передела), МВт∙ч/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",электр.")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – коэффициент выброса для электроэнергии, т СО₂/МВт∙ч."),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с тепловой энергией"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с тепловой энергией, выполняется по формуле (4):"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тепл.")
                                                ],
                                            }),
                                            new MathRun(" = ("),

                                            new MathSubScript({
                                                children: [new MathRun("Q")],
                                                subScript: [new MathRun("потр.")],
                                            }),
                                            new MathRun(" - "),
                                            new MathSubScript({
                                                children: [new MathRun("Q")],
                                                subScript: [new MathRun("выр.")],
                                            }),
                                            new MathRun(" )"),
                                            new MathRun(" × "),
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тепл.")
                                                ],
                                            }),

                                        ],
                                    }),
                                    new TextRun(",    (4)"),
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("Q")],
                                                subScript: [new MathRun("потр.")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельное потребление электроэнергии в границах производственного процесса (передела), МВт∙ч/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("Q")],
                                                subScript: [new MathRun("выр.")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельная выработка электроэнергии на источнике, находящемся в границах производственного процесса (передела), МВт∙ч/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тепл.")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – коэффициент выброса для электроэнергии, т СО₂/МВт∙ч."),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с техническими газами и дутьем"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельных выбросов СО₂, связанных с техническими газами и дутьем, выполняется по формуле (5):"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тех.газы")
                                                ],
                                            }),
                                            new MathRun(" = "),
                                            new MathRun("∑("),
                                            new MathSubScript({
                                                children: [new MathRun("G")],
                                                subScript: [new MathRun("i")],
                                            }),
                                            new MathRun(" × "),
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тех.газ,i")
                                                ],
                                            }),
                                            new MathRun(")"),
                                        ],
                                    }),
                                    new TextRun(",    (5)"),
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("G")],
                                                subScript: [new MathRun("i")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельное потребление i-технического газа, доменного дутья в границах производства, тыс. м³/т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",тех.газ,i")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – коэффициент выброса для i-технического газа, доменного дутья, т СО₂/тыс. м³."),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельной поправки к прямым выбросам СО₂ на вторичные топливные газы"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("Расчет удельной поправки к прямым выбросам СО₂ на вторичные топливные газы, выполняется по формуле (6):"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("∆E")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",втор.газы")
                                                ],
                                            }),
                                            new MathRun(" = "),
                                            new MathRun("∑[("),
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("потр.,i")],
                                            }),
                                            // new MathRun(" × "),
                                            new MathRun(" - "),
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("выр.,i")],
                                            }),
                                            new MathRun(" + "),
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("потери.,i")],
                                            }),
                                            new MathRun(")"),
                                            new MathRun(" × "),
                                            new MathSubScript({
                                                children: [new MathRun("ε")],
                                                subScript: [new MathRun("i")],
                                            }),
                                            new MathRun("]"),
                                            new MathRun(" × "),
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",прир.газ.")
                                                ],
                                            }),
                                        ],
                                    }),
                                    new TextRun(",    (6)"),
                                ],
                                style: "usualPar",
                                alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun("где:"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("EF")],
                                                subScript: [
                                                    new MathSubScript({
                                                        children: [new MathRun("CO")],
                                                        subScript: [
                                                            new MathRun("2")
                                                        ],
                                                    }),
                                                    new MathRun(",прир.газ")
                                                ],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – коэффициент выброса СО₂ для природного газа, т СО₂/т у.т;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("потр.,i")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельное потребление i-го вторичного топливного газа в границах производственного процесса (передела), т у.т./т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("выр.,i")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельная выработка (образование) i-го вторичного топливного газа в границах производственного процесса (передела), т у.т./т продукции;"),
                                ],
                                style: "usualPar"
                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("F")],
                                                subScript: [new MathRun("потери.,i")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – удельные потери i-го вторичного топливного газа в границах предприятия, включая сжигание на свечах, рассеивание и утечки, т у.т./т продукции;"),
                                ],
                                style: "usualPar"

                            }),
                            new Paragraph({
                                children: [
                                    new Math({
                                        children: [
                                            new MathSubScript({
                                                children: [new MathRun("ε")],
                                                subScript: [new MathRun("i")],
                                            }),
                                        ]
                                    }),
                                    new TextRun("  – показатель эффективности сжигания i-го вторичного топливного газа в сравнении со сжиганием природного газа, доля."),
                                ],
                                style: "usualPar"

                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "2. Исходные данные и результаты проведения расчета выбросов ПГ (СО₂)",
                                        bold: true,
                                        color: "000000",
                                        size: 28
                                    })
                                ],
                                heading: HeadingLevel.HEADING_3,
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: process,
                                        bold: true,
                                        color: "000000",
                                        size: 36
                                    })
                                ],
                                heading: HeadingLevel.HEADING_3,
                                spacing: {
                                    before: 240,
                                    after: 240
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Входные и выходные потоки ресурсов, показатели выбросов СО₂ по видам ресурсов",
                                        bold: true,
                                        italics: true,
                                        color: "000000",
                                        size: 22
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 20
                                },
                            }),
                            //table
                            new Table({
                                // const tableOneRows = [],
                                rows: makeTableRows(),//TODO переделать по новому
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Итоговые абсолютные валовые выбросы СО₂ по производственному процессу (переделу), т СО₂",
                                        bold: true,
                                        italics: true,
                                        color: "000000",
                                        size: 22
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 20
                                },
                            }),
                            new Table({
                                alignment: AlignmentType.LEFT,
                                width: {
                                    size: 80,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: makeSecondTableRows(),//TODO переделать по новому
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Итоговые удельные выбросы СО₂ по производственному процессу (переделу), т СО₂/ т продукции",
                                        bold: true,
                                        italics: true,
                                        color: "000000",
                                        size: 22
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 20
                                },
                            }),
                            new Table({
                                alignment: AlignmentType.LEFT,
                                width: {
                                    size: 80,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: makeThirdTableRows(),
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Индикативные показатели удельных выбросов парниковых газов в соответствии с ИТС 26 «Производство чугуна, стали и ферросплавов»",
                                        bold: true,
                                        italics: true,
                                        color: "000000",
                                        size: 22
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 20
                                },
                            }),
                            new Table({
                                alignment: AlignmentType.LEFT,
                                width: {
                                    size: 80,
                                    type: WidthType.PERCENTAGE,
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Производственный процесс (передел)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                rowSpan: 2
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Индикативный показатель удельных выбросов парниковых газов, т СО₂-экв./ т продукции"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ],
                                                columnSpan: 2
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Нижний уровень индикативного показателя (ИП 2)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ]
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Верхний уровень индикативного показателя (ИП 1)"
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ]
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: process
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ]
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: String(indicators[process].min)
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ]
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: String(indicators[process].max)
                                                            })
                                                        ],
                                                        style: "tabCellStyle"
                                                    })
                                                ]
                                            }),
                                        ]
                                    })
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Показатели удельных выбросов СО₂, т / т продукции Объекта ОНВ на графике",
                                        bold: true,
                                        italics: true,
                                        color: "000000",
                                        size: 22
                                    })
                                ],
                                spacing: {
                                    before: 240,
                                    after: 20
                                },
                            }),
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: dataURL,
                                        transformation: {
                                            width: 900,
                                            height: 450,
                                        },
                                    })
                                ]
                            })
                        ],
                    },
                ],
            });
            const mimeType =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            const fileName = "test.docx";
            Packer.toBlob(doc).then((blob) => {
                const docblob = blob.slice(0, blob.size, mimeType);
                saveAs(docblob, fileName);
            });
        }
        function makeThirdTableRows() {
            const arr = []
            arr.push(new TableRow({
                children: makeSecondTableHeader()
            }))
            arr.push(new TableRow({
                children: strUdelPryamVybr()
            }))
            arr.push(new TableRow({
                children: strUdelElEnergy()
            }))
            arr.push(new TableRow({
                children: strUdelTeploEnergy()
            }))
            arr.push(new TableRow({
                children: strUdelTechGases()
            }))
            arr.push(new TableRow({
                children: strUdelPopravka()
            }))
            arr.push(new TableRow({
                children: strUdelValVybr()
            }))
            return arr
        }
        function strUdelValVybr() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("I")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ]
                                            })
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Интенсивность выбросов (бенчмарк) для определенного вида металлургической продукции"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, intensVybr.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midIntensVybr.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strUdelPopravka() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("∆E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",втор.газы")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Удельная поправка к прямым выбросам на вторичные топливные газы"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpUdelEcoPopravka.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpUdelEcoPopravka.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strUdelTechGases() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тех.газы")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Удельные выбросы, связанные с техническими газами и дутьем"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpUdelEcoTechGas.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpUdelEcoTechGas.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strUdelTeploEnergy() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Удельные выбросы, связанные с тепловой энергией"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpUdelEcoTeploElectro.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpUdelEcoTeploElectro.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strUdelElEnergy() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Удельные выбросы, связанные с электроэнергией"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpUdelEcopryamVelectro.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpUdelEcopryamVelectro.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))

            return arr
        }
        function strUdelPryamVybr() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubSuperScript({
                                        children: [new MathRun("E")],
                                        superScript: [new MathRun("*")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Удельные прямые выбросы в границах производства (передела) без учета вторичных топливных газов"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpUdelEco2pryamV.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpUdelEco2pryamV.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))

            return arr
        }

        function makeSecondTableRows() {
            const arr = []
            arr.push(new TableRow({
                children: makeSecondTableHeader()
            }))
            arr.push(new TableRow({
                children: strPryamVybr()
            }))
            arr.push(new TableRow({
                children: strElEnergy()
            }))
            arr.push(new TableRow({
                children: strTeploEnergy()
            }))
            arr.push(new TableRow({
                children: strTechGases()
            }))
            arr.push(new TableRow({
                children: strPopravka()
            }))
            arr.push(new TableRow({
                children: strValVybr()
            }))
            return arr
        }
        function strValVybr() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph("")
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Валовые выбросы"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpValVybr.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpValVybr.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strPopravka() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("∆E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",втор.газы")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Поправка к прямым выбросам на вторичные топливные газы"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpEcoPopravka.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpEcoPopravka.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strTechGases() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тех.газы")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Выбросы, связанные с техническими газами и дутьем"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpEcoTechGas.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpEcoTechGas.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strTeploEnergy() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Выбросы, связанные с тепловой энергией"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpEcoTeploElectro.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpEcoTeploElectro.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            return arr
        }
        function strElEnergy() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Выбросы, связанные с электроэнергией"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpEcoElectro.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpEcoElectro.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))

            return arr
        }
        function strPryamVybr() {
            const arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubSuperScript({
                                        children: [new MathRun("E")],
                                        superScript: [new MathRun("*")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    })
                                ]
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Прямые выбросы в границах производства (передела) без учета вторичных топливных газов"
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))
            getCellsByYearsAndParam(arr, inpEco2pryamV.value)
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: midInpEco2pryamV.value.toFixed(3)
                            })
                        ],
                        style: "tabCellStyle"
                    })
                ]
            }))

            return arr
        }
        function getCellsByYearsAndParam(arr, param) {
            for (let i = 0; i < years.length; i++) {
                arr.push(new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: param[i].toFixed(3)
                                })
                            ],
                            style: "tabCellStyle"
                        })
                    ]
                }))
            }
        }
        function makeSecondTableHeader() {
            let arr = []
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "",
                                bold: true
                            })
                        ],
                        style: "tabCellStyle"
                    }),
                ]
            }))
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "",
                                bold: true
                            })
                        ],
                        style: "tabCellStyle"
                    }),
                ]
            }))
            for (let i = 0; i < years.length; i++) {
                arr.push(new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: String(years[i]),
                                    bold: true
                                })
                            ],
                            style: "tabCellStyle"
                        }),
                    ]
                }))
            }
            arr.push(new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Среднее",
                                bold: true
                            })
                        ],
                        style: "tabCellStyle"
                    }),
                ]
            }))
            return arr
        }

        function makeTableRows() {
            const arr = []
            arr.push(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Ресурсы",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            }),
                        ],
                        columnSpan: 2,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Единицы измерения",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Суммарный израсходованный объем",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        columnSpan: years.length,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Содержание углерода, т С/ед.",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Коэффициент выбросов, т СО₂/ед.",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Абсолютные валовые выбросы СО₂, т",
                                        bold: true
                                    })
                                ],
                                style: "tabCellStyle"
                            })
                        ],
                        columnSpan: years.length,
                    }),
                ],
            }))
            arr.push(new TableRow({
                children: makeYearsCells(),
            }))
            arr.push(new TableRow({
                children: [new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Входной поток",
                                    italics: true
                                })
                            ],
                            style: "tabCellStyle",
                            alignment: AlignmentType.LEFT
                        })
                    ],
                    columnSpan: years.length * 2 + 5
                })]
            }))
            for (let n = 0; n < forDoc.value.input.length; n++) {
                arr.push(new TableRow({
                    children: createTableRow(forDoc.value.input[n])
                }))
            }

            arr.push(new TableRow({
                children: [new TableCell({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: "Выходной поток",
                                    italics: true
                                })
                            ],
                            style: "tabCellStyle",
                            alignment: AlignmentType.LEFT
                        })
                    ],
                    columnSpan: years.length * 2 + 5
                })]
            }))
            for (let m = 0; m < forDoc.value.output.length; m++) {
                arr.push(new TableRow({
                    children: createTableRow(forDoc.value.output[m])
                }))
            }
            return arr
        }
        function makeYearsCells() {
            const arr = []
            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: "Тип ресурса",
                        })
                    ],
                    style: "tabCellStyle"
                })],
            }))
            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: "Вид ресурса",
                        })
                    ],
                    style: "tabCellStyle"
                })],
            }))
            for (let i = 0; i < years.length; i++) {
                arr.push(new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: String(years[i]),
                            })
                        ],
                        style: "tabCellStyle"
                    })],
                }))
            }
            for (let i = 0; i < years.length; i++) {
                arr.push(new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: String(years[i]),
                            })
                        ],
                        style: "tabCellStyle"
                    })],
                }))
            }
            return arr
        }
        function createTableRow(obj) {
            let arr = []
            if (obj.span) {
                arr.push(new TableCell({
                    children: [new Paragraph({
                        children: [new TextRun({
                            text: obj.name
                        })],
                        style: "tabCellStyle"
                    })],
                    rowSpan: obj.span,

                }))
            }

            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [new TextRun({
                        text: obj.subName
                    })],
                    style: "tabCellStyle"
                })]
            }))
            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [new TextRun({
                        text: obj.units
                    })],
                    style: "tabCellStyle"
                })]
            }))
            for (let j = 0; j < years.length; j++) {
                arr.push(new TableCell({
                    children: [new Paragraph({
                        children: [new TextRun({
                            text: String(obj.volume[j])
                        })],
                        style: "tabCellStyle"
                    })]
                }))
            }
            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [new TextRun({
                        text: String(Number(obj.volumeCO).toFixed(4))
                    })],
                    style: "tabCellStyle"
                })]
            }))
            arr.push(new TableCell({
                children: [new Paragraph({
                    children: [new TextRun({
                        text: String(obj.volumeK.toFixed(3))
                    })],
                    style: "tabCellStyle"
                })]
            }))
            for (let j = 0; j < years.length; j++) {
                arr.push(new TableCell({
                    children: [new Paragraph({
                        children: [new TextRun({
                            text: String(obj.volumeAbs[j].toFixed(2))
                        })],
                        style: "tabCellStyle"
                    })]
                }))
            }
            return arr
        }
        //end docx
        return {
            getData, inpEco2pryamV, years,
            inpEcoElectro, inpEcoTeploElectro, inpEcoTechGas, inpEcoPopravka, inpValVybr,
            midInpEco2pryamV, midInpEcoElectro, midInpEcoTeploElectro, midInpEcoTechGas, midInpEcoPopravka, midInpValVybr,
            inpUdelEco2pryamV, midInpUdelEco2pryamV, data, inpUdelEcopryamVelectro, inpUdelEcoTeploElectro, inpUdelEcoTechGas, inpUdelEcoPopravka, intensVybr,
            midInpUdelEcopryamVelectro, midInpUdelEcoTeploElectro, midInpUdelEcoTechGas, midInpUdelEcoPopravka, midIntensVybr,
            chartData, chartOptions, width1, height1, exportDocx
        }
    }
}
</script>

<style scoped>
.result_container {
    width: 60%;
    margin: 0 auto;
    margin-top: 3rem;
}

@media (max-width: 1250px) {
    .result_container {
        width: 98%
    }
}
</style>