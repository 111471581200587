import axios from "axios"
import { environment } from './api_url';

class AuthService {
	login(user) {
		return axios
			.post(environment.apihost + "login.php", {
				email: user.email,
				password: user.password,
			})
			.then((response) => {
				if (response.data.jwt) {
					localStorage.setItem("user", JSON.stringify(response.data))
				}

				return response.data
			})
	}

	logout() {
		localStorage.removeItem("user")
	}

	register(user) {
		return axios.post(environment.apihost + "register.php", {
			username: user.username,
			email: user.email,
			password: user.password,
		})
	}

	passwordReset(user) {
		return axios.post(environment.apihost + "password-reset.php", {
			email: user.email,
		})
	}

	checkHash(hash) {
		return axios.post(environment.apihost + "check-hash.php", {
			hash: hash
		})
	}

	changePassword(password) {
		return axios.post(environment.apihost + "set-new-password.php", {
			password: password.password,
			password_confirm: password.password_confirm,
			hash: password.hash
		})
	}
}

export default new AuthService()
