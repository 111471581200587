<template>
        <tr>
            <td>
                <math-jax :latex="formula" :block="true"></math-jax>
            </td>
            <td v-html="name"></td>
            <td v-for="(year, index) in years" :key="year">{{
                makeString(parseFloat(volume[index]).toFixed(3))
            }}
            </td>
            <td>{{ makeString(parseFloat(middle).toFixed(3)) }}</td>
        </tr>
</template>

<script>
import { colorMetalFunctions } from "@/use/colorMetFunctions";

export default {
    name: 'ResultTableRowComponent',
    props: {
        years: Array,
        formula: String,
        name: String,
        volume: Object,
        middle: Number
    },

    setup(props) {
        const { arrayMiddle, makeString } = colorMetalFunctions()
        const years = props.years
        const formula = props.formula
        const name = props.name
        const volume = props.volume
        const middle = props.middle
        return {
            years, formula, name, volume, middle, makeString
        }
    }
}

</script>