<template>
    <div class="fon">
        <div class="bd-main">
            <div class="row justify-content-center mt-5">
                <div class="col-md-6">
                    <div class="my-form">
                        <h3>Введите данные</h3>
                        <Form @submit="handleRegister" :validation-schema="schema">
                            <div v-if="!successful">
                                <div class="form-group mb-3">
                                    <!-- <label for="username">ФИО</label> -->
                                    <Field name="username" type="text" class="form-el form-control" placeholder="ФИО" />
                                    <div class="e-feed">
                                        <ErrorMessage name="username" class="error-feedback mb-3" />
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <!-- <label for="email">Email</label> -->
                                    <Field name="email" type="email" class="form-el form-control" placeholder="Email" />
                                    <div class="e-feed">
                                        <ErrorMessage name="email" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label for="password">Пароль</label> -->
                                    <Field name="password" type="password" class="form-el form-control"
                                        placeholder="Пароль" />
                                    <div class="e-feed">
                                        <ErrorMessage name="password" class="error-feedback" />
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <button class="btn my-btn" :disabled="loading">
                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                        Регистрация
                                    </button>
                                    <div style="color: white; text-align: right" class="me-2">
                                        <router-link to="/login">
                                            <p class="perehod">вход</p>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </Form>

                        <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
                            <p v-if="successful">{{ message }} <router-link to="/login">Страница входа</router-link></p>
                            <p v-else>{{ message }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-bottom bottommm">
            <img src="/img/logo/acyr_logo.png">
        </div>
    </div>
</template>

<script>
import * as yup from "yup"
import { Field, Form, ErrorMessage, useForm } from "vee-validate"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { ref, onMounted, computed } from "vue"

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    setup() {
        const router = useRouter()
        const { handleSubmit, isSubmitting, submitCount } = useForm()
        const store = useStore()
        const successful = ref(false)
        const loading = ref(false)
        const message = ref("")
        const schema = yup.object().shape({
            username: yup
                .string()
                .required("ФИО обязательно!")
                .min(3, "Не менее трех символов!")
                .max(50, "Максимум 50 символов!"),
            email: yup
                .string()
                .required("Email обязательно!")
                .email("Email некорректен!")
                .max(50, "Максимум 50 символов!"),
            password: yup
                .string()
                .required("Пароль обязателен!")
                .min(6, "Минимум 6 символов!")
                .max(40, "Максимум 40 символов!"),
        });

        const loggedIn = computed(() => {
            return store.state.auth.status.loggedIn;
        })

        onMounted(() => {
            if (loggedIn.value) {
                router.push("/");
            }
        })

        function handleRegister(user) {
            message.value = ""
            successful.value = ""
            loading.value = true
            store.dispatch("auth/register", user).then(
                (data) => {
                    message.value = data.message
                    successful.value = true
                    loading.value = false
                },
                (error) => {
                    message.value = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    successful.value = false
                    loading.value = false
                }
            )
        }

        return {
            schema, handleRegister, successful, loading, message
        }
    },
};

</script>

<style scoped>
.fon {
    background: linear-gradient(to right, #295575, #4CA6C6, #75DFB2) !important;
    min-height: 100vh;
    margin-top: -20px;
}

.bd-main {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
}

label {
    text-align: left;
}

.error-feedback {
    color: red;
    font-size: 12px;
    /* float: left; */
    margin-left: 0.5rem;
}

.bottommm {
    text-align: left;
}

.my-form h3 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    align-items: center;
}

.form-el {
    /* width: 500px; */
    background: transparent;
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 7px;
    /* margin-bottom: 10px; */
    color: #fff;
    outline: none;
}

.my-btn {
    background: #fff;
    border: 0;
    padding: 8px;
    border-radius: 10px;
    color: #4ea5bb;
    font-size: 24px;
    margin: 10px 0 20px 0;
    border: 2px solid #fff;
    width: 180px;
}

.my-btn:hover {
    cursor: pointer;
    background: transparent;
    color: #fff;
}

.perehod {
    color: white;
    text-decoration: underline;
}

.perehod:hover {
    text-decoration: none;
    color: rgb(245, 227, 66);
}

::placeholder {
    color: white !important;
}

.e-feed {
    text-align: left;
}
@media screen and (max-width : 700px) {
   .bd-main{
    width: 96%;
   }
   .form-el{
    width: 100%;
   }
   .info_servies span{
    font-size: 22px;
   }
}
</style>