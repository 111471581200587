<template>
    <div class="container-years">
        <!-- <p class="h6">Выпуск алюминия (электролитического) M<sub>al</sub>,  т / год</p> -->
        <div class="sub-container">
            
            <FormKit type="form" submit-label="Далее" @submit="sendData" :actions="false" id="weight-form"
            incomplete-message="Не все поля заполнены правильно!">
                <p class="h5 text-start mt-2">Выпуск алюминия (электролитического) &nbsp; M<sub>al</sub> &nbsp;  т / год</p>
                <table>
                    <!-- <tr>
                        <th v-for="year in years" :key="year">{{ year }} год</th>
                        <th>Среднее значение</th>
                    </tr> -->
                    <tr class="align-top">
                        <td v-for="(year, index) in years" :key="index" class="px-1">
                            <FormKit type="number" step="0.00001" validation="required|number|min:0.1"
                                :name="String(year)"
                                :validation-messages="{ required: 'Поле обязательно', number: 'Должно быть число', min: 'Число должно быть больше 0' }"
                                :help="year + ' год'" validation-visibility="dirty"
                                v-model="alWeight[index]" @input="emitDatas" :help-class="{
                                  'formkit-help': false,
                                  'helpers': true
                                }" />
                        </td>
                        <td>
                            <FormKit type="number" step="0.01" name="averageAlWeight" disabled v-model="middleWeight"
                                ignore="true" help="Среднее значение" :help-class="{
                                  'formkit-help': false,
                                  'helpers': true
                                }" />
                        </td>
                    </tr>
                </table>
                <FormKit v-if="!only" type="submit" :label="label" :wrapper-class="{
                    'text-start': true
                }" />
            </FormKit>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, watch, toRef } from 'vue'
import { submitForm } from "@formkit/vue"

export default {
    props: {
        only: Boolean
    },
    emits: ['sendWeights'],
    setup(props, context) {
        const only = toRef(props, 'only')
        const label = ref('Далее')//надпись для кнопки

        const store = useStore()
        const years = store.state.firstPage.year.sort()
        const alWeight = ref([])//массив с массами по годам
        for (let i = 0; i < years.length; i++) {
            alWeight.value.push(0)
        }
        const middleWeight = ref(0)//средняя масса алюминия
        watch(alWeight, (newAlWeight) => {
            let summ = 0
            for (let i = 0; i < newAlWeight.length; i++) {
                summ += Number(newAlWeight[i])
            }
            middleWeight.value = (summ / newAlWeight.length).toFixed(2)
        },
            { deep: true }
        )
        function sendData(credentials) {
            if (only.value) {
                label.value = 'Изменить'
            }
            context.emit('sendWeights', credentials)
        }
        function emitDatas() {
            if (only.value) {
                submitForm('weight-form')
            }
        }

        return {
            years, alWeight, sendData, middleWeight, only, label, emitDatas
        }
    }
}
</script>

<style scoped>
.container-years {
    margin: 0 2rem;

}

.sub-container {
    margin: 0 auto;
    width: 90%;
}
</style>