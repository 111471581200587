<template>
    <div class="bd-main">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">№ п/п</th>
                    <th scope="col">ФИО</th>
                    <th scope="col">Email</th>
                    <th scope="col">Кол-во посещений</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr v-for="(cont, index) in content" :key="index">
                    <th scope="row">{{(index + 1)}}</th>
                    <td>{{cont.fio}}</td>
                    <td>{{cont.email}}</td>
                    <td>{{cont.count}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
import UserService from "../services/user.service";
import { onMounted, ref } from "vue";
export default {
    setup() {

        const content = ref([])
        onMounted(() => {
            UserService.getPublicContent().then(
                (responce) => {
                    content.value = responce.data;
                },
                (error) => {
                    content.value =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            )
        })

        return {
            content
        }
    }
}

</script>

<style scoped>
.bd-main {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
}
</style>