<template>
    <div class="diox-calc m-4 table-responsive">
        <table class="table table-bordered align-middle border-secondary my-5">
            <thead class="sticky-top bg-white">
                <tr>
                    <th :colspan="Object.keys(weightByYears).length + 5">Показатель</th>
                </tr>
                <tr>
                    <th scope="col">№ п/п</th>
                    <th scope="col">Наименование</th>
                    <th scope="col">Обозначения</th>
                    <th scope="col">Единицы измерения</th>
                    <th v-for="(weight, name, index) in weightByYears" :key="index">{{ name == 'averageAlWeight' ?
                    'Среднее' : name + ' год' }}</th>
                    <th>Среднее</th>
                </tr>
            </thead>
            <tbody>
                <tr class="align-middle green">
                    <td></td>
                    <td>Абсолютные валовые выбросы СО₂, т</td>
                    <td>

                    </td>
                    <td>т</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" disabled name="absDioxideEmission" outer-class="mb-0"
                            v-model="dataDioxide.absDioxideEmission[name]" /> -->
                        <!-- пример как переделать без потери точности вычисляемых строк -->
                        <p class="qwerty mb-0">{{ makeString(parseFloat(dataDioxide.absDioxideEmission[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="absDioxideEmissionMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.absDioxideEmissionMiddle" />
                    </td>
                </tr>
                <tr class="align-middle green" v-if="processType == 1">
                    <td>1</td>
                    <td>Удельный выброс диоксида углерода от электролизеров Содерберга</td>
                    <td>
                        <math-jax :latex="'E^{содб}_{CO2}'" :block="true"></math-jax>
                    </td>
                    <td>т СО₂ / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name='udelDioxideEmission[]' disabled outer-class="mb-0"
                            v-model="dataDioxide.udelDioxideEmission[name]" /> -->
                        <p class="qwerty mb-0">{{ makeString(parseFloat(dataDioxide.udelDioxideEmission[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelDioxideEmissionMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.udelDioxideEmissionMiddle" />
                    </td>
                </tr>
                <tr class="align-middle green" v-if="processType == 2">
                    <td>1</td>
                    <td>Удельный выброс СО₂ от электролизеров с обожженными анодами</td>
                    <td>
                        <math-jax :latex="'E^{оа}_{CO2}'" :block="true"></math-jax>
                    </td>
                    <td>т СО₂ / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name='udelDioxideEmissionOa[]' disabled outer-class="mb-0"
                            v-model="dataDioxide.udelDioxideEmissionOa[name]" /> -->
                        <p class="qwerty mb-0">{{ makeString(parseFloat(dataDioxide.udelDioxideEmissionOa[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelDioxideEmissionOaMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.udelDioxideEmissionOaMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 2">
                    <td>1.1</td>
                    <td>Расход обожженых анодов</td>
                    <td>
                        <math-jax :latex="'P_{a}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name='consumptioBakedAnodes[]'
                            :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataDioxide.consumptioBakedAnodes[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="consumptioBakedAnodesMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.consumptioBakedAnodesMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 2">
                    <td>1.2</td>
                    <td>Содержание серы в обожженом аноде</td>
                    <td>
                        <math-jax :latex="'S_{a}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name='sulfurPathBakedAnode[]'
                            :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataDioxide.sulfurPathBakedAnode[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="sulfurPathBakedAnodeMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.sulfurPathBakedAnodeMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 2">
                    <td>1.3</td>
                    <td>Содержание золы в обожженом аноде</td>
                    <td>
                        <math-jax :latex="'Z_{a}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name='ashPathBakedAnode[]'
                            :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataDioxide.ashPathBakedAnode[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="ashPathBakedAnodeMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.ashPathBakedAnodeMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 2">
                    <td rowspan="3" class="white">1.4</td>
                    <td>Потери углерода с пылью</td>
                    <td>
                        <math-jax :latex="'M^c_{a}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name='dustCarbonLossAnode[]' disabled outer-class="mb-0"
                            v-model="dataDioxide.dustCarbonLossAnode[name]" /> -->
                        <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataDioxide.dustCarbonLossAnode[name]).toFixed(3)) }}
                        </p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="dustCarbonLossAnodeMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.dustCarbonLossAnodeMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 2">
                    <!-- <td></td> -->
                    <td>Удельное поступление пыли через фонарь</td>
                    <td>
                        <math-jax :latex="'P^ф_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name='intakeDustLanternAnode[]'
                            :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataDioxide.intakeDustLanternAnode[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="intakeDustLanternAnodeMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.intakeDustLanternAnodeMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 2">
                    <!-- <td></td> -->
                    <td>Содержание углерода в пыли</td>
                    <td>
                        <math-jax :latex="'W^c_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name='carbonPathInDustAnode[]'
                            :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataDioxide.carbonPathInDustAnode[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonPathInDustAnodeMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.carbonPathInDustAnodeMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <td>1.1</td>
                    <td>Расход анодной массы</td>
                    <td>
                        <math-jax :latex="'P_{ам}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="anodePasteConsumption[]" outer-class="mb-0"
                            v-model="dataDioxide.anodePasteConsumption[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="anodePasteConsumptionMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.anodePasteConsumptionMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="2" class="white">1.2</td>
                    <td>Потери анодной массы с водородом</td>
                    <td>
                        <math-jax :latex="'M^н_{ам}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="LossOfAnodeMassHydrogen[]" outer-class="mb-0"
                            v-model="dataDioxide.LossOfAnodeMassHydrogen[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.LossOfAnodeMassHydrogen[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="LossOfAnodeMassHydrogenMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.LossOfAnodeMassHydrogenMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.2</td> -->
                    <td>Содержание водорода в анодной массе</td>
                    <td><em>Hn</em></td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="HydrogenPathInAnodeMass[]" outer-class="mb-0"
                            v-model="dataDioxide.HydrogenPathInAnodeMass[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="HydrogenPathInAnodeMassMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.HydrogenPathInAnodeMassMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="3" class="white">1.3</td>
                    <td>Потери анодной массы с серой и золой</td>
                    <td>
                        <math-jax :latex="'M^{sz}_{ам}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="LossOfAnodeMassSulfurAndAsh[]" outer-class="mb-0"
                            v-model="dataDioxide.LossOfAnodeMassSulfurAndAsh[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.LossOfAnodeMassSulfurAndAsh[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="LossOfAnodeMassSulfurAndAshMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.LossOfAnodeMassSulfurAndAshMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.3</td> -->
                    <td>Содержание серы в анодной массе</td>
                    <td>
                        <math-jax :latex="'S_{ам}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="sulfurPathInAnodeMass[]" outer-class="mb-0"
                            v-model="dataDioxide.sulfurPathInAnodeMass[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="sulfurPathInAnodeMassMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.sulfurPathInAnodeMassMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.3</td> -->
                    <td>Содержание золы в анодной массе</td>
                    <td>
                        <math-jax :latex="'Z_{ам}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="ashPathInAnodeMass[]" outer-class="mb-0"
                            v-model="dataDioxide.ashPathInAnodeMass[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="ashPathInAnodeMassMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.ashPathInAnodeMassMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="4" class="white">1.4</td>
                    <td>Потери углерода со смолистыми веществами (при наличии мокрой ступени газоочистки)</td>
                    <td>
                        <math-jax :latex="'M^{c}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="carbonLossResinousSubstancesWet[]" outer-class="mb-0"
                            v-model="dataDioxide.carbonLossResinousSubstancesWet[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.carbonLossResinousSubstancesWet[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonLossResinousSubstancesWetMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.carbonLossResinousSubstancesWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.4</td> -->
                    <td>Удельное поступление смолистых веществ через фонарь</td>
                    <td>
                        <math-jax :latex="'E^{ф}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="intakeResinousSubstancesLanternWet[]"
                            outer-class="mb-0" v-model="dataDioxide.intakeResinousSubstancesLanternWet[name]"
                            :disabled="name == 'averageAlWeight'" />
                        <!-- <p class="qwerty grey mb-0">{{ parseFloat(dataDioxide.intakeResinousSubstancesLanternWet[name]).toFixed(3) }}</p> -->
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="intakeResinousSubstancesLanternWetMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.intakeResinousSubstancesLanternWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.4</td> -->
                    <td>Содержание углерода в смолистых веществах</td>
                    <td>
                        <math-jax :latex="'W^{c}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="resinousCarbonPathWet[]" outer-class="mb-0"
                            v-model="dataDioxide.resinousCarbonPathWet[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="resinousCarbonPathWetMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.resinousCarbonPathWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.4</td> -->
                    <td>Удельное поступление смолистых веществ в ГОУ</td>
                    <td>
                        <math-jax :latex="'P^{г}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="resinousSubstancesInGOUWet[]" outer-class="mb-0"
                            v-model="dataDioxide.resinousSubstancesInGOUWet[name]"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="resinousSubstancesInGOUWetMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.resinousSubstancesInGOUWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="12" class="white">1.5</td>
                    <td>Потери углерода со смолистыми веществами (при наличии сухой газоочистки)</td>
                    <td>
                        <math-jax :latex="'M^{c}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="carbonLossResinousSubstancesDry[]" outer-class="mb-0"
                            v-model="dataDioxide.carbonLossResinousSubstancesDry[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.carbonLossResinousSubstancesDry[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonLossResinousSubstancesDryMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.carbonLossResinousSubstancesDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Удельное поступление смолистых веществ через фонарь</td>
                    <td>
                        <math-jax :latex="'P^{ф}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="intakeResinousSubstancesLanternDry[]" outer-class="mb-0"
                            v-model="dataDioxide.intakeResinousSubstancesLanternDry[name]" :disabled="name == 'averageAlWeight'" /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.intakeResinousSubstancesLanternDry[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="intakeResinousSubstancesLanternDryMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.intakeResinousSubstancesLanternDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Эффективность укрытия электролизера</td>
                    <td>
                        <math-jax :latex="'&eta;_{к}'" :block="true"></math-jax>
                    </td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="potСoverEfficiencyDry[]" outer-class="mb-0"
                            v-model="dataDioxide.potСoverEfficiencyDry[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="potСoverEfficiencyDryMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.potСoverEfficiencyDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Количество смолистых веществ, выделяющихся в атмосферу при перестановке штырей для
                        электролизеров с верхним токоподводом</td>
                    <td>
                        <math-jax :latex="'P^{пш}_{cм}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="amountSubstancesToPinDry[]" outer-class="mb-0"
                            v-model="dataDioxide.amountSubstancesToPinDry[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.amountSubstancesToPinDry[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="amountSubstancesToPinDryMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.amountSubstancesToPinDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Переводной коэффициент</td>
                    <td> - </td>
                    <td> - </td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" name="conversionFactorDry[]"
                            v-model="dataDioxide.conversionFactorDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="conversionFactorDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.conversionFactorDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Диаметр лунки (принят равным среднему диаметру участка штыря, запеченного в теле анода)</td>
                    <td>
                        <math-jax :latex="'d_{n}'" :block="true"></math-jax>
                    </td>
                    <td>дм</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="HoleDiameterDry[]"
                            v-model="dataDioxide.HoleDiameterDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="HoleDiameterDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.HoleDiameterDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Средняя по электролизу высота лунки (высота штыря в запеченной части анода</td>
                    <td><em>h</em></td>
                    <td>дм</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="averageWellHeightDry[]" outer-class="mb-0"
                            v-model="dataDioxide.averageWellHeightDry[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="averageWellHeightDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.averageWellHeightDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Степень заполнения лунки</td>
                    <td><em>q</em></td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="WellFillingDry[]"
                            v-model="dataDioxide.WellFillingDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="WellFillingDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.WellFillingDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Содержание пека в анодной смассе, загружаемой перед перестановкой штырей</td>
                    <td><em>c</em></td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="PitchContentDry[]"
                            v-model="dataDioxide.PitchContentDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="PitchContentDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.PitchContentDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Плотность жидкой анодной массы</td>
                    <td>
                        <math-jax :latex="'P_{жам}'" :block="true"></math-jax>
                    </td>
                    <td>кг / дм3</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="DensityLiquidAnodePasteDry[]" outer-class="mb-0"
                            v-model="dataDioxide.DensityLiquidAnodePasteDry[name]"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="DensityLiquidAnodePasteDryMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.DensityLiquidAnodePasteDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Выход кокса при быстром коксовании пека</td>
                    <td><em>k</em></td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="OutpuCokeDry[]"
                            v-model="dataDioxide.OutpuCokeDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="OutpuCokeDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.OutpuCokeDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.5</td> -->
                    <td>Количество переставляемых штырей в расчете на 1 т Ал.</td>
                    <td>
                        <math-jax :latex="'n_{ш}'" :block="true"></math-jax>
                    </td>
                    <td>шт.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="NumberOfPinsDry[]"
                            v-model="dataDioxide.NumberOfPinsDry[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="NumberOfPinsDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.NumberOfPinsDryMiddle" />
                    </td>
                </tr>
                <!-- тут в excel файле еще куча полей, но они почему-то не используются -->
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="4" class="white">1.6</td>
                    <td>Потери углерода с пылью (при наличии мокрой ступени газоочистки)</td>
                    <td>
                        <math-jax :latex="'M^c_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="carbonLossDustWet[]" v-model="dataDioxide.carbonLossDustWet[name]" outer-class="mb-0"
                            disabled /> -->
                        <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataDioxide.carbonLossDustWet[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonLossDustWetMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.carbonLossDustWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.6</td> -->
                    <td>Удельный выброс пыли через фонарь</td>
                    <td>
                        <math-jax :latex="'E^ф_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="intakeDustLanternWet[]" outer-class="mb-0"
                            v-model="dataDioxide.intakeDustLanternWet[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="intakeDustLanternWetMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.intakeDustLanternWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.6</td> -->
                    <td>Содержание углерода в пыли</td>
                    <td>
                        <math-jax :latex="'W^c_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="carbonPathInDustWet[]" outer-class="mb-0"
                            v-model="dataDioxide.carbonPathInDustWet[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonPathInDustWetMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.carbonPathInDustWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.6</td> -->
                    <td>Удельное поступление пыли в ГОУ</td>
                    <td>
                        <math-jax :latex="'P^г_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="udelDustInGOUWet[]"
                            v-model="dataDioxide.udelDustInGOUWet[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelDustInGOUWetMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.udelDustInGOUWetMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="3" class="white">1.7</td>
                    <td>Потери углерода с пылью (при наличии сухой газоочистки)</td>
                    <td>
                        <math-jax :latex="'M^c_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="carbonLossDustDry[]" v-model="dataDioxide.carbonLossDustDry[name]" outer-class="mb-0"
                            disabled /> -->
                        <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataDioxide.carbonLossDustDry[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonLossDustDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.carbonLossDustDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.7</td> -->
                    <td>Удельное поступление пыли через фонарь</td>
                    <td>
                        <math-jax :latex="'P^ф_{пыль}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="intakeDustLanternDry[]" outer-class="mb-0"
                            v-model="dataDioxide.intakeDustLanternDry[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataDioxide.intakeDustLanternDry[name]).toFixed(3)) }}
                        </p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="intakeDustLanternDryMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.intakeDustLanternDryMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.7</td> -->
                    <td>Эффективность укрытия электролизера</td>
                    <td>
                        <math-jax :latex="'&eta;_{к}'" :block="true"></math-jax>
                    </td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="PotCoverEfficiency[]" outer-class="mb-0"
                            v-model="dataDioxide.PotCoverEfficiency[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="PotCoverEfficiencyMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.PotCoverEfficiencyMiddle" />
                    </td>
                </tr>
                <!-- тут еще неиспользуемое поле -->
                <tr class="align-middle grey">
                    <td rowspan="3" class="white">{{ processType == 1 ? 1.8 : 1.5}}</td>
                    <td>Потери углерода с угольной пеной</td>
                    <td>
                        <math-jax :latex="'M^c_{пена}'" :block="true"></math-jax>
                    </td>
                    <td>т / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="carbonLossWithFoam[]" outer-class="mb-0"
                            v-model="dataDioxide.carbonLossWithFoam[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataDioxide.carbonLossWithFoam[name]).toFixed(3)) }}
                        </p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="carbonLossWithFoamMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.carbonLossWithFoamMiddle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <!-- <td>1.8</td> -->
                    <td>Выход угольной пены</td>
                    <td>
                        <math-jax :latex="'P^{вых}_{пена}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="outputCoalFoam[]"
                            v-model="dataDioxide.outputCoalFoam[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="outputCoalFoamMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.outputCoalFoamMiddle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <!-- <td>1.8</td> -->
                    <td>Содержание углерода в пене</td>
                    <td>
                        <math-jax :latex="'W^{c}_{пена}'" :block="true"></math-jax>
                    </td>
                    <td>%</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="foamCarbonPath[]"
                            v-model="dataDioxide.foamCarbonPath[name]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="foamCarbonPathMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.foamCarbonPathMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey">
                    <td class="white">{{ processType == 1 ? 1.9 : 1.6}}</td>
                    <td>Стехиометрический коэффициент пересчета углерода в СО₂</td>
                    <td> - </td>
                    <td> - </td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="stehKoeffMain"
                            v-model="dataDioxide.stehKoeffMain[name]" disabled outer-class="mb-0" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="stehKoeffMainMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.stehKoeffMainMiddle" />
                    </td>
                </tr>
                <tr class="align-middle grey" v-if="processType == 1">
                    <td rowspan="4" class="white">1.10</td>
                    <td>Удельный выброс диоксида углерода при мокрой очистке отходящих газов содовым раствором</td>
                    <td>
                        <math-jax :latex="'E^{мо}_{CO2}'" :block="true"></math-jax>
                    </td>
                    <td>т СО₂ / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="udelCarbonEmissionGasWithSoda[]" outer-class="mb-0"
                            v-model="dataDioxide.udelCarbonEmissionGasWithSoda[name]" disabled /> -->
                        <p class="qwerty grey mb-0">{{
                            makeString(parseFloat(dataDioxide.udelCarbonEmissionGasWithSoda[name]).toFixed(3))
                        }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelCarbonEmissionGasWithSodaMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.udelCarbonEmissionGasWithSodaMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.10</td> -->
                    <td>Удельное поступление диоксида серы в ГОУ</td>
                    <td>
                        <math-jax :latex="'P_{SO2}'" :block="true"></math-jax>
                    </td>
                    <td>кг / т Ал</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="udelDioxideSulfurInGOU[]" outer-class="mb-0"
                            v-model="dataDioxide.udelDioxideSulfurInGOU[name]" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelDioxideSulfurInGOUMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.udelDioxideSulfurInGOUMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.10</td> -->
                    <td>Эффективность улавливания диоксида серы в ГОУ</td>
                    <td>
                        <math-jax :latex="'&eta;_{к}'" :block="true"></math-jax>
                    </td>
                    <td>доли ед.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="captureEffDioxideSulfurInGOU[]" outer-class="mb-0"
                            v-model="dataDioxide.captureEffDioxideSulfurInGOU[name]"
                            :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="captureEffDioxideSulfurInGOUMiddle" disabled
                            outer-class="mb-0" v-model="dataDioxide.captureEffDioxideSulfurInGOUMiddle" />
                    </td>
                </tr>
                <tr class="align-middle" v-if="processType == 1">
                    <!-- <td>1.10</td> -->
                    <td>Стехиометрический коэффициент пересчета углерода в СО₂</td>
                    <td> - </td>
                    <td> - </td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="stehKoeff[]" v-model="dataDioxide.stehKoeff[name]"
                            outer-class="mb-0" :disabled="name == 'averageAlWeight'" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="stehKoeffMiddle" disabled outer-class="mb-0"
                            v-model="dataDioxide.stehKoeffMiddle" />
                    </td>
                </tr>
                <!-- Потом раскомментировать -->
                <!-- <tr>
                    <td>2</td>
                    <td>Выпуск алюминия (электролитического)</td>
                    <td>Что-то</td>
                    <td>т / год</td>
                    <td v-for="(weight, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="alumWeight[]" :value="weight" disabled />
                    </td>
                </tr> -->

            </tbody>
        </table>
    </div>
</template>

<script>
import { ref, toRef, watch, toRefs } from 'vue'
import { useStore } from 'vuex'
import {colorMetalFunctions} from "@/use/colorMetFunctions";
export default {
    props: {
        weightByYears: Object,
        showResult: Boolean
    },
    emits: ["sendAllDioxideData"],
    setup(props, context) {
        const { arrayMiddle, makeString } = colorMetalFunctions();
        const store = useStore()
        const processType = store.state.blackMetProcess.type
        const properties = toRefs(props)
        const weightByYears = toRef(props, 'weightByYears')

        const dataDioxide = ref({
            anodePasteConsumption: {},
            HydrogenPathInAnodeMass: {},
            LossOfAnodeMassHydrogen: {},
            LossOfAnodeMassSulfurAndAsh: {},
            sulfurPathInAnodeMass: {},
            ashPathInAnodeMass: {},
            carbonLossResinousSubstancesWet: {},
            intakeResinousSubstancesLanternWet: {},
            resinousCarbonPathWet: {},
            resinousSubstancesInGOUWet: {},
            carbonLossResinousSubstancesDry: {},
            intakeResinousSubstancesLanternDry: {},
            carbonLossDustWet: {},
            intakeDustLanternWet: {},
            carbonPathInDustWet: {},
            udelDustInGOUWet: {},
            carbonLossDustDry: {},
            intakeDustLanternDry: {},
            PotCoverEfficiency: {},
            carbonLossWithFoam: {},
            outputCoalFoam: {},
            foamCarbonPath: {},
            udelCarbonEmissionGasWithSoda: {},
            udelDioxideSulfurInGOU: {},
            captureEffDioxideSulfurInGOU: {},
            stehKoeff: {},
            stehKoeffMain: {},
            udelDioxideEmission: {},
            absDioxideEmission: {},
            potСoverEfficiencyDry: {},
            amountSubstancesToPinDry: {},
            conversionFactorDry: {},
            HoleDiameterDry: {},
            averageWellHeightDry: {},
            WellFillingDry: {},
            PitchContentDry: {},
            DensityLiquidAnodePasteDry: {},
            OutpuCokeDry: {},
            NumberOfPinsDry: {},
            //тип расчета 2
            udelDioxideEmissionOa: {},
            consumptioBakedAnodes: {},
            sulfurPathBakedAnode: {},
            ashPathBakedAnode: {},
            dustCarbonLossAnode: {},
            intakeDustLanternAnode: {},
            carbonPathInDustAnode: {},

            anodePasteConsumptionMiddle: 0,
            HydrogenPathInAnodeMassMiddle: 0,
            LossOfAnodeMassHydrogenMiddle: 0,
            LossOfAnodeMassSulfurAndAshMiddle: 0,
            sulfurPathInAnodeMassMiddle: 0,
            ashPathInAnodeMassMiddle: 0,
            carbonLossResinousSubstancesWetMiddle: 0,
            intakeResinousSubstancesLanternWetMiddle: 0,
            resinousCarbonPathWetMiddle: 0,
            resinousSubstancesInGOUWetMiddle: 0,
            carbonLossResinousSubstancesDryMiddle: 0,
            intakeResinousSubstancesLanternDryMiddle: 0,
            carbonLossDustWetMiddle: 0,
            intakeDustLanternWetMiddle: 0,
            carbonPathInDustWetMiddle: 0,
            udelDustInGOUWetMiddle: 0,
            carbonLossDustDryMiddle: 0,
            intakeDustLanternDryMiddle: 0,
            PotCoverEfficiencyMiddle: 0,
            carbonLossWithFoamMiddle: 0,
            outputCoalFoamMiddle: 0,
            foamCarbonPathMiddle: 0,
            udelCarbonEmissionGasWithSodaMiddle: 0,
            udelDioxideSulfurInGOUMiddle: 0,
            captureEffDioxideSulfurInGOUMiddle: 0,
            stehKoeffMiddle: 0,
            stehKoeffMainMiddle: 3.667,
            udelDioxideEmissionMiddle: 0,
            absDioxideEmissionMiddle: 0,
            potСoverEfficiencyDryMiddle: 0,
            amountSubstancesToPinDryMiddle: 0,
            conversionFactorDryMiddle: 0.785,
            HoleDiameterDryMiddle: 0,
            averageWellHeightDryMiddle: 0,
            WellFillingDryMiddle: 0,
            PitchContentDryMiddle: 0,
            DensityLiquidAnodePasteDryMiddle: 0,
            OutpuCokeDryMiddle: 0,
            NumberOfPinsDryMiddle: 0,
            resinousCarbonPathWetMiddle: 95,
            udelDioxideEmissionOaMiddle: 0,
            consumptioBakedAnodesMiddle: 0,
            sulfurPathBakedAnodeMiddle: 0,
            ashPathBakedAnodeMiddle: 0,
            dustCarbonLossAnodeMiddle: 0,
            intakeDustLanternAnodeMiddle: 0,
            carbonPathInDustAnodeMiddle: 0
        })

        for (let key in properties.weightByYears.value) {
            if (key != "averageAlWeight") {
                dataDioxide.value.anodePasteConsumption[key] = 0
                dataDioxide.value.HydrogenPathInAnodeMass[key] = 1.4
                dataDioxide.value.LossOfAnodeMassHydrogen[key] = 0
                dataDioxide.value.LossOfAnodeMassSulfurAndAsh[key] = 0
                dataDioxide.value.sulfurPathInAnodeMass[key] = 0
                dataDioxide.value.ashPathInAnodeMass[key] = 0
                dataDioxide.value.carbonLossResinousSubstancesWet[key] = 0
                dataDioxide.value.intakeResinousSubstancesLanternWet[key] = 0
                dataDioxide.value.resinousCarbonPathWet[key] = 95
                dataDioxide.value.resinousSubstancesInGOUWet[key] = 0
                dataDioxide.value.carbonLossResinousSubstancesDry[key] = 0
                dataDioxide.value.intakeResinousSubstancesLanternDry[key] = 0
                dataDioxide.value.carbonLossDustWet[key] = 0
                dataDioxide.value.intakeDustLanternWet[key] = 0
                dataDioxide.value.carbonPathInDustWet[key] = 0
                dataDioxide.value.udelDustInGOUWet[key] = 0
                dataDioxide.value.carbonLossDustDry[key] = 0
                // udelDustInGOUDry.value[key] = 0
                dataDioxide.value.intakeDustLanternDry[key] = 0
                dataDioxide.value.PotCoverEfficiency[key] = 0
                dataDioxide.value.carbonLossWithFoam[key] = 0
                dataDioxide.value.outputCoalFoam[key] = 0
                dataDioxide.value.foamCarbonPath[key] = 0
                dataDioxide.value.udelCarbonEmissionGasWithSoda[key] = 0
                dataDioxide.value.udelDioxideSulfurInGOU[key] = 0
                dataDioxide.value.captureEffDioxideSulfurInGOU[key] = 0
                dataDioxide.value.stehKoeff[key] = 0.6875
                dataDioxide.value.udelDioxideEmission[key] = 0
                dataDioxide.value.absDioxideEmission[key] = 0
                dataDioxide.value.potСoverEfficiencyDry[key] = 0
                dataDioxide.value.HoleDiameterDry[key] = 0
                dataDioxide.value.amountSubstancesToPinDry[key] = 0
                dataDioxide.value.conversionFactorDry[key] = 0.785
                dataDioxide.value.averageWellHeightDry[key] = 0
                dataDioxide.value.WellFillingDry[key] = 0
                dataDioxide.value.PitchContentDry[key] = 0
                dataDioxide.value.DensityLiquidAnodePasteDry[key] = 0
                dataDioxide.value.OutpuCokeDry[key] = 0
                dataDioxide.value.NumberOfPinsDry[key] = 0
                dataDioxide.value.stehKoeffMain[key] = 3.667
                //для расчета типа 2
                dataDioxide.value.udelDioxideEmissionOa[key] = 0
                dataDioxide.value.consumptioBakedAnodes[key] = 0
                dataDioxide.value.sulfurPathBakedAnode[key] = 0
                dataDioxide.value.ashPathBakedAnode[key] = 0
                dataDioxide.value.dustCarbonLossAnode[key] = 0
                dataDioxide.value.intakeDustLanternAnode[key] = 0
                dataDioxide.value.carbonPathInDustAnode[key] = 0
            }
        }
        watch([dataDioxide.value.HydrogenPathInAnodeMass, dataDioxide.value.anodePasteConsumption], ([newHydrogenPathInAnodeMass, newAnodePasteConsumption], []) => {
            for (let key in properties.weightByYears.value) {
                dataDioxide.value.LossOfAnodeMassHydrogen[key] = ((newHydrogenPathInAnodeMass[key] * newAnodePasteConsumption[key]) / 100)
            }
        })
        watch([dataDioxide.value.anodePasteConsumption, dataDioxide.value.sulfurPathInAnodeMass, dataDioxide.value.ashPathInAnodeMass],
            ([newAnodePasteConsumption, newSulfurPathInAnodeMass, newAshPathInAnodeMass], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.LossOfAnodeMassSulfurAndAsh[key] = (newAnodePasteConsumption[key] * ((1 * newSulfurPathInAnodeMass[key] + 1 * newAshPathInAnodeMass[key]) / 100))
                }
            })
        watch([dataDioxide.value.intakeResinousSubstancesLanternWet, dataDioxide.value.resinousCarbonPathWet, dataDioxide.value.resinousSubstancesInGOUWet],
            ([newIntakeResinousSubstancesLanternWet, newResinousCarbonPathWet, newResinousSubstancesInGOUWet], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.carbonLossResinousSubstancesWet[key] = ((1 * newIntakeResinousSubstancesLanternWet[key] / 1000) * (1 * newResinousCarbonPathWet[key] / 100) +
                        (1 * newResinousSubstancesInGOUWet[key] / 1000) * (1 * newResinousCarbonPathWet[key] / 100))
                }
            })
        watch([dataDioxide.value.conversionFactorDry, dataDioxide.value.HoleDiameterDry, dataDioxide.value.averageWellHeightDry, dataDioxide.value.WellFillingDry,
        dataDioxide.value.PitchContentDry, dataDioxide.value.DensityLiquidAnodePasteDry, dataDioxide.value.OutpuCokeDry, dataDioxide.value.NumberOfPinsDry],
            ([newConversionFactorDry, newHoleDiameterDry, newAverageWellHeightDry, newWellFillingDry, newPitchContentDry, newDensityLiquidAnodePasteDry,
                newOutpuCokeDry, newNumberOfPinsDry], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.amountSubstancesToPinDry[key] = ((1 * newConversionFactorDry[key]) * Math.pow((1 * newHoleDiameterDry[key]), 2) * (1 * newAverageWellHeightDry[key]) *
                        (1 * newWellFillingDry[key]) * (1 * newPitchContentDry[key]) * (1 * newDensityLiquidAnodePasteDry[key]) *
                        (1 - (1 * newOutpuCokeDry[key])) * (1 * newNumberOfPinsDry[key]))
                }
            })
        watch([dataDioxide.value.potСoverEfficiencyDry, dataDioxide.value.resinousSubstancesInGOUWet, dataDioxide.value.amountSubstancesToPinDry],
            ([newPotСoverEfficiencyDry, newResinousSubstancesInGOUWet, newAmountSubstancesToPinDry], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.intakeResinousSubstancesLanternDry[key] = (((1 - (1 * newPotСoverEfficiencyDry[key])) * (1 * newResinousSubstancesInGOUWet[key]))
                        + (1 * newAmountSubstancesToPinDry[key]))
                }
            })
        watch([dataDioxide.value.intakeResinousSubstancesLanternDry, dataDioxide.value.resinousCarbonPathWet],
            ([newIntakeResinousSubstancesLanternDry, newResinousCarbonPathWet], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.carbonLossResinousSubstancesDry[key] = ((1 * newIntakeResinousSubstancesLanternDry[key] / 1000) * (1 * newResinousCarbonPathWet[key] / 100))
                }
            })
        watch([dataDioxide.value.intakeDustLanternWet, dataDioxide.value.carbonPathInDustWet, dataDioxide.value.udelDustInGOUWet],
            ([newIntakeDustLanternWet, newCarbonPathInDustWet, newUdelDustInGOUWet], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.carbonLossDustWet[key] = ((1 * newIntakeDustLanternWet[key] / 1000) * (1 * newCarbonPathInDustWet[key] / 100) +
                        (1 * newUdelDustInGOUWet[key] / 1000) * (1 * newCarbonPathInDustWet[key] / 100))
                }
            })
        watch([dataDioxide.value.intakeDustLanternDry, dataDioxide.value.carbonPathInDustWet],
            ([newIntakeDustLanternDry, newCarbonPathInDustWet], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.carbonLossDustDry[key] = ((1 * newIntakeDustLanternDry[key] / 1000) * (1 * newCarbonPathInDustWet[key] / 100))
                }
            })
        watch([dataDioxide.value.outputCoalFoam, dataDioxide.value.foamCarbonPath],
            ([newOutputCoalFoam, newFoamCarbonPath], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.carbonLossWithFoam[key] = ((1 * newOutputCoalFoam[key] / 1000) * (1 * newFoamCarbonPath[key] / 100))
                }
            })
        watch([dataDioxide.value.PotCoverEfficiency, dataDioxide.value.udelDustInGOUWet],
            ([newPotCoverEfficiency, newUdelDustInGOUWet], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.intakeDustLanternDry[key] = ((1 - 1 * newPotCoverEfficiency[key]) * (1 * newUdelDustInGOUWet[key]))
                }
            })
        watch([dataDioxide.value.udelDioxideSulfurInGOU, dataDioxide.value.captureEffDioxideSulfurInGOU, dataDioxide.value.stehKoeff],
            ([newUdelDioxideSulfurInGOU, newCaptureEffDioxideSulfurInGOU, newStehKoeff], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.udelCarbonEmissionGasWithSoda[key] = ((1 * newUdelDioxideSulfurInGOU[key] / 1000) * (1 * newCaptureEffDioxideSulfurInGOU[key]) * (1 * newStehKoeff[key]))
                }
            })
        watch([dataDioxide.value.anodePasteConsumption, dataDioxide.value.LossOfAnodeMassHydrogen, dataDioxide.value.LossOfAnodeMassSulfurAndAsh,
        dataDioxide.value.carbonLossResinousSubstancesWet, dataDioxide.value.carbonLossResinousSubstancesDry, dataDioxide.value.carbonLossDustWet,
        dataDioxide.value.carbonLossDustDry, dataDioxide.value.carbonLossWithFoam, dataDioxide.value.udelCarbonEmissionGasWithSoda],
            ([newAnodePasteConsumption, newLossOfAnodeMassHydrogen, newLossOfAnodeMassSulfurAndAsh, newCarbonLossResinousSubstancesWet, newCarbonLossResinousSubstancesDry,
                newCarbonLossDustWet, newCarbonLossDustDry, newCarbonLossWithFoam, newUdelCarbonEmissionGasWithSoda], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.udelDioxideEmission[key] = ((1 * newAnodePasteConsumption[key] - 1 * newLossOfAnodeMassHydrogen[key] - 1 * newLossOfAnodeMassSulfurAndAsh[key] -
                        1 * newCarbonLossResinousSubstancesWet[key] - 1 * newCarbonLossResinousSubstancesDry[key] - 1 * newCarbonLossDustWet[key] -
                        1 * newCarbonLossDustDry[key] - 1 * newCarbonLossWithFoam[key]) * (44 / 12) + 1 * newUdelCarbonEmissionGasWithSoda[key])
                }
            })
        watch([dataDioxide.value.udelDioxideEmission, dataDioxide.value.udelDioxideEmissionOa, properties.weightByYears],
            ([newUdelDioxideEmission, newUdelDioxideEmissionOa, newWeight], []) => {
                for (let key in properties.weightByYears.value) {
                    if (processType == 1) {
                        dataDioxide.value.absDioxideEmission[key] = ((1 * newUdelDioxideEmission[key]) * (1 * newWeight[key]))
                    } else {
                        dataDioxide.value.absDioxideEmission[key] = ((1 * newUdelDioxideEmissionOa[key]) * (1 * newWeight[key]))
                    }

                }
            })
        //для второго типа расчетов
        watch([dataDioxide.value.intakeDustLanternAnode, dataDioxide.value.carbonPathInDustAnode], ([newIntakeDustLanternAnode, newCarbonPathInDustAnode], []) => {
            for (let key in properties.weightByYears.value) {
                dataDioxide.value.dustCarbonLossAnode[key] = ((newIntakeDustLanternAnode[key] / 1000) * (newCarbonPathInDustAnode[key] / 100))
            }
        })
        watch([dataDioxide.value.consumptioBakedAnodes, dataDioxide.value.sulfurPathBakedAnode, dataDioxide.value.ashPathBakedAnode,
        dataDioxide.value.dustCarbonLossAnode, dataDioxide.value.carbonLossWithFoam],
            ([newConsumptioBakedAnodes, newSulfurPathBakedAnode, newAshPathBakedAnode, newDustCarbonLossAnode, newCarbonLossWithFoam], []) => {
                for (let key in properties.weightByYears.value) {
                    dataDioxide.value.udelDioxideEmissionOa[key] = ((newConsumptioBakedAnodes[key] * ((100 - 1 * newSulfurPathBakedAnode[key] - 1 * newAshPathBakedAnode[key]) / 100) -
                        1 * newDustCarbonLossAnode[key] - 1 * newCarbonLossWithFoam[key]) * (44 / 12))
                }
            })
        // watch([dataDioxide.value.udelDioxideEmissionOa, properties.weightByYears], 
        // ([newUdelDioxideEmissionOa, newWeight], []) => {
        //     for (let key in properties.weightByYears.value){
        //         dataDioxide.value.absDioxideEmission[key] = ((1 * newUdelDioxideEmissionOa[key]) * (1 * newWeight[key])).toFixed(3)
        //     }
        // })
        watch([dataDioxide, properties.weightByYears], ([newData, newWeight], []) => {

            dataDioxide.value.anodePasteConsumptionMiddle = arrayMiddle(dataDioxide.value.anodePasteConsumption, properties.weightByYears.value)
            dataDioxide.value.HydrogenPathInAnodeMassMiddle = arrayMiddle(dataDioxide.value.HydrogenPathInAnodeMass, properties.weightByYears.value)
            dataDioxide.value.LossOfAnodeMassHydrogenMiddle = arrayMiddle(dataDioxide.value.LossOfAnodeMassHydrogen, properties.weightByYears.value)
            dataDioxide.value.LossOfAnodeMassSulfurAndAshMiddle = arrayMiddle(dataDioxide.value.LossOfAnodeMassSulfurAndAsh, properties.weightByYears.value)
            dataDioxide.value.sulfurPathInAnodeMassMiddle = arrayMiddle(dataDioxide.value.sulfurPathInAnodeMass, properties.weightByYears.value)
            dataDioxide.value.ashPathInAnodeMassMiddle = arrayMiddle(dataDioxide.value.ashPathInAnodeMass, properties.weightByYears.value)
            dataDioxide.value.carbonLossResinousSubstancesWetMiddle = arrayMiddle(dataDioxide.value.carbonLossResinousSubstancesWet, properties.weightByYears.value)
            dataDioxide.value.intakeResinousSubstancesLanternWetMiddle = arrayMiddle(dataDioxide.value.intakeResinousSubstancesLanternWet, properties.weightByYears.value)
            dataDioxide.value.resinousCarbonPathWetMiddle = arrayMiddle(dataDioxide.value.resinousCarbonPathWet, properties.weightByYears.value)
            dataDioxide.value.resinousSubstancesInGOUWetMiddle = arrayMiddle(dataDioxide.value.resinousSubstancesInGOUWet, properties.weightByYears.value)
            dataDioxide.value.carbonLossResinousSubstancesDryMiddle = arrayMiddle(dataDioxide.value.carbonLossResinousSubstancesDry, properties.weightByYears.value)
            dataDioxide.value.intakeResinousSubstancesLanternDryMiddle = arrayMiddle(dataDioxide.value.intakeResinousSubstancesLanternDry, properties.weightByYears.value)
            dataDioxide.value.carbonLossDustWetMiddle = arrayMiddle(dataDioxide.value.carbonLossDustWet, properties.weightByYears.value)
            dataDioxide.value.intakeDustLanternWetMiddle = arrayMiddle(dataDioxide.value.intakeDustLanternWet, properties.weightByYears.value)
            dataDioxide.value.carbonPathInDustWetMiddle = arrayMiddle(dataDioxide.value.carbonPathInDustWet, properties.weightByYears.value)
            dataDioxide.value.udelDustInGOUWetMiddle = arrayMiddle(dataDioxide.value.udelDustInGOUWet, properties.weightByYears.value)
            dataDioxide.value.carbonLossDustDryMiddle = arrayMiddle(dataDioxide.value.carbonLossDustDry, properties.weightByYears.value)
            dataDioxide.value.intakeDustLanternDryMiddle = arrayMiddle(dataDioxide.value.intakeDustLanternDry, properties.weightByYears.value)
            dataDioxide.value.PotCoverEfficiencyMiddle = arrayMiddle(dataDioxide.value.PotCoverEfficiency, properties.weightByYears.value)
            dataDioxide.value.carbonLossWithFoamMiddle = arrayMiddle(dataDioxide.value.carbonLossWithFoam, properties.weightByYears.value)
            dataDioxide.value.outputCoalFoamMiddle = arrayMiddle(dataDioxide.value.outputCoalFoam, properties.weightByYears.value)
            dataDioxide.value.foamCarbonPathMiddle = arrayMiddle(dataDioxide.value.foamCarbonPath, properties.weightByYears.value)
            dataDioxide.value.udelCarbonEmissionGasWithSodaMiddle = arrayMiddle(dataDioxide.value.udelCarbonEmissionGasWithSoda, properties.weightByYears.value)
            dataDioxide.value.udelDioxideSulfurInGOUMiddle = arrayMiddle(dataDioxide.value.udelDioxideSulfurInGOU, properties.weightByYears.value)
            dataDioxide.value.captureEffDioxideSulfurInGOUMiddle = arrayMiddle(dataDioxide.value.captureEffDioxideSulfurInGOU, properties.weightByYears.value)
            dataDioxide.value.stehKoeffMiddle = arrayMiddle(dataDioxide.value.stehKoeff, properties.weightByYears.value)
            dataDioxide.value.stehKoeffMainMiddle = arrayMiddle(dataDioxide.value.stehKoeffMain, properties.weightByYears.value)
            dataDioxide.value.udelDioxideEmissionMiddle = arrayMiddle(dataDioxide.value.udelDioxideEmission, properties.weightByYears.value)
            dataDioxide.value.absDioxideEmissionMiddle = arrayMiddle(dataDioxide.value.absDioxideEmission, properties.weightByYears.value)
            dataDioxide.value.potСoverEfficiencyDryMiddle = arrayMiddle(dataDioxide.value.potСoverEfficiencyDry, properties.weightByYears.value)
            dataDioxide.value.amountSubstancesToPinDryMiddle = arrayMiddle(dataDioxide.value.amountSubstancesToPinDry, properties.weightByYears.value)
            dataDioxide.value.conversionFactorDryMiddle = arrayMiddle(dataDioxide.value.conversionFactorDry, properties.weightByYears.value)
            dataDioxide.value.HoleDiameterDryMiddle = arrayMiddle(dataDioxide.value.HoleDiameterDry, properties.weightByYears.value)
            dataDioxide.value.averageWellHeightDryMiddle = arrayMiddle(dataDioxide.value.averageWellHeightDry, properties.weightByYears.value)
            dataDioxide.value.WellFillingDryMiddle = arrayMiddle(dataDioxide.value.WellFillingDry, properties.weightByYears.value)
            dataDioxide.value.PitchContentDryMiddle = arrayMiddle(dataDioxide.value.PitchContentDry, properties.weightByYears.value)
            dataDioxide.value.DensityLiquidAnodePasteDryMiddle = arrayMiddle(dataDioxide.value.DensityLiquidAnodePasteDry, properties.weightByYears.value)
            dataDioxide.value.OutpuCokeDryMiddle = arrayMiddle(dataDioxide.value.OutpuCokeDry, properties.weightByYears.value)
            dataDioxide.value.NumberOfPinsDryMiddle = arrayMiddle(dataDioxide.value.NumberOfPinsDry, properties.weightByYears.value)
            //для второго типа расчета
            dataDioxide.value.udelDioxideEmissionOaMiddle = arrayMiddle(dataDioxide.value.udelDioxideEmissionOa, properties.weightByYears.value)
            dataDioxide.value.consumptioBakedAnodesMiddle = arrayMiddle(dataDioxide.value.consumptioBakedAnodes, properties.weightByYears.value)
            dataDioxide.value.sulfurPathBakedAnodeMiddle = arrayMiddle(dataDioxide.value.sulfurPathBakedAnode, properties.weightByYears.value)
            dataDioxide.value.ashPathBakedAnodeMiddle = arrayMiddle(dataDioxide.value.ashPathBakedAnode, properties.weightByYears.value)
            dataDioxide.value.dustCarbonLossAnodeMiddle = arrayMiddle(dataDioxide.value.dustCarbonLossAnode, properties.weightByYears.value)
            dataDioxide.value.intakeDustLanternAnodeMiddle = arrayMiddle(dataDioxide.value.intakeDustLanternAnode, properties.weightByYears.value)
            dataDioxide.value.carbonPathInDustAnodeMiddle = arrayMiddle(dataDioxide.value.carbonPathInDustAnode, properties.weightByYears.value)

        }, { deep: true })
        watch(properties.showResult, (newShowResult) => {
            context.emit('sendAllDioxideData', dataDioxide.value)
        })

        return {
            weightByYears, dataDioxide, processType, 
            makeString
        }
    }
}
</script>

<style scoped>
.green {
    background-color: rgba(19, 200, 19, 0.27);
}

.grey {
    background-color: rgba(206, 198, 198, 0.222);
}

p.qwerty {
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #bbe4bc;
    text-align: left;
}

p.qwerty.grey {
    background-color: rgba(206, 198, 198, 0.222);
}

td.white {
    background-color: white;
}
</style>