export const colorMetBeginData = {
    "Электролитическое получение алюминия на электролизерах Содерберга с боковым токоподводом": {
        name: "Электролитическое получение алюминия на электролизерах Содерберга с боковым токоподводом",
        type: 1
    },
    "Электролитическое получение алюминия на электролизерах Содерберга с верхним токоподводом": {
        name: "Электролитическое получение алюминия на электролизерах Содерберга с верхним токоподводом",
        type: 1
    },
    "Электролитическое получение алюминия на электролизерах ЭкоСодерберга с верхним токоподводом": {
        name: "Электролитическое получение алюминия на электролизерах ЭкоСодерберга с верхним токоподводом",
        type: 1
    },
    "Электролитическое получение алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA)": {
        name: "Электролитическое получение алюминия на электролизерах с обожженными анодами (используемая сила тока <300 kA)",
        type: 2
    },
    "Электролитическое получение алюминия на электролизерах с обожженными анодами (используемая сила тока >300 kA)": {
        name: "Электролитическое получение алюминия на электролизерах с обожженными анодами (используемая сила тока >300 kA)",
        type: 2
    }
}