<template>
    <div class="border rounded p-3 my-3 css-prod-type">
        <!-- <TheBrickCarbonateSV v-for="item in items" :key="item" :prodTypeNum="num" :num="item" @getBrickCarbData="getCarbonatesData" /> -->
        <div class="row">
            <div class="form-floating col-lg-7 mb-3">
                <input type="text" class="form-control" :name="'prodTypeName_' + num" :id="'prodTypeName_' + num"
                    v-model="prodTypeName">
                <label class="mx-2 pt-2" :for="'prodTypeName_' + num">Введите вид продукции</label>
                <small class="invalid" v-if="prodTypeNameError">{{ prodTypeNameError }}</small>
            </div>
            <div class="form-floating col-lg-5 mb-3">
                <input type="number" class="form-control" :name="'prodWeight_' + num" :id="'prodWeight_' + num"
                    v-model="prodWeight">
                <label class="mx-2 pt-2" :for="'prodWeight_' + num">Годовой выпуск продукции, тонн/год</label>
                <small class="invalid" v-if="prodWeightError">{{ prodWeightError }}</small>
            </div>
        </div>
        <h5>Сведения об использовании карбонатов, содержащихся в минеральном сырье (глине, добавках и др.)</h5>
        <TheBrickCarbonate v-for="item in items" :key="item" :prodTypeNum="num" :num="item"
            @getBrickCarbData="getCarbonatesData" />
        <div class="row">
            <div class="">
                <button class="btn add-btn btn-sm d-flex align-items-center" @click.prevent="newCarbonate">
                    <img src="/img/plus.svg" alt="" width="40" class="add-img">Добавить<br>карбонат</button>
            </div>
        </div>

        <div class="row my-2">
            <div class="form-floating col-lg-12 mb-3">
                <input disabled type="text" class="form-control" :name="'summCarbonEmiss_' + num"
                    :id="'summCarbonEmiss_' + num" :value="summCarbonEmiss.toFixed(4)" placeholder="">
                <label class="mx-2 pt-2" :for="'summCarbonEmiss_' + num">Суммарные выбросы <i>CO<sub>2</sub></i> от
                    кальцинации карбонатов, т.
                    <i>CO<sub>2</sub></i></label>
            </div>
        </div>
        <span class="position-relative h5">
            Сведения о потреблении топлива на основные технологические процессы
            <div class="position-absolute info-pic">
                <img src="/img/info.svg" alt="" width="20" height="20" class="" data-bs-toggle="tooltip"
                    data-bs-placement="auto" data-bs-html="true" :title="tooltipData()">
            </div>

        </span>
        <TheBrickFuelComp v-for="item in fuelItems" :key="item" :prodTypeNum="num" :fuelNum="item"
            @getFuelData="getFuelData" />
        <div class="row">
            <div class="">
                <button class="btn add-btn btn-sm d-flex align-items-center" @click.prevent="newFuel">
                    <img src="/img/plus.svg" alt="" width="40" class="add-img">Добавить<br>топливо</button>
            </div>
        </div>
        <div class="row my-2">
            <div class="form-floating col-lg-12 mb-3">
                <input disabled type="text" class="form-control" :name="'summFuelEmiss_' + num" :id="'summFuelEmiss_' + num"
                    :value="summFuelEmiss.toFixed(4)" placeholder="">
                <label class="mx-2 pt-2" :for="'summFuelEmiss_' + num">Суммарные выбросы <i>CO<sub>2</sub></i> от
                    стационарного сжигания различных видов топлива, т.
                    <i>CO<sub>2</sub></i></label>
            </div>
        </div>
        <div class="row">
            <div class="form-floating col-lg-12 mb-3">
                <input disabled type="text" class="form-control" :name="'resultEmission_' + num"
                    :id="'resultEmission_' + num" :value="resultEmission.toFixed(4)" placeholder="">
                <label class="mx-2 pt-2" :for="'resultEmission_' + num">Итоговые значения выброса CO2 по виду выпускаемой
                    продукции, т.
                    <i>CO<sub>2</sub></i></label>
            </div>
            <div class="form-floating col-lg-12 mb-3">
                <input disabled type="text" class="form-control" :name="'resultUdelEmission_' + num"
                    :id="'resultUdelEmission_' + num" :value="resultUdelEmission.toFixed(4)" placeholder="">
                <label class="mx-2 pt-2" :for="'resultUdelEmission_' + num">Удельный показатель выброса CO2 по виду
                    выпускаемой
                    продукции, т.<i>CO<sub>2</sub></i>/т. кирпича</label>
            </div>
        </div>
    </div>
</template>

<script>
import { useField, Field, useForm } from "vee-validate"
import * as yup from 'yup';
import TheBrickCarbonate from "@/components/TheBrickCarbonate.vue";
import TheBrickFuelComp from "@/components/TheBrickFuelComp.vue";
// import TheBrickCarbonateSV from "@/components/TheBrickCarbonateSV.vue";
import { reactive, ref, watch, toRefs, onMounted } from 'vue'
import { fuelTypeData } from "../use/fuelTypeData"
import { Tooltip } from 'bootstrap'

export default {
    components: { TheBrickCarbonate, TheBrickFuelComp, Field },
    props: ["num"],
    emits: ["getProdTypeData"],
    setup(props, context) {

        onMounted(() => {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            })
        })


        const { num } = toRefs(props)
        const { handleSubmit } = useForm()
        const {
            value: prodTypeName,
            errorMessage: prodTypeNameError,
        } = useField(
            "prodTypeName",
            yup.string()
                .required("Введите тип продукции")
        )
        const { value: prodWeight, errorMessage: prodWeightError } =
            useField(
                "prodWeight",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0"),
                { initialValue: 0 }
            );

        const { value: summCarbonEmiss, errorMessage: summCarbonEmissError } =
            useField(
                "summCarbonEmiss",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0"),
                { initialValue: 0 }
            );
        const { value: summFuelEmiss, errorMessage: summFuelEmissError } =
            useField(
                "summFuelEmiss",
                yup
                    .number()
                    .typeError("Должно быть число")
                    .moreThan(0, "Значение должно быть больше 0"),
                { initialValue: 0 }
            );
        let items = ref([1])
        let fuelItems = ref([1])
        let componentName = 'TheBrickCarbonate'//имя компонента карбонатов
        let carbonatesObj = ref({})//объект с данными по карбонатам
        let fuelsObj = ref({})//объект с данными по топливам
        // let summCarbonEmiss = ref(0)//сумма всех выбросов по карбонатам
        const getCarbonatesData = (data) => {
            summCarbonEmiss.value = 0
            carbonatesObj.value[data.num] = data
            for (let k in carbonatesObj.value) {
                summCarbonEmiss.value += carbonatesObj.value[k].carbEmission
            }
        }
        const getFuelData = (data) => {
            summFuelEmiss.value = 0
            fuelsObj.value[data.num] = data
            for (let k in fuelsObj.value) {
                summFuelEmiss.value += fuelsObj.value[k].fuelCO2
            }
        }
        const newCarbonate = function () {//добавление нового карбоната
            items.value.push(items.value.length + 1)
        }
        const newFuel = function () {//добавление нового карбоната
            fuelItems.value.push(fuelItems.value.length + 1)
        }

        let resultEmission = ref(0)
        let resultUdelEmission = ref(0)
        let prodData = reactive({
            prodTypeName: prodTypeName,
            prodWeight: prodWeight,
            carbonatesObj: carbonatesObj,
            fuelsObj: fuelsObj,
            summCarbonEmiss: summCarbonEmiss,
            summFuelEmiss: summFuelEmiss,
            // fuelCO2: fuelCO2,
            resultEmission: resultEmission,
            resultUdelEmission: resultUdelEmission,
            num: num
        })
        const calc = handleSubmit(values => {
            // resultEmission.value = summCarbonEmiss.value + clearDataStr(fuelCO2.value)
            resultEmission.value = summCarbonEmiss.value + Number(summFuelEmiss.value)
            // resultUdelEmission.value = resultEmission.value / (clearDataStr(prodWeight.value) * 1000)
            resultUdelEmission.value = resultEmission.value / clearDataStr(prodWeight.value)//убрал деление на 1000, т.к. поменялись единицы измерения
            context.emit("getProdTypeData", prodData);
        })
        watch([prodData], ([], []) => {
            // resultEmission.value = summCarbonEmiss.value + clearDataStr(fuelCO2.value)
            // resultUdelEmission.value = resultEmission.value / (clearDataStr(prodWeight.value) * 1000)
            // context.emit("getProdTypeData", prodData);
            calc()
        })
        function clearDataStr(data) {
            if (data == '') {
                return 0
            }
            return data
        }
        function tooltipData() {
            return "<p class='tool-title'>Технологические процессы, включенные в границы расчета удельных выбросов парниковых газов от производственных процессов (производств):</p>" +
                '<ul class="list-unstyled">' +
                '<li>&mdash;  включены все процессы, прямо или косвенно связанные с производственными процессами:' +
                '<ul>' +
                '<li>•	подготовка сырья</li>' +
                '<li>•	смешивание компонентов</li>' +
                '<li>•	формование и придание формы изделиям</li>' +
                '<li>•	сушка изделий</li>' +
                '<li>•	обжиг изделий</li>' +
                '<li>•	отделка изделий</li>' +
                '<li>•	упаковка изделий</li>' +
                '</ul>' +
                '</li>' +
                '<li>&mdash;  не учитываются выбросы СО2 в процессах добычи сырьевых материалов и топлива, а также при их транспортировке.</li>' +
                '</ul>'
        }
        return {
            getCarbonatesData, newCarbonate, componentName, items, fuelItems, fuelTypeData,
            summCarbonEmiss, summCarbonEmissError, prodTypeName, prodTypeNameError, prodWeight, prodWeightError, resultEmission, resultUdelEmission,
            newFuel, getFuelData, summFuelEmiss, summFuelEmissError, tooltipData
        }
    },
}
</script>

<style >
.tooltip-inner {
    min-width: 600px;
    text-align: start;
    background-color: white !important;
    color: black !important;
    border: 2px solid black !important;
    opacity: 1 !important;
}
.tooltip-inner p, .tooltip-inner ul {
    text-align: start;
}

.form-control:disabled {
    background-color: #e2f8e8 !important;
}

.css-prod-type {
    background-color: bisque;
}

.add-btn {
    color: #4ca6c6;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1;
    transition: .3s;
}

.add-btn:hover {
    color: #4ca6c6;
    transform: scale(1.05);
    transition: .3s;
}

.css-fuel {
    background-color: rgb(255, 209, 153);
}

small.invalid {
    color: red;
    font-size: 12px;
    float: left;
    margin-left: 0.5rem;
}

.info-pic {
    top: -5px;
    right: -20px;
    cursor: pointer;
}
</style>