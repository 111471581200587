<template>
    <div class="diox-calc m-4 table-responsive">
        <table class="table table-bordered align-middle border-secondary my-5">
            <thead>
                <tr>
                    <th :colspan="Object.keys(weightByYears).length + 5">Показатель</th>
                </tr>
                <tr>
                    <th scope="col">№ п/п</th>
                    <th scope="col">Наименование</th>
                    <th scope="col">Обозначения</th>
                    <th scope="col">Единицы измерения</th>
                    <th v-for="(weight, name, index) in weightByYears" :key="index">{{ name == 'averageAlWeight' ?
                    'Среднее' : name + ' год' }}</th>
                    <th>Среднее</th>
                </tr>
            </thead>
            <tbody>
                <tr class="align-middle yellow">
                    <td></td>
                    <td>Абсолютные валовые выбросы Перфторметан (ПФУ-14), т</td>
                    <td></td>
                    <td>т</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="absEmissionCF4[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.absEmissionCF4[name]" /> -->
                            <p class="qwerty mb-0">{{ makeString(parseFloat(dataPerftorm.absEmissionCF4[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="absEmissionCF4Middle" disabled outer-class="mb-0"
                            v-model="dataPerftorm.absEmissionCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle yellow">
                    <td>1</td>
                    <td>Удельный выброс CF<sub>4</sub> от электролизеров Содерберга</td>
                    <td>
                        <math-jax :latex="'E^{содб}_{CF4}'" :block="true"></math-jax>
                    </td>
                    <td>т CF<sub>4</sub> / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="udelEmissionCF4[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.udelEmissionCF4[name]" /> -->
                            <p class="qwerty mb-0">{{ makeString(parseFloat(dataPerftorm.udelEmissionCF4[name]).toExponential(2)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelEmissionCF4Middle" disabled outer-class="mb-0"
                            v-model="dataPerftorm.udelEmissionCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <td>1.1</td>
                    <td>Угловой коэффициент для CF<sub>4</sub></td>
                    <td>
                        <math-jax :latex="'S_{CF4}'" :block="true"></math-jax>
                    </td>
                    <td>(кг CF<sub>4</sub>/т Ал.)/(минуты анодного эффекта / ванно-сутки)</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="SlopeCF4[]" :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataPerftorm.SlopeCF4[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="SlopeCF4Middle" outer-class="mb-0"
                        disabled v-model="dataPerftorm.SlopeCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle grey">
                    <td rowspan="3" class="white">1.2</td>
                    <td>Минуты анодного эффекта на ванно-сутки</td>
                    <td><em>AEM</em></td>
                    <td>минуты анодного эффекта / ванно-сутки</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="MinutesAnodeEffectCF4[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.MinutesAnodeEffectCF4[name]" /> -->
                            <p class="qwerty grey mb-0">{{ makeString(parseFloat(dataPerftorm.MinutesAnodeEffectCF4[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="MinutesAnodeEffectCF4Middle" disabled outer-class="mb-0"
                            v-model="dataPerftorm.MinutesAnodeEffectCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <!-- <td>1.2</td> -->
                    <td>Средняя частота анодных эффектов за период</td>
                    <td><em>AEF</em></td>
                    <td>шт. / ванно-сутки</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="AverageFrequencyAnodeEffectsCF4[]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" v-model="dataPerftorm.AverageFrequencyAnodeEffectsCF4[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="AverageFrequencyAnodeEffectsCF4Middle" outer-class="mb-0"
                            disabled v-model="dataPerftorm.AverageFrequencyAnodeEffectsCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <!-- <td>1.2</td> -->
                    <td>Средняя продолжительность анодных эффектов за период</td>
                    <td><em>AED</em></td>
                    <td>минут / шт.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="AverageDurationAnodeEffectsCF4[]" outer-class="mb-0"
                            :disabled="name == 'averageAlWeight'" v-model="dataPerftorm.AverageDurationAnodeEffectsCF4[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="AverageDurationAnodeEffectsCF4Middle" outer-class="mb-0"
                            disabled v-model="dataPerftorm.AverageDurationAnodeEffectsCF4Middle" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered align-middle my-5 border-secondary">
            <thead>
                <tr>
                    <th :colspan="Object.keys(weightByYears).length + 5">Показатель</th>
                </tr>
                <tr>
                    <th scope="col">№ п/п</th>
                    <th scope="col">Наименование</th>
                    <th scope="col">Обозначения</th>
                    <th scope="col">Единицы измерения</th>
                    <th v-for="(weight, name, index) in weightByYears" :key="index">{{ name == 'averageAlWeight' ?
                    'Среднее' : name + ' год' }}</th>
                    <th>Среднее</th>
                </tr>
            </thead>
            <tbody>
                <tr class="align-middle purple">
                    <td></td>
                    <td>Абсолютные валовые выбросы Перфтормэтан (ПФУ-116), т</td>
                    <td></td>
                    <td>т</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="absEmissionC2F6[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.absEmissionC2F6[name]" /> -->
                            <p class="qwerty purple mb-0">{{ makeString(parseFloat(dataPerftorm.absEmissionC2F6[name]).toFixed(3)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="absEmissionC2F6Middle[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.absEmissionC2F6Middle" />
                    </td>
                </tr>
                <tr class="align-middle purple">
                    <td>1</td>
                    <td>Удельный выброс C<sub>2</sub>F<sub>2</sub> от электролизеров Содерберга</td>
                    <td>
                        <math-jax :latex="'E^{содб}_{C2F6}'" :block="true"></math-jax>
                    </td>
                    <td>т C<sub>2</sub>F<sub>6</sub> / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <!-- <FormKit type="number" step="0.01" name="udelEmissionC2F6[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.udelEmissionC2F6[name]" /> -->
                            <p class="qwerty purple mb-0">{{ makeString(parseFloat(dataPerftorm.udelEmissionC2F6[name]).toExponential(2)) }}</p>
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelEmissionC2F6Middle[]" disabled outer-class="mb-0"
                            v-model="dataPerftorm.udelEmissionC2F6Middle" />
                    </td>
                </tr>
                <tr class="align-middle">
                    <td>1.1</td>
                    <td>Весовая доля C<sub>2</sub>F<sub>2</sub>/CF<sub>4</sub></td>
                    <td>
                        <math-jax :latex="'E^{содб}_{C2F6/CF4}'" :block="true"></math-jax>
                    </td>
                    <td>кг C<sub>2</sub>F<sub>6</sub> / кг CF<sub>4</sub></td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="weightFractionC2F6[]" :disabled="name == 'averageAlWeight'" outer-class="mb-0"
                            v-model="dataPerftorm.weightFractionC2F6[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="weightFractionC2F6Middle[]" outer-class="mb-0"
                        disabled v-model="dataPerftorm.weightFractionC2F6Middle" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref, toRef, watch, toRefs } from 'vue'
import {colorMetalFunctions} from "@/use/colorMetFunctions";
export default {
    props: {
        weightByYears: Object,
        showResult: Boolean
    },
    emits: ["sendAllPerformData"],
    setup(props, context) {
        const { arrayMiddle, makeString } = colorMetalFunctions();
        const dataPerftorm = ref({
            absEmissionCF4: {},
            absEmissionCF4Middle: 0,
            udelEmissionCF4: {},
            udelEmissionCF4Middle: 0,
            SlopeCF4: {},
            SlopeCF4Middle: 0,
            MinutesAnodeEffectCF4: {},
            MinutesAnodeEffectCF4Middle: 0,
            AverageFrequencyAnodeEffectsCF4: {},
            AverageFrequencyAnodeEffectsCF4Middle: 0,
            AverageDurationAnodeEffectsCF4: {},
            AverageDurationAnodeEffectsCF4Middle: 0,
            absEmissionC2F6: {},
            absEmissionC2F6Middle: 0,
            udelEmissionC2F6: {},
            udelEmissionC2F6Middle: 0,
            weightFractionC2F6: {},
            weightFractionC2F6Middle: 0
        })

        const properties = toRefs(props)
        const weightByYears = toRef(props, 'weightByYears')

        watch(properties.showResult, (newShowResult) => {
            context.emit('sendAllPerformData', dataPerftorm.value)
        })

        for (let key in properties.weightByYears.value){
            
            if (key != "averageAlWeight"){
                dataPerftorm.value.absEmissionCF4[key] = 0
                dataPerftorm.value.udelEmissionCF4[key] = 0
                dataPerftorm.value.SlopeCF4[key] = 0
                dataPerftorm.value.MinutesAnodeEffectCF4[key] = 0
                dataPerftorm.value.AverageFrequencyAnodeEffectsCF4[key] = 0
                dataPerftorm.value.AverageDurationAnodeEffectsCF4[key] = 0
                dataPerftorm.value.absEmissionC2F6[key] = 0
                dataPerftorm.value.udelEmissionC2F6[key] = 0
                dataPerftorm.value.weightFractionC2F6[key] = 0
            }
        }
        watch([dataPerftorm, properties.weightByYears], ([newData, newWeight], []) => {
            
            for (let key in properties.weightByYears.value){
                newData.MinutesAnodeEffectCF4[key] = newData.AverageFrequencyAnodeEffectsCF4[key] * newData.AverageDurationAnodeEffectsCF4[key]
                newData.udelEmissionCF4[key] = (newData.SlopeCF4[key] * newData.MinutesAnodeEffectCF4[key] / 1000)
                newData.absEmissionCF4[key] = (newData.udelEmissionCF4[key] * newWeight[key])
                newData.udelEmissionC2F6[key] = (newData.udelEmissionCF4[key] * newData.weightFractionC2F6[key])
                newData.absEmissionC2F6[key] = (newData.udelEmissionC2F6[key] * newWeight[key])
                dataPerftorm.value.absEmissionCF4Middle = arrayMiddle(dataPerftorm.value.absEmissionCF4, properties.weightByYears.value)
                dataPerftorm.value.udelEmissionCF4Middle = arrayMiddle(dataPerftorm.value.udelEmissionCF4, properties.weightByYears.value)
                dataPerftorm.value.SlopeCF4Middle = arrayMiddle(dataPerftorm.value.SlopeCF4, properties.weightByYears.value)
                dataPerftorm.value.MinutesAnodeEffectCF4Middle = arrayMiddle(dataPerftorm.value.MinutesAnodeEffectCF4, properties.weightByYears.value)
                dataPerftorm.value.AverageFrequencyAnodeEffectsCF4Middle = arrayMiddle(dataPerftorm.value.AverageFrequencyAnodeEffectsCF4, properties.weightByYears.value)
                dataPerftorm.value.AverageDurationAnodeEffectsCF4Middle = arrayMiddle(dataPerftorm.value.AverageDurationAnodeEffectsCF4, properties.weightByYears.value)
                dataPerftorm.value.absEmissionC2F6Middle = arrayMiddle(dataPerftorm.value.absEmissionC2F6, properties.weightByYears.value)
                dataPerftorm.value.udelEmissionC2F6Middle = arrayMiddle(dataPerftorm.value.udelEmissionC2F6, properties.weightByYears.value)
                dataPerftorm.value.weightFractionC2F6Middle = arrayMiddle(dataPerftorm.value.weightFractionC2F6, properties.weightByYears.value)
            }
            
        }, { deep: true })

        return {
            weightByYears,
            dataPerftorm,
            makeString
        }
    }
}
</script>

<style scoped>
.yellow{
    background-color: rgba(242, 244, 92, 0.27);
}
.grey{
    background-color: rgba(206, 198, 198, 0.222);
}
.purple{
    background-color: rgba(194, 101, 67, 0.188);
}
p.qwerty{
    width: 100%;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #eeefcb;
    text-align: left;
}
p.qwerty.purple {
    background-color: #e7d6d1;
}
p.qwerty.grey{
    background-color: rgba(206, 198, 198, 0.222);
}
td.white{
    background-color: white;
}
</style>