<template>
  <div class="bd-main">
    <form class="bd-content ps-lg-4 container" id="typeOne" @submit.prevent="onSubmit">
      <div class="row mb-3">
        <div class="col-sm-12">
          <input v-model="company" type="text" class="form-control" id="company" name="company" @blur="companyBlur"
            placeholder="Введите наименование предприятия" />
          <small class="invalid" v-if="companyError">{{ companyError }}</small>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-5">
          <div class="col-sm-12">
            <input v-model="onvCode" type="text" class="form-control" id="onvCode" name="onvCode" @blur="onvCodeBlur"
              placeholder="Введите код ОНВ" />
            <small class="invalid" v-if="onvCodeError">{{
                onvCodeError
            }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-4">
          <div class="col-sm-12">
            <select v-model="period" class="form-select" aria-label="Default select example" id="period" name="period"
              @change="periodChange">
              <option disabled value="" selected="selected">
                Выберите период
              </option>
              <option value="Год">Год</option>
              <option value="Квартал">Квартал</option>
              <option value="Месяц">Месяц</option>
            </select>
            <small class="invalid" v-if="periodError">{{ periodError }}</small>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-3">
          <div class="col-sm-12">
            <input v-model="year" type="number" class="form-control" id="year" name="year" @blur="yearBlur"
              placeholder="Год" />
            <small class="invalid" v-if="yearError">{{ yearError }}</small>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="row mb-3 mt-3">
          <label for="thousandsTonnsProduction" class="col-sm-5 col-form-label">Вес (масса) произведённого цемента типа
            i, тонны</label>
          <div class="col-sm-7">
            <input v-model="thousandsTonnsProduction" type="number" step="0.01" @blur="thousandsTonnsProductionBlur"
              class="form-control form-control-type-two" id="thousandsTonnsProduction"
              name="thousandsTonnsProduction" />
            <small class="invalid" v-if="thousandsTonnsProductionError">{{
                thousandsTonnsProductionError
            }}</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="fuelType" class="col-sm-5 col-form-label">Тип цемента</label>
          <div class="col-sm-7">
            <Field as="select" v-model="cementType" class="form-select form-control-type-two"
              aria-label="Default select example" id="cementType" name="cementType" @change="setValues($event)">
              <option disabled value="zero">Выберите тип цемента</option>
              <template v-for="(value, name, index) in cementData" :key="index">
                <option v-if="name != 'zero'" :value="name">{{ name }}</option>
              </template>
            </Field>
            <small class="invalid" v-if="cementTypeError">{{
                cementTypeError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="klinkerFraction" class="col-sm-5 col-form-label">Фракция клинкера в цементе типа i, дробь</label>
          <div class="col-sm-7">
            <input v-model="klinkerFraction" type="number" step="0.01" class="form-control form-control-type-two"
              id="klinkerFraction" name="klinkerFraction" />
            <small class="invalid" v-if="klinkerFractionError">{{
                klinkerFractionError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="klinkerKoeff" class="col-sm-5 col-form-label">Коэффициент выбросов для клинкера в конкретном
            цементе, тонны CO2/тонну клинкера</label>
          <div class="col-sm-7">
            <input v-model="klinkerKoeff" type="number" step="0.01" class="form-control form-control-type-two"
              id="klinkerKoeff" name="klinkerKoeff" />
            <small class="invalid" v-if="klinkerKoeffError">{{
                klinkerKoeffError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="klinkerImport" class="col-sm-5 col-form-label">Импорт клинкера для потребления, тонны</label>
          <div class="col-sm-7">
            <input v-model="klinkerImport" type="number" step="0.01" class="form-control form-control-type-two"
              id="klinkerImport" name="klinkerImport" />
            <small class="invalid" v-if="klinkerImportError">{{
                klinkerImportError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="klinkerExport" class="col-sm-5 col-form-label">Экспорт клинкера, тонны </label>
          <div class="col-sm-7">
            <input v-model="klinkerExport" type="number" step="0.01" class="form-control form-control-type-two"
              id="klinkerExport" name="klinkerExport" />
            <small class="invalid" v-if="klinkerExportError">{{
                klinkerExportError
            }}</small>
          </div>
        </div>
        <div class="row mb-3">
          <label for="cementCo2" class="col-sm-5 col-form-label">Выбросы CO2 от производства цемента, тонны</label>
          <div class="col-sm-7">
            <input v-model="cementCo2" type="text" class="form-control form-control-type-two" id="cementCo2"
              name="cementCo2" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <label for="udelPokVybr" class="col-sm-5 col-form-label">Удельный показатель выбросов, т CO2-экв/т</label>
          <div class="col-sm-7">
            <input v-model="udelPokVybr" type="text" class="form-control form-control-type-two" id="udelPokVybr"
              name="udelPokVybr" disabled :class="{ excess: excess, noexcees: !excess }" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="koefUdelVybr" class="col-sm-5 col-form-label">Производство цемента, коэффициент удельных выбросов
            (CO2-экв)</label>
          <div class="col-sm-7">
            <input v-model="koefUdelVybr" type="text" class="form-control form-control-type-two" id="koefUdelVybr"
              name="koefUdelVybr" disabled />
          </div>
        </div>
        <!-- <div class="row mb-3">
          <label for="udelCheckVybr" class="col-sm-5 col-form-label"
            >Удельный показатель выбросов, т. СО2-экв./т.</label
          >
          <div class="col-sm-7">
            <input
              v-model="udelCheckVybr"
              type="number"
              class="form-control form-control-type-two"
              id="udelCheckVybr"
              name="udelCheckVybr"
              disabled
            />
            <small class="invalid" v-if="udelCheckVybrError">{{
              udelCheckVybrError
            }}</small>
          </div>
        </div> -->
        <div class="d-grid d-md-flex justify-content-between">
          <button type="button" @click.prevent="calculate" class="btn devise-btn col-sm-6">
            Рассчитать
          </button>
          <button @click.prevent="generatePdfFile" type="submit" class="btn pdf-make me-2 sub_btn" disabled>
            <img src="/img/disk.svg" width="25" alt="">
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { calculateTypeOneCement } from "../use/type_one_cement";
import { Field, useForm } from "vee-validate";
export default {
  name: "TheTypeOneCement",
  components: {
    Field,
  },
  setup() {
    return { ...calculateTypeOneCement() };
  },
};
</script>

<style scoped>
.excess {
  color: red;
}

.noexcees {
  color: green;
}

.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 0.5rem;
}

.devise-btn {
  background-color: #4ca6c6;
  color: white;
  border: 2px solid #4ca6c6;
}

.devise-btn:hover {
  background-color: white;
  border: 2px solid #4ca6c6;
  color: #4ca6c6;
}
.pdf-make {
  background-color: #4ca6c6 !important;
  float: right;
  cursor: pointer;
}
.pdf-make:hover {
  cursor: pointer !important;
}
.form-control:disabled {
  background-color: #e2f8e8 !important;
}
</style>