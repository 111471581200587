<template>
  <div class="bd-main">
    <form class="bd-content ps-lg-4 container" id="secondType" @submit.prevent="onSubmit">
      <div class="row mb-3">
        <label for="company" class="col-sm-2 col-form-label">Предприятие</label>
        <div class="col-sm-10">
          <Field v-model="company" type="text" class="form-control" id="company" name="company" @blur="companyBlur"
            placeholder="Введите название предприятия" />
          <small class="invalid" v-if="companyError">{{ companyError }}</small>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-4">
          <div class="row">
            <label for="onvCode" class="col-sm-3 col-form-label">Код ОНВ</label>
            <div class="col-sm-9">
              <Field v-model="onvCode" type="text" class="form-control" id="onvCode" name="onvCode" @blur="onvCodeBlur"
                placeholder="Введите код ОНВ" />
              <small class="invalid" v-if="onvCodeError">{{
                  onvCodeError
              }}</small>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <label for="period" class="col-sm-3 col-form-label">Период</label>
            <div class="col-sm-9">
              <Field as="select" v-model="period" class="form-select" aria-label="Default select example" id="period"
                name="period" @change="periodChange">
                <option value="" disabled>Выберите период</option>
                <option value="Год">Год</option>
                <option value="Квартал">Квартал</option>
                <option value="Месяц">Месяц</option>
              </Field>
              <small class="invalid" v-if="periodError">{{
                  periodError
              }}</small>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="row">
            <label for="year" class="col-sm-5 col-form-label">Значение периода</label>
            <div class="col-sm-7">
              <Field v-model="year" type="number" class="form-control" id="year" name="year" @blur="yearBlur"
                placeholder="Год" />
              <small class="invalid" v-if="yearError">{{ yearError }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row mb-3 mt-3">
          <label for="sumVybrByCompany" class="col-sm-5 col-form-label">Суммарный выброс по предприятию / ОНВ, тонн
            (СО2-экв)</label>
          <div class="col-sm-7">
            <Field type="text" class="form-control green" id="sumVybrByCompany" name="sumVybrByCompany" disabled
              v-model="sumVybrosCompanyFixed" />
          </div>
        </div>
        <hr />
        <the-device v-for="deviceNumber in devicesCount" :key="deviceNumber" :noDevice="noDevice" :number="devicesCount"
          @getData="getAllData"></the-device>
        <div class="row justify-content-between">
          <div class="col-sm-7 mb-3">
            <!-- <button @click.prevent="addDevice" class="btn custom-btn-type-two"> -->
            <button @click.prevent="addDevice" class="btn add-btn d-flex align-items-center me-5">
              <img src="/img/plus.svg" alt="" width="40" class="add-img">
              Добавить<br />устройство
            </button>
          </div>
          <div class="col-sm-5">
            <!-- <button type="submit" class="btn custom-btn-type-two col-sm-10" @click.prevent="generatePdfFile"> -->
            <button type="submit" style="color: white" class="btn pdf-make me-2" disabled
              @click.prevent="generatePdfFile">
              <img src="/img/disk.svg" width="25" alt="">
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TheDevice from "../components/TheDevice.vue";
import { getDataForTypeTwo } from "../use/type_two";
import { Field } from "vee-validate";

export default {
  components: { TheDevice, Field },
  setup() {
    return {
      ...getDataForTypeTwo(),
    };
  },
};
</script>


<style scoped lang="scss">
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$calculate-for-device-button-color: #7a6d49;
$hover-button-color: #ffffff;

$custom-btn-type-two-color: #6b6b6b;
$hover-custom-btn-type-two-color: #ffffff;

.custom-btn {
  @include button-variant($calculate-for-device-button-color,
    $calculate-for-device-button-color,
    $hover-button-color );
}

.custom-btn:hover {
  @include button-variant($hover-button-color,
    $calculate-for-device-button-color,
    $calculate-for-device-button-color );
}

.custom-btn-type-two {
  @include button-variant($custom-btn-type-two-color,
    $custom-btn-type-two-color,
    $hover-custom-btn-type-two-color );
}

.custom-btn-type-two:hover {
  @include button-variant($hover-custom-btn-type-two-color,
    $custom-btn-type-two-color,
    $custom-btn-type-two-color );
}

.bd-main {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 50px;
}

label {
  text-align: left;
}

.green {
  background-color: #e2f8e8;
}

.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 0.5rem;
}

.custom-btn-type-two {
  width: 100%;
}

.form-control:disabled {
  background-color: #e2f8e8 !important;
}

.pdf-make {
  background-color: #4ca6c6;
  float: right;
}

.pdf-make:hover {
  background-color: #1da0cf;
  cursor: pointer !important;
}
.add-btn{
  color: #4ca6c6;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  transition: .3s;
}
.add-btn:hover{
  color: #4ca6c6;
  transform: scale(1.05);
  transition: .3s;
}
</style>