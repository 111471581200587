export const fuelTypeData = {
    "Природный газ": {
        k: "54.40",
        eoCH: "1",
        eoNO: "0.1",
        opCH: "1",
        opNO: "0.1",
        ksCH: "5",
        ksNO: "0.1",
        jsCH: "5",
        jsNO: "0.1",
    },
    "Газ сжиженный": {
        k: "56.10",
        eoCH: "0",
        eoNO: "0",
        opCH: "1",
        opNO: "0.1",
        ksCH: "5",
        ksNO: "0.1",
        jsCH: "5",
        jsNO: "0.1",
    },
    Торф: {
        k: "106",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Брикеты торфяные": {
        k: "106",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    zero: {
        k: "0",
        eoCH: "0",
        eoNO: "0",
        opCH: "0",
        opNO: "0",
        ksCH: "0",
        ksNO: "0",
        jsCH: "0",
        jsNO: "0",
    },
    "Нефть, включая промысловый газоконденсат": {
        k: "73.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Природный газовый конденсат": {
        k: "64.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ попутный нефтяной (нефтяные месторождения)": {
        k: "60.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ попутный нефтяной (газоконденсатные месторождения)": {
        k: "55.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ попутный нефтяной (газовые месторождения)": {
        k: "55.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Бензин автомобильный": {
        k: "69.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Бензин авиационный": {
        k: "70",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Авиационный керосин": {
        k: "71.5",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Керосин": {
        k: "71.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Топливо дизельное": {
        k: "74.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Мазут топочный": {
        k: "77.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Мазут флотский": {
        k: "77.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Топливо печное бытовое": {
        k: "77.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ сжиженный нефтяной": {
        k: "63.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Другие моторные топлива": {
        k: "71.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Нефтебитум": {
        k: "80.7",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Этан": {
        k: "61.6",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Пропан": {
        k: "63.8",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Бутан": {
        k: "62",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Пропан и бутан сжиженные, газы углеводородные и их смеси сжиженные": {
        k: "63.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Лигроин": {
        k: "73.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Смазочные материалы": {
        k: "73.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ нефтеперерабатывающих предприятий сухой": {
        k: "44.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Кокс нефтяной и сланцевый": {
        k: "97.5",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Другие нефтепродукты": {
        k: "73.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь донецкий": {
        k: "90.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь кузнецкий": {
        k: "91.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь карагандинский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь подмосковный": {
        k: "95",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь воркутинский": {
        k: "92.6",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь интинский": {
        k: "93.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь челябинский": {
        k: "94.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь свердловский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь башкирский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь нерюнгринский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь якутский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь черемховский": {
        k: "94",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь азейский": {
        k: "93.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь читинский": {
        k: "98.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь гусиноозерский": {
        k: "94.9",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь хакасский": {
        k: "94.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь канско-ачинский": {
        k: "98.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь тувинский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь тунгусский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь магаданский": {
        k: "93.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь арктический (шпицбергенский)": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь норильский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь огоджинский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь камчатский": {
        k: "93.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь Приморья": {
        k: "93.1",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь экибастузский": {
        k: "94.6",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь алтайский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь тугнуйский": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь прочих месторождений": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "уголь импортный": {
        k: "94.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Антрацит": {
        k: "98.3",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Коксующийся уголь": {
        k: "94.6",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Каменный уголь": {
        k: "94.6",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Бурый уголь": {
        k: "101",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Сланцы горючие": {
        k: "107",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Брикеты угольные": {
        k: "97.5",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ горючий искусственный коксовый": {
        k: "44.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ горючий искусственный доменный": {
        k: "260",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Кокс металлургический": {
        k: "107",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Смола каменноугольная коксохимических заводов": {
        k: "80.7",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Газ компримированный": {
        k: "54.4",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Отходы бытовые (небиологическая фракция)": {
        k: "91.7",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Прочие горючие отходы технологических производств": {
        k: "143",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
    "Нефтяные отходы": {
        k: "72.2",
        eoCH: "1",
        eoNO: "1.5",
        opCH: "2",
        opNO: "1.5",
        ksCH: "10",
        ksNO: "1.4",
        jsCH: "300",
        jsNO: "1.4",
    },
}