import { createStore } from "vuex"
import { auth } from "./auth.module";

export default createStore({
  // calcType 1 - все, 2 - Целлюлозно-бумажное производство
	state: {
		activityType: "",
		productionType: "",
		production: {
			k: "0",
			l: "zero",
		},
    calcType: 1,
    firstPage: {},
    blackMetProcess: {},
    colorkMetProcess: {}
	},
	mutations: {
    setActivityType: (state, payload) => {
      state.activityType = payload
    },
    setProductionType: (state, payload) => {
      state.productionType = payload
    },
    setProduction: (state, payload) => {
      state.production = payload
    },
    setCalcType: (state) => {
      state.calcType = state.activityType == 'Целлюлозно-бумажное производство' ? 2 : 1
    },
    setFirstPageData: (state, payload) => {
      state.firstPage = payload
    },
    setBlackMetProcess: (state, payload) => {
      state.blackMetProcess = payload
    },
    setColorMetProcess: (state, payload) => {
      state.blackMetProcess = payload
    }
  },
	// actions: {
  //   setActivityType: (state, payload) => {
  //     state.activityType = payload
  //   },
  //   setProductionType: (state, payload) => {
  //     state.productionType = payload
  //   },
  //   setProduction: (state, payload) => {
  //     state.production = payload
  //   }
  // },
	
  getters: {
    activ: state => {
      return state.activityType
    },
    prodType: state => {
      return state.productionType
    },
    prod: state => {
      return state.production
    }
  },
  modules: {
    auth,
  },
})
