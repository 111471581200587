<template>
    <thead>
        <tr>
            <th scope="col" rowspan="2" class="align-middle text-center" style="width: 7%">Тип ресурса</th>
            <th scope="col" rowspan="2" class="align-middle text-center" style="width: 12%">Вид ресурса</th>
            <th scope="col" rowspan="2" class="align-middle text-center">Ед. изм.</th>
            <th scope="col" :colspan="years.length + 1" class="align-middle text-center">
                Суммарный
                израсходованный объем</th>
            <th scope="col" rowspan="2" class="align-middle text-center">Содерж. углерода, т/ед</th>
            <th scope="col" rowspan="2" class="align-middle text-center">Коэфф. выбросов СО<sub>2</sub>, т/ед</th>
            <th scope="col" :colspan="years.length + 1" class="align-middle text-center">
                Абсолютные
                валовые выбросы СО<sub>2</sub>, т</th>
        </tr>
        <tr>
            <th class="text-center" style="white-space: nowrap; vertical-align: middle;" scope="col" v-for="year in years" :key="year">{{ year }} год
            </th>
            <th class="text-center" scope="col">Среднее</th>
            <th class="text-center" scope="col" v-for="year in years" :key="year">{{ year }} год
            </th>
            <th class="text-center" scope="col">Среднее</th>
        </tr>
    </thead>
</template>

<script>

export default {
    name: 'TableHeaderComponent',
    props: {
        years: Array
    },

    setup(props) {
        const years = props.years
        return {
            years
        }
    }
}

</script>