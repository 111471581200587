import { computed, ref, nextTick } from "vue"
import * as yup from "yup"
import { useField, useForm } from "vee-validate"
import { useRouter, useRoute } from "vue-router"
import { useStore } from "vuex"
import { logoData } from "./logo"
import {cementData}  from "./cementData"

export function calculateTypeThreeCement() {
	const { handleSubmit, isSubmitting, submitCount } = useForm()
	const router = useRouter()
	const store = useStore()

	const MIN_LENGTH = 4
	const {
		value: company,
		errorMessage: companyError,
		handleBlur: companyBlur,
	} = useField(
		"company",
		yup
			.string()
			.trim()
			.required("Введите наименование компании")
			.min(
				MIN_LENGTH,
				`Наименование компании не должно быть меньше ${MIN_LENGTH} символов`
			)
	)
	const {
		value: onvCode,
		errorMessage: onvCodeError,
		handleBlur: onvCodeBlur,
	} = useField(
		"onvCode",
		yup
			.string()
			.trim()
			.required("Введите код ОНВ")
			.min(
				MIN_LENGTH,
				`Код ОНВ не должен быть меньше ${MIN_LENGTH} символов`
			)
	)
	const {
		value: period,
		errorMessage: periodError,
		handleBlur: periodChange,
	} = useField("period", yup.string().trim().required("Введите период"), {
		initialValue: "",
	})
	const {
		value: year,
		errorMessage: yearError,
		handleBlur: yearBlur,
	} = useField(
		"year",
		yup
			.string()
			.trim()
			.required("Введите год")
			.min(4, "Введите корректный год")
	)

	const {
		value: koefKerogenVybr,
		errorMessage: koefKerogenVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"koefKerogenVybr",
		yup.number().moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)

	const {
		value: calcPathCP,
		errorMessage: calcPathCPError,
		handleBlur: calcPathCPBlur,
	} = useField(
		"calcPathCP",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)

	const {
		value: popravKoeff,
		errorMessage: popravKoeffError,
		handleBlur: popravKoeffBlur,
	} = useField(
		"popravKoeff",
		yup
			.number()
			.typeError("Введите число")
			.min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 0 }
	)

	
	const {
		value: weightNoFuelMaterial,
		errorMessage: weightNoFuelMaterialError,
		handleBlur: weightNoFuelMaterialBlur,
	} = useField(
		"weightNoFuelMaterial",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)
    const {
		value: weightCP,
		errorMessage: weightCPError,
		handleBlur: weightCPBlur,
	} = useField(
		"weightCP",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)
	const {
		value: carbPath,
		errorMessage: carbPathError,
		handleBlur: carbPathBlur,
	} = useField(
		"carbPath",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 1 }
	)
	const {
		value: kVybrNoCalcCarb,
		errorMessage: kVybrNoCalcCarbError,
		handleBlur: kVybrNoCalcCarbBlur,
	} = useField(
		"kVybrNoCalcCarb",
		yup
			.number()
			.typeError("Введите число")
			.moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0.4397 }
	)

	const {
		value: pathUglerod,
		errorMessage: pathUglerodError,
		// handleBlur: yearBlur,
	} = useField(
		"pathUglerod",
		yup.number().moreThan(0, "Значение должно быть больше 0"),
		{ initialValue: 0 }
	)

	const {
		value: udelPokVybr,
		errorMessage: udelPokVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"udelPokVybr",
		yup.number().min(0, "Значение не должно быть меньше 0"),
		{ initialValue: 0 }
	)

	const {
		value: koefUdelVybr,
		errorMessage: koefUdelVybrError,
		// handleBlur: yearBlur,
	} = useField(
		"koefUdelVybr",
		// yup.number().min(0, "Значение не должно быть меньше 0"),
		yup.number(),
		{ initialValue: 0.53 }  
	)
    const {
		value: vybrCO2Cement,
		errorMessage: vybrCO2CementError,
		// handleBlur: yearBlur,
	} = useField(
		"vybrCO2Cement",
		// yup.number().min(0, "Значение не должно быть меньше 0"),
		yup.number(),
		{ initialValue: 0 }
	)

	const onSubmit = handleSubmit(async (values) => {
		const data = new FormData()
		data.append("dfdf", "sdsd")
		const response = await fetch("http://calc/api/", {
			method: "POST",
			body: JSON.stringify(values),
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
		})
		const res = await response.json()
		return
		router.push("/")
	})
	const onChangePT = () => {
		production.value = { k: "0", l: "zero" }
	}
	const onChangePTandVP = () => {
		production.value = { k: "0", l: "zero" }
		productionType.value = ""
	}

	

	const productionTypeInclArray = ["29", "31", "38", "44"]
	const diokUgl = 1
	const metan = 25
	const zakAzot = 298

    const totalCarbData = [];
    const noCarbonates = ref(false);

	const excess = ref(false)

	function setValues(event){
		klinkerFraction.value = cementData[event.target.value].klinker
		klinkerKoeff.value = cementData[event.target.value].co2
	}

	const calculateTypeThree = handleSubmit((values) => {
        
        vybrCO2Cement.value = summCarbonatsVybrosFixed.value - weightCP.value * carbPath.value * (1 - calcPathCP.value) * kVybrNoCalcCarb.value +
        weightNoFuelMaterial.value * pathUglerod.value * koefKerogenVybr.value

        udelPokVybr.value = (vybrCO2Cement.value / summCarbonWeight.value).toFixed(2)

		udelPokVybr.value > koefUdelVybr.value ? excess.value = true : excess.value = false

		document.querySelector("button.sub_btn").removeAttribute("disabled")
	})
    const route = useRoute();

    const carbonate = computed(() => route.meta.carbonate);
    const summCarbonatsVybros = ref("0");
    const summCarbonatsVybrosFixed = ref(0);
    const summCarbonWeight = ref(0)

    let rawCount = ref(1);
    const addRaw = async () => {
      rawCount.value++;
      await nextTick(); //ждет пока перестроится дом
      document
        .querySelector(
          ".row .rawMaterial_num_" + rawCount.value
        )
        .scrollIntoView({ block: "center", behavior: "smooth" });
    };
    const getCarbsData = (data) => {
      noCarbonates.value = false;
      if (totalCarbData.length != 0) {
        totalCarbData.forEach((el, index, array) => {
          for (let k in el) {
            for (let key in data) {
              if (k == key) {
                array.splice(index, 1);
              }
            }
          }
        });
        totalCarbData.push(data);
      } else {
        totalCarbData.push(data);
      }
      summCarbonatsVybros.value = 0;
      summCarbonWeight.value = 0
      totalCarbData.forEach((el) => {
        for (let k2 in el) {
          summCarbonatsVybros.value += el[k2].vpVal;
          summCarbonWeight.value += el[k2].cwVal;
        }
      });
      summCarbonatsVybrosFixed.value = summCarbonatsVybros.value.toFixed(0);
    };

	function generatePdfFile() {
		var pdfMake = require("pdfmake/build/pdfmake.js")
		var pdfFonts = require("pdfmake/build/vfs_fonts")

		pdfMake.fonts = {
			Arial: {
				normal: "ArialRegular.ttf",
				bold: "ArialBold.ttf",
				italics: "ArialItalic.ttf",
				bolditalics: "ArialRegular.ttf",
			},
		}
		pdfMake.vfs = pdfFonts.pdfMake.vfs
		var docDefinition = {
			content: company.value,
			defaultStyle: {
				font: "Roboto",
			},
		}
		let d = new Date()
		let formatter = new Intl.DateTimeFormat("ru", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		})
		let date = formatter.format(d)
		var dd = {
			pageOrientation: "landscape",
			defaultStyle: {
				font: "Arial",
				fontSize: 11,
			},
			footer: function (currentPage, pageCount) {
				// return currentPage.toString() + ' из ' + pageCount;
				return [
					{
						columns: [
							{
								width: 750,
								text: date,
								margin: [15, 0, 0, 8],
								fontSize: 10,
							},
							{
								text:
									"Стр. " +
									currentPage.toString() +
									" из " +
									pageCount,
								margin: [10, 0, 0, 0],
								fontSize: 10,
							},
						],
					},
					{
						text: 'данный отчёт сформирован калькулятором, разработанным ФГАУ "НИИ "ЦЭПП"" и носит рекомендательный характер',
						fontSize: 9,
						color: "#515151",
						margin: [15, 0, 0, 8],
						italics: true,
					},
				]
			},
			content: [
				{
					image: logoData,
					width: 800,
				},
				{
					text: "Тип расчета:  Расчет выбросов при наличии данных только по типам продукции.",
					fontSize: 11,
					margin: [2, 2],
				},
				{
					text: "Организация    " + company.value,
					fontSize: 11,
					margin: [5, 5],
				},
				{
					text:
						"Код ОНВ      " +
						onvCode.value +
						"     Отчетный период   " +
						period.value +
						"   " +
						year.value,
					fontSize: 11,
					margin: [5, 8],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text: store.state.activityType,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							text: store.state.productionType,
							margin: [10, 0, 0, 0],
							background: "#e8f0fe",
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text:
								store.state.production.l == "zero"
									? "-----"
									: store.state.production.l,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							columns: [
								{
									text:
										"Выпуск продукции тонн     " +
										thousandsTonnsProduction.value,
									margin: [10, 0, 0, 0],
								},
								{
									text:
										store.state.calcType == 1 ? "Доля стеклобоя %         " + stecloboyPath.value : "Кальцинированная сода, тонн    " +
										stecloboyPath.value,
									margin: [10, 0, 0, 0],
								},
							],
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 300,
							text: fuelType.value,
							margin: [10, 0, 0, 8],
							background: "#e8f0fe",
						},
						{
							columns: [
								{
									text:
										"Расход топлива в ГДж       " +
										energyQuantity.value,
									margin: [10, 0, 0, 0],
								},
								{
									text:
										"Снижение т CO2 экв        " +
										snigenieEcv.value,
									margin: [10, 0, 0, 0],
								},
							],
						},
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Технологические выбросы, тонн (СО2-экв.)",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: techVybross.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Энергетические выбросы, тонн (СО2-экв.)",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: energoVybross.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Суммарный выброс, тонн (СО2-экв.)",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: summVybross.value },
					],
				},
				{
					alignment: "justify",
					columns: [
						{
							width: 330,
							text: "Удельный показатель выбросов, т. СО2-экв./т.",
							margin: [10, 0, 0, 8],
						},
						{ width: "auto", text: udelCheckVybr.value },
					],
				},
			],
		}
		pdfMake
			.createPdf(dd, null, pdfMake.fonts)
			.download(company.value + ".pdf")
	}
	return {
		onSubmit,
		company,
		companyError,
		companyBlur,
		onvCode,
		onvCodeError,
		onvCodeBlur,
		period,
		periodError,
		periodChange,
		year,
		yearError,
		yearBlur,
		// energoVybross,
		// summVybross,
		calculateTypeThree,
		productionTypeInclArray,
		onChangePT,
		onChangePTandVP,
		generatePdfFile,
		cementData,
        setValues,
		udelPokVybr, excess,
        popravKoeff, popravKoeffError, vybrCO2Cement, koefUdelVybr,
        carbonate, addRaw, rawCount, getCarbsData, noCarbonates, carbPath, carbPathError, calcPathCP, calcPathCPError, kVybrNoCalcCarb, kVybrNoCalcCarbError,
        weightNoFuelMaterial, weightNoFuelMaterialError, pathUglerod, pathUglerodError, koefKerogenVybr, koefKerogenVybrError, weightCP, weightCPError
	}
}