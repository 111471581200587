<template>
    <div class="equiv-calc m-4">
        <table class="table table-bordered align-middle table-hover border-secondary">
            <thead>
                <tr>
                    <th :colspan="Object.keys(weightByYears).length + 5">Показатель</th>
                </tr>
                <tr>
                    <th scope="col">№ п/п</th>
                    <th scope="col">Наименование</th>
                    <th scope="col">Обозначения</th>
                    <th scope="col">Единицы измерения</th>
                    <th v-for="(weight, name, index) in weightByYears" :key="index">{{ name == 'averageAlWeight' ?
                    'Среднее' : name + ' год' }}</th>
                    <th>Среднее</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr class="align-middle">
                    <td>0</td>
                    <td>Абсолютные валовые выбросы Перфторметан (??????), т</td>
                    <td>Что-то</td>
                    <td>т</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="absEmissionC2F6[]" disabled
                            v-model="dataPerftorm.absEmissionC2F6[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="absEmissionC2F6Middle[]" disabled
                            v-model="dataPerftorm.absEmissionC2F6Middle" />
                    </td>
                </tr> -->
                <tr class="align-middle grey">
                    <td>1</td>
                    <td>Потенциал глобального потепления для CО<sub>2</sub></td>
                    <td>
                        <!-- <math-jax :latex="'ПГП_{CO2}'" :block="true"></math-jax> -->
                        <p><i>ПГП<sub>CO2</sub></i></p>
                    </td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialCO2[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialCO2[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialCO2Middle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialCO2Middle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>1.1</td>
                    <td>Абсолютные выбросы диоксида углерода</td>
                    <td></td>
                    <td>т СО₂-экв.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="AbcEquivCO2[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivCO2[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="AbcEquivCO2Middle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivCO2Middle" />
                    </td>
                </tr>
                <tr class="align-middle grey">
                    <td>2</td>
                    <td>Потенциал глобального потепления для CF<sub>4</sub></td>
                    <td>
                        <!-- <math-jax :latex="'ПГП_{CF4}'" :block="true"></math-jax> -->
                        <p><i>ПГП<sub>CF4</sub></i></p>
                    </td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialCF4[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialCF4[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialCF4Middle[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>2.2</td>
                    <td>Абсолютные выбросы СF<sub>4</sub></td>
                    <td></td>
                    <td>т СО₂-экв.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="AbcEquivCF4[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivCF4[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="AbcEquivCF4Middle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivCF4Middle" />
                    </td>
                </tr>
                <tr class="align-middle grey">
                    <td>3</td>
                    <td>Потенциал глобального потепления для C<sub>2</sub>F<sub>6</sub></td>
                    <td>
                        <!-- <math-jax :latex="'ПГП_{C2F6}'" :block="true"></math-jax> -->
                        <p><i>ПГП<sub>C2F6</sub></i></p>
                    </td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialC2F6[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialC2F6[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="GlobalWarmingPotentialC2F6Middle[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.GlobalWarmingPotentialC2F6Middle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>3.3</td>
                    <td>Абсолютные выбросы C<sub>2</sub>F<sub>6</sub></td>
                    <td></td>
                    <td>т СО₂-экв.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="AbcEquivC2F6[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivC2F6[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="AbcEquivCF4Middle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.AbcEquivC2F6Middle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>4</td>
                    <td>Суммарные абсолютные выбросы ПГ</td>
                    <td></td>
                    <td>т СО₂-экв.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="absSummVybr[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.absSummVybr[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="absSummVybrMiddle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.absSummVybrMiddle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>5</td>
                    <td>Удельные выбросы СО₂ на тонну алюминия</td>
                    <td></td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="udelVybrCO2[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrCO2[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelVybrCO2Middle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrCO2Middle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>6</td>
                    <td>Удельные выбросы ПФУ на тонну алюминия</td>
                    <td></td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="udelVybrPFU[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrPFU[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelVybrPFUMiddle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrPFUMiddle" />
                    </td>
                </tr>
                <tr class="align-middle blue">
                    <td>7</td>
                    <td>Суммарные удельные выбросы ПГ на тонну алюминия</td>
                    <td></td>
                    <td>т СО₂-экв. / т Ал.</td>
                    <td v-for="(weight, name, index) in weightByYears" :key="index">
                        <FormKit type="number" step="0.01" name="udelVybrSumm[]" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrSumm[name]" />
                    </td>
                    <td>
                        <FormKit type="number" step="0.01" name="udelVybrSummMiddle" disabled outer-class="mb-0"
                            v-model="dataEquivalent.udelVybrSummMiddle" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { toRef, toRefs, ref, onMounted } from 'vue'
import {colorMetalFunctions} from "@/use/colorMetFunctions";
export default {
    props: {
        weightByYears: Object,
        dioxideData: Object,
        performData: Object,
        showResult: Boolean,
    },
    emits: ["sendAllEquivalentData"],
    setup(props, context) {
        const { arrayMiddle, makeString } = colorMetalFunctions();
        const properties = toRefs(props)
        const weightByYears = toRef(props, 'weightByYears')
        const dioxideData = toRef(props, 'dioxideData')
        const performData = toRef(props, 'performData')
        

        const dataEquivalent = ref({
            GlobalWarmingPotentialCO2: {},
            GlobalWarmingPotentialCO2Middle: 1,
            GlobalWarmingPotentialCF4: {},
            GlobalWarmingPotentialCF4Middle: 7390,
            GlobalWarmingPotentialC2F6: {},
            GlobalWarmingPotentialC2F6Middle: 12200,
            AbcEquivCO2: {},
            AbcEquivCO2Middle: 0,
            AbcEquivCF4: {},
            AbcEquivCF4Middle: 0,
            AbcEquivC2F6: {},
            AbcEquivC2F6Middle: 0,
            absSummVybr: {},
            absSummVybrMiddle: 0,
            udelVybrCO2: {},
            udelVybrCO2Middle: 0,
            udelVybrPFU: {},
            udelVybrPFUMiddle: 0,
            udelVybrSumm: {},
            udelVybrSummMiddle: 0
        })
        
        for (let key in properties.weightByYears.value) {
            dataEquivalent.value.GlobalWarmingPotentialCO2[key] = 1
            dataEquivalent.value.GlobalWarmingPotentialCF4[key] = 7390
            dataEquivalent.value.GlobalWarmingPotentialC2F6[key] = 12200
            // dataEquivalent.value.AbcEquivCO2[key] = properties.dioxideData.value.absDioxideEmission[key] * dataEquivalent.value.GlobalWarmingPotentialCO2[key]
            // dataEquivalent.value.AbcEquivCF4[key] = properties.performData.value.absEmissionCF4[key] * dataEquivalent.value.GlobalWarmingPotentialCF4[key]
        }
        for (let key in properties.dioxideData.value.absDioxideEmission){
            dataEquivalent.value.AbcEquivCO2[key] = (properties.dioxideData.value.absDioxideEmission[key] * dataEquivalent.value.GlobalWarmingPotentialCO2[key]).toFixed(3)
        }
        for (let key in properties.performData.value.absEmissionCF4){
            dataEquivalent.value.AbcEquivCF4[key] = (properties.performData.value.absEmissionCF4[key] * dataEquivalent.value.GlobalWarmingPotentialCF4[key]).toFixed(3)
        }
        for (let key in properties.performData.value.absEmissionC2F6){
            dataEquivalent.value.AbcEquivC2F6[key] = (properties.performData.value.absEmissionC2F6[key] * dataEquivalent.value.GlobalWarmingPotentialC2F6[key]).toFixed(3)
            dataEquivalent.value.absSummVybr[key] = (1*dataEquivalent.value.AbcEquivCO2[key] + 1*dataEquivalent.value.AbcEquivCF4[key] + 1*dataEquivalent.value.AbcEquivC2F6[key]).toFixed(3)
            dataEquivalent.value.udelVybrCO2[key] = (dataEquivalent.value.AbcEquivCO2[key] / properties.weightByYears.value[key]).toFixed(3)
            dataEquivalent.value.udelVybrPFU[key] = ((1*dataEquivalent.value.AbcEquivCF4[key] + 1*dataEquivalent.value.AbcEquivC2F6[key]) / properties.weightByYears.value[key]).toFixed(3)
            dataEquivalent.value.udelVybrSumm[key] = (dataEquivalent.value.absSummVybr[key] / properties.weightByYears.value[key]).toFixed(3)
        }
        dataEquivalent.value.AbcEquivC2F6Middle = arrayMiddle(dataEquivalent.value.AbcEquivC2F6, properties.weightByYears.value)
        dataEquivalent.value.absSummVybrMiddle = arrayMiddle(dataEquivalent.value.absSummVybr, properties.weightByYears.value)
        dataEquivalent.value.udelVybrCO2Middle = arrayMiddle(dataEquivalent.value.udelVybrCO2, properties.weightByYears.value)
        dataEquivalent.value.udelVybrPFUMiddle = arrayMiddle(dataEquivalent.value.udelVybrPFU, properties.weightByYears.value)
        dataEquivalent.value.udelVybrSummMiddle = arrayMiddle(dataEquivalent.value.udelVybrSumm, properties.weightByYears.value)
        dataEquivalent.value.AbcEquivCO2Middle = arrayMiddle(dataEquivalent.value.AbcEquivCO2, properties.weightByYears.value)
        dataEquivalent.value.AbcEquivCF4Middle = arrayMiddle(dataEquivalent.value.AbcEquivCF4, properties.weightByYears.value)

        
        context.emit('sendAllEquivalentData', dataEquivalent.value)

        return {
            weightByYears, dataEquivalent, makeString
        }
    }
}

</script>

<style scoped>
.blue{
    background-color: rgba(88, 158, 244, 0.221);
}
.grey{
    background-color: rgba(206, 198, 198, 0.222);
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1054;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: black;
    opacity: .5;
    top: 0;
    left: 0;
}
</style>