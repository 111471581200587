<template>
    <div class="container">
        <h3>Введите данные</h3>
        <form action="">
            <div class="row">
                <div class="form-floating col-lg-8 mb-3">
                    <input type="text" class="form-control" name="company" id="company" v-model="company" placeholder="">
                    <label class="mx-2 pt-2" for="company">Название предприятия</label>
                    <small class="invalid" v-if="companyError">{{ companyError }}</small>
                </div>
                <div class="form-floating col-lg-1 mb-3">
                    <input type="text" class="form-control" name="year" id="year" v-model="year" placeholder="">
                    <label class="mx-2 pt-2" for="year">Год</label>
                    <small class="invalid" v-if="yearError">{{ yearError }}</small>
                </div>
                <div class="form-floating col-lg-3 mb-3">
                    <input type="text" class="form-control" name="onvosCode" id="onvosCode" v-model="onvosCode"
                        placeholder="">
                    <label class="mx-2 pt-2" for="onvosCode">Код ОНВОС</label>
                    <small class="invalid" v-if="onvosCodeError">{{ onvosCodeError }}</small>
                </div>
            </div>

            <!-- тут модуль типов продукции -->
            <TheBrickProdType v-for="item in items" :key="item" :num="item" @getProdTypeData="getProdData" />
            <!-- <TheBrickProdType /> -->
            <button class="btn add-btn d-flex align-items-center" @click.prevent="newProdType"><img src="/img/plus.svg"
                    alt="" width="40" class="add-img">
                Добавить<br />вид продукции</button>
            <!-- <div class="row mt-3">
                <div class="form-floating col-lg-10 mb-3">
                    <input type="number" class="form-control" name="otherProcessEmission" id="otherProcessEmission"
                        v-model="otherProcessEmission" placeholder="">
                    <label class="mx-2 pt-2" for="otherProcessEmission">Выбросы <i>CO<sub>2</sub></i> от прочих процессов,
                        т. <i>CO<sub>2</sub></i></label>
                </div>
            </div> -->
            <div class="border rounded p-3 m-1 bg-info" style="--bs-bg-opacity: .1;">
                <h5>Выбросы CO2 от прочих процессов</h5>
                <TheOtherProcessEmission v-for="item in processItems" :key="item" :processNum="item"
                    @getOtherProcessData="getOtherProcData" />
                <button class="btn add-btn d-flex align-items-center" @click.prevent="newProcess"><img src="/img/plus.svg"
                        alt="" width="40" class="add-img">
                    Добавить<br />процесс</button>
            </div>
            <div class="row mt-3 justify-content-lg-center">
                <div class="form-floating col-lg-10 mb-3">
                    <input disabled type="number" class="form-control" name="summBricksWeight" id="summBricksWeight"
                        :value="summBricksWeight" placeholder="">
                    <label class="mx-2 pt-2" for="summBricksWeight">Общий объем выпускаемой продукции обожженный кирпич,
                        тыс.т/год</label>
                </div>
            </div>
            <div class="row justify-content-lg-center">
                <div class="form-floating col-lg-10 mb-3">
                    <input disabled type="number" class="form-control" name="summBrickEmiss" id="summBrickEmiss"
                        :value="summBrickEmiss.toFixed(4)" placeholder="">
                    <label class="mx-2 pt-2" for="summBrickEmiss">Итоговые значения выброса CO2 по выпускаемой продукции
                        обожженный кирпич, т. <i>CO<sub>2</sub></i></label>
                </div>
                <div class="form-floating col-lg-10 mb-3">
                    <input disabled type="number" class="form-control" name="summUdelBrickEmiss" id="summUdelBrickEmiss"
                        :value="summUdelBrickEmiss.toFixed(4)" placeholder="">
                    <label class="mx-2 pt-2" for="summUdelBrickEmiss">Удельный показатель выбросов CO2 обожженный кирпич, т.
                        <i>CO<sub>2</sub></i> /т. кирпича</label>
                </div>
            </div>
            <div class="row">
                <input type="hidden" name="" v-model="boolError.error" />
                <small class="invalid" v-if="boolError.error">{{ boolError.errorMessage }}</small>
            </div>
            <div class="row justify-content-lg-center">
                <button type="button" class="btn btn-primary mb-4 col-lg-4" @click.prevent="openModal">
                    Показать результаты
                </button>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Расчет выбросов CO2 при производстве кирпича
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table class="table table-success table-sm table-bordered" v-for="obj in prodTypeObj"
                                :key="obj.num">
                                <!-- <template v-for="obj in prodTypeObj" :key="obj.num"> -->
                                <thead>
                                    <tr>
                                        <th>Вид выпускаемой продукции</th>
                                        <th>Годовой выпуск продукции, тыс.т/год</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ obj.prodTypeName }}</td>
                                        <td>{{ obj.prodWeight }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="table table-info table-sm table-bordered mb-0">
                                                <caption class="caption-top">Карбонаты</caption>
                                                <thead>
                                                    <tr>
                                                        <th>Наименование - Формула</th>
                                                        <th>Сырье</th>
                                                        <th>Масса сырья</th>
                                                        <th>Выбросы CO2</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="carb in obj.carbonatesObj" :key="carb.num">
                                                        <td>{{ carb.carbonateName }}</td>
                                                        <td>{{ carb.mineralMatVal }}</td>
                                                        <td>{{ carb.mRawMaterial }}</td>
                                                        <td>{{ carb.carbEmission }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3">Итого</td>
                                                        <td>{{ obj.summCarbonEmiss }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="table table-primary table-bordered mb-0">
                                                <caption class="caption-top">Топливо</caption>
                                                <thead>
                                                    <tr>
                                                        <th>Наименование</th>
                                                        <th>Расход</th>
                                                        <th>Выбросы CO2</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="fuel in obj.fuelsObj" :key="fuel.num">
                                                        <td>{{ fuel.fuelType }}</td>
                                                        <td>{{ fuel.fuelConsumption }}</td>
                                                        <td>{{ fuel.fuelCO2 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">Итого</td>
                                                        <td>{{ obj.summFuelEmiss }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Выбросы <i>CO<sub>2</sub></i>, т. <i>CO<sub>2</sub></i></th>
                                        <th>Удельный показатель выброса <i>CO<sub>2</sub></i>, т. <i>CO<sub>2</sub></i>/т.
                                            кирпича</th>
                                    </tr>
                                    <tr>
                                        <td>{{ obj.resultEmission.toFixed(4) }}</td>
                                        <td>{{ obj.resultUdelEmission.toFixed(4) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-light table-sm table-bordered">
                                <caption class="caption-top">Выбросы CO2 от прочих процессов</caption>
                                <thead>
                                    <tr>
                                        <th>Наименование процесса</th>
                                        <th>Выбросы CO2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="other in otheProcessObj" :key="other.num">
                                        <td>{{ other.otherProcessName }}</td>
                                        <td>{{ other.otherProcessEmission }}</td>
                                    </tr>
                                    <tr>
                                        <td>Итого</td>
                                        <td>{{ summOtherProcessEmiss }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-warning table-sm table-bordered">
                                <caption class="caption-top">Суммарные показатели</caption>
                                <thead>
                                    <tr>
                                        <th>Общий объем продукции</th>
                                        <th>Выбросы CO2</th>
                                        <th>Удельный Выброс CO2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ summBricksWeight }}</td>
                                        <td>{{ summBrickEmiss.toFixed(4) }}</td>
                                        <td>{{ summUdelBrickEmiss.toFixed(4) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                            <!-- <button type="button" class="btn btn-primary" @click="generatePdfFile">Получить отчет</button> -->
                            <button type="button" class="btn pdf-make me-2" @click.prevent="generatePdfFile">
                                <img src="/img/disk.svg" width="25" alt="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import { useField, useForm, Field, } from "vee-validate"
import * as yup from 'yup';
import TheBrickProdType from "@/components/TheBrickProdType.vue";
import TheOtherProcessEmission from "@/components/TheOtherProcessEmission.vue";
import { ref, watch, reactive, onMounted } from 'vue'
import { Modal } from 'bootstrap'
import { logoData } from "../use/logo"


export default {
    components: { TheBrickProdType, TheOtherProcessEmission, Field, Modal },
    setup() {
        const { handleSubmit } = useForm()
        // // modal
        const mState = reactive({
            modal_demo: null,
        })

        onMounted(() => {
            mState.modal_demo = new Modal('#exampleModal', {})
        })
        // const openModal = handleSubmit(values => {
        //     mState.modal_demo.show()
        // },
        //     ({ errors }) => {
        //         const firstError = Object.keys(errors)[0]
        //         const el = document.querySelector(`[name="${firstError}"]`)
        //         el?.scrollIntoView({ block: "start", behavior: 'smooth' })
        //     }
        // )
        const boolError = reactive({
            error: false,
            errorMessage: "Не все поля заполнены"
        })
        // const {
        //     value: boolError,
        //     errorMessage: boolErrorError,
        // } = useField(
        //     "boolError",
        //     yup.boolean().oneOf([false], 'This field must be checked'), { initialValue: true }
        // )

        const {
            value: company,
            errorMessage: companyError,
        } = useField(
            "company",
            yup.string()
                .required("Введите наименование компании")
        )
        const {
            value: year,
            errorMessage: yearError,
        } = useField(
            "year",
            yup.string()
                .required("Введите год")
                .min(4, "Должно быть 4 цифры")
                .max(4, "Должно быть 4 цифры")
        )
        const {
            value: onvosCode,
            errorMessage: onvosCodeError,
        } = useField(
            "onvosCode",
            yup.string()
                .required("Введите код ОНВОС")
        )

        let items = ref([1])
        let processItems = ref([1])
        let prodTypeObj = ref({})
        let summBrickEmiss = ref(0)
        let summBricksWeight = ref(0)
        let summUdelBrickEmiss = ref(0)
        let summOtherProcessEmiss = ref(0)
        // let otherProcessEmission = ref(0)//прочие выбросы
        const newProdType = function () {//добавление нового типа продукции
            items.value.push(items.value.length + 1)
        }
        const newProcess = function () {
            processItems.value.push(processItems.value.length + 1)
        }
        const getProdData = (data) => {
            summBrickEmiss.value = 0
            summBricksWeight.value = 0
            prodTypeObj.value[data.num] = data
            for (let k in prodTypeObj.value) {
                summBrickEmiss.value += Number(prodTypeObj.value[k].resultEmission)
                summBricksWeight.value += Number(prodTypeObj.value[k].prodWeight)
            }
            // summBrickEmiss.value += Number(otherProcessEmission.value)
            summUdelBrickEmiss.value = summBricksWeight.value > 0 ? summBrickEmiss.value / (summBricksWeight.value) : 0
            boolError.error = false
            console.log(prodTypeObj.value)
        }
        //количество карбонатов - по умолчанию 1

        watch(summOtherProcessEmiss, () => {
            summBrickEmiss.value = 0
            for (let k in prodTypeObj.value) {
                summBrickEmiss.value += Number(prodTypeObj.value[k].resultEmission)
            }
            summBrickEmiss.value += Number(summOtherProcessEmiss.value)
            summUdelBrickEmiss.value = summBricksWeight.value > 0 ? summBrickEmiss.value / summBricksWeight.value : 0
            boolError.error = false
        })

        const openModal = handleSubmit(values => {
            if (summBricksWeight.value > 0 && summBrickEmiss.value > 0 && summUdelBrickEmiss.value > 0) {
                boolError.error = false
                mState.modal_demo.show()
            } else {
                boolError.error = true
            }
        }, ({ errors }) => {
            const firstError = Object.keys(errors)[0]
            const el = document.querySelector(`[name="${firstError}"]`)
            let x = el.scrollLeft
            let y = el.scrollTop
            // el?.scrollIntoView({ block: "start", behavior: 'smooth' })
            window.scrollTo(x, y)
        }
        )
        // function getOtherProcessData(values){
        //     console.log(values)
        // }
        const otheProcessObj = ref({})
        const getOtherProcData = (data) => {
            summOtherProcessEmiss.value = 0
            otheProcessObj.value[data.num] = data
            for (let k in otheProcessObj.value) {
                summOtherProcessEmiss.value += otheProcessObj.value[k].otherProcessEmission
            }
        }

        // start PDF
        function generatePdfFile() {
            var pdfMake = require("pdfmake/build/pdfmake.js")
            var pdfFonts = require("pdfmake/build/vfs_fonts")

            pdfMake.fonts = {
                Arial: {
                    normal: "ArialRegular.ttf",
                    bold: "ArialBold.ttf",
                    italics: "ArialItalic.ttf",
                    bolditalics: "ArialRegular.ttf",
                },
            }
            pdfMake.vfs = pdfFonts.pdfMake.vfs
            var docDefinition = {
                content: company.value,
                defaultStyle: {
                    font: "Roboto",
                },
            }
            let d = new Date()
            let formatter = new Intl.DateTimeFormat("ru", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            })
            let date = formatter.format(d)
            var dd = {

                pageOrientation: "landscape",
                defaultStyle: {
                    font: "Arial",
                    fontSize: 11,
                },
                footer: function (currentPage, pageCount) {
                    // return currentPage.toString() + ' из ' + pageCount;
                    return [
                        {
                            columns: [
                                {
                                    width: 750,
                                    text: date,
                                    margin: [15, 0, 0, 8],
                                    fontSize: 10,
                                },
                                {
                                    text:
                                        "Стр. " +
                                        currentPage.toString() +
                                        " из " +
                                        pageCount,
                                    margin: [10, 0, 0, 0],
                                    fontSize: 10,
                                },
                            ],
                        },
                        {
                            text: 'данный отчёт сформирован калькулятором, разработанным ФГАУ "НИИ "ЦЭПП"" и носит рекомендательный характер',
                            fontSize: 9,
                            color: "#515151",
                            margin: [15, 0, 0, 8],
                            italics: true,
                        },
                    ]
                },
                content: [],
                styles: {
                    tablesStyle: {
                        margin: [0, 5, 0, 5]
                    },
                    subHeadStyle: {
                        fontSize: 13,
                        bold: true,
                        margin: [0, 5, 0, 5]
                    },
                    prodTypeStyle: {
                        margin: [20, 5, 0, 5],
                        fontSize: 14,
                        bold: true,
                        alignment: "left",
                    }
                },
            }
            dd.content.push(
                {
                    image: logoData,
                    width: 800,
                },
                // { text: "Тип расчета:  " + typeCalc, fontSize: 11, margin: [2, 2] },
                {
                    text: "Организация    " + company.value,
                    fontSize: 13,
                    italics: true,
                    // bold: true,  
                    margin: [5, 5, 5, 5],
                },
                {
                    text:
                        "Код ОНВ    " +
                        onvosCode.value + "          " +
                        year.value +
                        " год",
                    fontSize: 13,
                    italics: true,
                    // bold: true,
                    margin: [5, 0, 5, 15],
                },
            )
            for (let prodKey in prodTypeObj.value) {
                dd.content.push({
                    style: "prodTypeStyle",
                    table: {
                        headerRows: 2,
                        body: [
                            // [{text: 'Header 1'}, {text: 'Header 2'}],
                            ['Вид продукции', prodTypeObj.value[prodKey].prodTypeName],
                            ['годовой выпуск продукции тонн/год', prodTypeObj.value[prodKey].prodWeight],
                        ]
                    },
                    layout: 'lightHorizontalLines'
                },
                    { text: "Сведения об использовании карбонатов, содержащихся в минеральном сырье (глине, добавках и др.)", style: "subHeadStyle" },
                )
                for (let carbKey in prodTypeObj.value[prodKey].carbonatesObj) {
                    dd.content.push({
                        style: "tablesStyle",
                        table: {
                            widths: [500, '*'],
                            body: [
                                // [{text: 'Header 1'}, {text: 'Header 2'}],
                                [{ text: 'Наименование карбоната', fillColor: '#eeeeee' }, { text: prodTypeObj.value[prodKey].carbonatesObj[carbKey].carbonateName, fillColor: '#eeeeee' }],
                                ['Наименование минерального сырья', prodTypeObj.value[prodKey].carbonatesObj[carbKey].mineralMatVal],
                                ['Расход минерального сырья тонн', prodTypeObj.value[prodKey].carbonatesObj[carbKey].mRawMaterial],
                                ['Содержание карбоната в минеральном сырье, доля', prodTypeObj.value[prodKey].carbonatesObj[carbKey].carbonateContent],
                                ['Степень кальцинирования карбоната, доля', prodTypeObj.value[prodKey].carbonatesObj[carbKey].degreeOfCalcination],
                                ['Выбросы CO2, т CO2', prodTypeObj.value[prodKey].carbonatesObj[carbKey].carbEmission.toFixed(4)],
                            ]
                        }
                    })
                }
                dd.content.push({
                    text: "Суммарные выбросы CO2 от кальцинации карбонатов, тонн CO2      " + prodTypeObj.value[prodKey].summCarbonEmiss.toFixed(4), style: "subHeadStyle"
                })
                dd.content.push({
                    text: "Сведения о потреблении топлива на основные технологические процессы", style: "subHeadStyle",
                })
                for (let carbKey in prodTypeObj.value[prodKey].fuelsObj) {
                    dd.content.push({
                        style: "tablesStyle",
                        table: {
                            widths: [500, '*'],
                            body: [
                                // [{text: 'Header 1'}, {text: 'Header 2'}],
                                [{ text: 'Тип топлива', fillColor: '#eeeeee' }, { text: prodTypeObj.value[prodKey].fuelsObj[carbKey].fuelType, fillColor: '#eeeeee' }],
                                ['Расход топлива, ГДж', prodTypeObj.value[prodKey].fuelsObj[carbKey].fuelConsumption],
                                ['Выбросы CO2 от сжигания топлива, тонн CO2', prodTypeObj.value[prodKey].fuelsObj[carbKey].fuelCO2],
                            ]
                        }
                    })
                }
                dd.content.push({
                    text: "Суммарные выбросы CO2 от стационарного сжигания различных видов топлива, тонн CO2      " + prodTypeObj.value[prodKey].summFuelEmiss, style: "subHeadStyle"
                })
                dd.content.push({
                    style: "tablesStyle",
                    table: {
                        widths: [500, '*'],
                        body: [
                            // [{text: 'Header 1'}, {text: 'Header 2'}],
                            [{ text: 'Итоговые значения выброса CO2 по виду выпускаемой продукции, тонн CO2', fillColor: '#eeeeee' }, { text: prodTypeObj.value[prodKey].resultEmission.toFixed(4), fillColor: '#eeeeee' }],
                            [{ text: 'Удельный показатель выброса CO2 по виду выпускаемой продукции, тонн CO2/тонн кирпича', fillColor: '#eeeeee' }, { text: prodTypeObj.value[prodKey].resultUdelEmission.toFixed(4), fillColor: '#eeeeee' }],
                        ]
                    }
                })
                // dd.content.push({
                //     text: "Итоговые значения выброса CO2 по виду выпускаемой продукции, тонн CO2      " + prodTypeObj.value[prodKey].resultEmission.toFixed(4)
                // })
                // dd.content.push({
                //     text: "Удельный показатель выброса CO2 по виду выпускаемой продукции, тонн CO2/тонн кирпича      " + prodTypeObj.value[prodKey].resultUdelEmission.toFixed(4)
                // })
            }
            if (Object.keys(otheProcessObj.value).length !== 0) {
                dd.content.push({
                    text: "Выбросы CO2 от прочих процессов", style: "subHeadStyle"
                })
                for (let oKey in otheProcessObj.value) {
                    dd.content.push({
                        style: "tablesStyle",
                        table: {
                            widths: [500, '*'],
                            body: [
                                // [{text: 'Header 1'}, {text: 'Header 2'}],
                                [{ text: 'Наименование процесса', fillColor: '#eeeeee' }, { text: otheProcessObj.value[oKey].otherProcessName, fillColor: '#eeeeee' }],
                                ['Выбросы CO2 от прочих процессов, т. CO2', otheProcessObj.value[oKey].otherProcessEmission],
                            ]
                        }
                    })
                }
            }
            dd.content.push({
                text: "Суммарные показатели", style: "subHeadStyle", alignment: "center",
            })
            dd.content.push({
                style: "tablesStyle",
                table: {
                    widths: [600, '*'],
                    body: [
                        [{ text: "Общий объем выпускаемой продукции обожженный кирпич, тонн/год", fillColor: '#eeffee' }, { text: summBricksWeight.value, fillColor: '#eeffee', }],
                        [{ text: "Итоговые значения выброса CO2 по выпускаемой продукции обожженный кирпич, т. CO2", fillColor: '#eeffee' }, { text: summBrickEmiss.value.toFixed(4), fillColor: '#eeffee' }],
                        [{ text: "Удельный показатель выбросов CO2 по выпускаемой продукции обожженный кирпич, т. CO2/т.кирпича", fillColor: '#eeffee' }, { text: summUdelBrickEmiss.value.toFixed(4), fillColor: '#eeffee' }],
                    ]
                }
            })

            pdfMake
                .createPdf(dd, null, pdfMake.fonts)
                .download(company.value + ".pdf")
        }
        // end PDF

        return {
            company, companyError, year, yearError, onvosCode, onvosCodeError,
            summBricksWeight, newProdType, getProdData, items, summBrickEmiss, summUdelBrickEmiss, prodTypeObj,
            openModal, boolError, newProcess, processItems, getOtherProcData, summOtherProcessEmiss, otheProcessObj,
            generatePdfFile
        }
    },
}
</script>
 
<style scoped>
.form-floating>.form-control {
    height: 3rem !important;
    line-height: 1 !important;
}

.form-control:disabled {
    background-color: #e2f8e8 !important;
}

.add-btn {
    color: #4ca6c6;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1;
    transition: .3s;
}

.add-btn:hover {
    color: #4ca6c6;
    transform: scale(1.05);
    transition: .3s;
}

small.invalid {
    color: red;
    font-size: 12px;
    float: left;
    margin-left: 0.5rem;
}

.pdf-make {
    background-color: #4ca6c6;
    float: right;
}

.pdf-make:hover {
    background-color: #3087a6;
    cursor: pointer !important;
}
</style>