<template>
    <main>
        <div style="" class="containerM mt-5">
            <span
                v-if="this.$store.state.activityType == 'Черная металлургия' || this.$store.state.activityType == 'Цветная металлургия' ||
                this.$store.state.productionType == 'производство аммиака' || this.$store.state.productionType == 'производство азотной кислоты'"
                class="long-text m-2">
                <p>Во исполнение Указа Президента Российской Федерации от 4 ноября 2020 г. № 666 «О сокращении выбросов
                    парниковых газов» утверждена Стратегия социально-экономического развития Российской Федерации с низким
                    уровнем выбросов парниковых газов до 2050 года (далее – Стратегия).</p>
                <p>В рамках реализации Стратегии и в соответствии с поручениями Правительства РФ проводится
                    <strong>актуализация
                        информационно-технических справочников по наилучшим доступным технологиям (далее – ИТС НДТ) с целью
                        установления в них индикативных показателей удельных выбросов парниковых газов по результатам
                        проведения
                        национального отраслевого бенчмаркинга углеродомкости производственных процессов.</strong>
                </p>
                <p>Индикативные показатели включаются в специальное приложение к ИТС НДТ и применяются как один из критериев
                    оценки инвестиционных проектов модернизации в рамках оказания мер государственной поддержки.</p>
                <p>Процедура бенчмаркинга регламентирована ГОСТ Р 113.00.11-2022 Наилучшие доступные технологии. Порядок
                    проведения бенчмаркинга удельных выбросов парниковых газов в отраслях промышленности.</p>
                <p><strong>Расчетные модули (калькуляторы) предназначены для определения удельных выбросов парниковых газов
                        при производстве продукции в отраслях промышленности и проведения сопоставительного анализа текущего
                        уровня углеродоемкости производственных процессов с индикативными показателями, установленными в ИТС
                        НДТ.</strong></p>
            </span>
            <span class="long-text m-2" v-else-if="this.$store.state.activityType == 'Целлюлозно-бумажное производство'">
                <p>Данный расчётный модуль (калькулятор) создан в рамках Федерального проекта «Промышленный экспорт» и
                    позволяет предприятиям промышленности, подпадающим под действие Федерального закона от 02.07.2021 №
                    296-ФЗ «Об ограничении выбросов парниковых газов», производить расчет выбросов ПГ в упрощенной форме (в
                    полуавтоматическом режиме).</p>
                <p>В основу модуля положены принципы национальной инвентаризации ПГ, закреплённые Межправительственной
                    группой экспертов по изменению климата (далее – МГЭИК).</p>
                <p>Данный сервис позволяет произвести расчет выбросов ПГ в атмосферу, используя эффективную практику по
                    выбору наилучшего метода с учётом национальных условий в соответствии с тремя уровнями расчёта.</p>
                <p>Модуль позволяет обеспечить точную, полную, непротиворечивую, актуальную и прозрачную инвентаризацию ПГ.
                </p>
                <p>Важно отметить, что расчет выбросов ПГ представляет собой многоэтапный процесс. Точная инвентаризация
                    может быть составлена только после тщательного изучения вопросов контроля качества и необходимых данных
                    о деятельности предприятия.</p>
            </span>
            <span class="long-text m-2" v-else-if="this.$store.state.activityType == 'Производство минеральных материалов'">
                <p>Данный расчётный модуль (калькулятор) создан в рамках Федерального проекта «Промышленный экспорт» и
                    позволяет предприятиям промышленности, подпадающим под действие Федерального закона от 02.07.2021 №
                    296-ФЗ «Об ограничении выбросов парниковых газов», производить расчет выбросов ПГ в упрощенной форме (в
                    полуавтоматическом режиме).</p>
                <template v-if="this.$store.state.productionType != 'Производство кирпича'">
                    <p>В основу модуля положены принципы национальной инвентаризации ПГ, закреплённые Межправительственной
                        группой экспертов по изменению климата (далее – МГЭИК).</p>
                    <p>Данный сервис позволяет произвести расчет выбросов ПГ в атмосферу, используя эффективную практику по
                        выбору наилучшего метода с учётом национальных условий в соответствии с тремя уровнями расчёта.</p>
                </template>
                <p>Модуль позволяет обеспечить точную, полную, непротиворечивую, актуальную и прозрачную инвентаризацию ПГ.
                </p>
                <p>Важно отметить, что расчет выбросов ПГ представляет собой многоэтапный процесс. Точная инвентаризация
                    может быть составлена только после тщательного изучения вопросов контроля качества и необходимых данных
                    о деятельности предприятия.</p>
            </span>
            <!-- <router-link to="/ferrous-metallurgy" v-if="this.$store.state.activityType == 'Черная металлургия' || this.$store.state.activityType == 'Цветная металлургия'"> -->
            <router-link to="/metallurgy" v-if="this.$store.state.activityType == 'Черная металлургия'">
                <div>
                    <img src="img/count/ipcc-eipc.jpg"><span>ГОСТ Р 113.26.01-2022</span>
                </div>
            </router-link>
            <router-link to="/metallurgy" v-else-if="this.$store.state.activityType == 'Цветная металлургия'">
                <div>
                    <img src="img/mpr.svg"><span>Приказ Минприроды России от 27.05.2022 №371</span>
                </div>
            </router-link>
            <router-link to="/ammonia" v-else-if="this.$store.state.productionType == 'производство аммиака'">
                <div>
                    <img src="img/count/ipcc-eipc.jpg"><span></span>
                </div>
            </router-link>
            <router-link to="/nitric-acid" v-else-if="this.$store.state.productionType == 'производство азотной кислоты'">
                <div>
                    <img src="img/count/ipcc-eipc.jpg"><span></span>
                </div>
            </router-link>
            <router-link to="/brick-production"
                v-else-if="this.$store.state.activityType == 'Производство минеральных материалов' && this.$store.state.productionType == 'Производство кирпича'">
                <div>
                    <img src="img/count/ipcc-eipc.jpg"><span>Приказ Минприроды России от 27.05.2022 №371</span>
                </div>
            </router-link>
            <router-link to="/types" v-else>
                <div><img src="img/count/ipcc.jpg"><span>МГЭИК</span></div>
            </router-link>
            <!-- <div v-if="this.$store.state.activityType != 'Цветная металлургия' && this.$store.state.activityType != 'Черная металлургия'"><img src="img/count/method.jpg"><span>Методика 2</span></div>
            <div v-if="this.$store.state.activityType != 'Цветная металлургия' && this.$store.state.activityType != 'Черная металлургия'"><img src="img/count/method.jpg"><span>Методика 3</span></div> -->
        </div>
    </main>
</template>


<style scoped>
main {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
}

.containerM {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(370px, 1fr)); */
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 60%;
    justify-items: center
}

@media screen and (max-width : 500px) {
    .containerM {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
    }
}

.containerM div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: .5s;
    max-width: 250px;
}

.containerM div:hover {
    transition: .5s;
    /* transform: scale(1.05); */
}

.long-text p {
    text-align: left;
    text-indent: 10px;
}
</style>